import { useEffect, useState } from "react";
import IMAGE from "../../const/image";
import { Dropdown } from "primereact/dropdown";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { Link } from "react-router-dom";
import CartMini from "../Cart/CartMini";
import { CommonService } from "../../services/CommonService";
import EventBus from "../../evenBus/EvenBus";
import { Sidebar } from "primereact/sidebar";
import {Checkbox} from "primereact/checkbox";
import { CategoryServices } from "../../services/CategoryServices";

function Header() {
    const [visibleRight, setVisibleRight] = useState(false);
    // const [selectedLanguage, setSelectedLanguage] = useState(null);
    // const [selectedCurrency, setSelectedCurency] = useState(null);
    const [totalItemCart, setTotalItemCart] = useState(0);
    const [visibleMenuRight, setVisibleMenuRight] = useState(false);
    const [categories, setCategories] = useState([]);
    const callBackVisibleRight = (data) => {
        setVisibleRight(data);
    };
    const [ipAddress, setIpAddress] = useState('');
    const language = [
        { name: "Vietnamese", code: "vi" },
        { name: "English", code: "en" },
        { name: "Japanses", code: "jp" },
    ];
    const currency = [
        { name: "VND", code: "vi" },
        { name: "USD", code: "en" },
        { name: "Yen", code: "jp" },
    ];
    const getCategories = () => {
        CategoryServices.getAllCategories()
            .then((data) => {
                setCategories(data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        getCategories();
        getTotalItemInCart();
        EventBus.on("cartUpdated", () => {
            getTotalItemInCart();
        });
        return () => {
            EventBus.removeAllListeners("cartUpdated");
        };
    }, []);
    const getTotalItemInCart = () => {
        const fetchCart = CommonService.getTotalItemInCart();
        setTotalItemCart(fetchCart);
    };

    return (
        <>
            {/* pre loader area start */}
            {/* <div id="loading">
                <div id="loading-center">
                    <div id="loading-center-absolute">
                        <div className="tp-preloader-content">
                            <div className="tp-preloader-logo">
                                <div className="tp-preloader-circle">
                                    <svg
                                        width={190}
                                        height={190}
                                        viewBox="0 0 380 380"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            stroke="#D9D9D9"
                                            cx={190}
                                            cy={190}
                                            r={180}
                                            strokeWidth={6}
                                            strokeLinecap="round"
                                        />
                                        <circle
                                            stroke="red"
                                            cx={190}
                                            cy={190}
                                            r={180}
                                            strokeWidth={6}
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </div>
                                <img src="assets/img/logo/preloader/preloader-icon.svg" alt="" />
                            </div>
                            <h3 className="tp-preloader-title">Shofy</h3>
                            <p className="tp-preloader-subtitle">Loading</p>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* pre loader area end */}
            {/* back to top start */}
            <div className="back-to-top-wrapper">
                <button id="back_to_top" type="button" className="back-to-top-btn">
                    <svg width={12} height={7} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11 6L6 1L1 6"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            </div>
            {/* back to top end */}
            {/* offcanvas area start */}
            <Sidebar visible={visibleMenuRight} position="right" onHide={() => setVisibleMenuRight(false)}>
                <div className="offcanvas__wrapper">
                    <div className="offcanvas__content">
                        <div className="tp-main-menu-mobile fix mb-40">
                            <nav className="tp-main-menu-content">
                                <ul>
                                    <li className="has-dropdown has-mega-menu">
                                        <Link to="/">Trang chủ</Link>
                                    </li>
                                    <li className="has-dropdown has-mega-menu">
                                        <Link to="/shop">Cửa hàng</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </Sidebar>
            {/* <div className="offcanvas__area">
                <div className="offcanvas__wrapper">
                    <div className="offcanvas__close">
                        <button className="offcanvas__close-btn offcanvas-close-btn">
                            <svg
                                width={12}
                                height={12}
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11 1L1 11"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M1 1L11 11"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="offcanvas__content">
                        <div className="offcanvas__top mb-70 d-flex justify-content-between align-items-center">
                            <div className="offcanvas__logo logo">
                                <a href="index.html">
                                    <img src={IMAGE.logo05} alt="logo" />
                                </a>
                            </div>
                        </div>
                        <div className="offcanvas__category pb-40">
                            <button className="tp-offcanvas-category-toggle">
                                <i className="fa-solid fa-bars" />
                                All Categories
                            </button>
                            <div className="tp-category-mobile-menu"></div>
                        </div>
                        <div className="tp-main-menu-mobile fix mb-40" />
                        <div className="offcanvas__contact align-items-center d-none">
                            <div className="offcanvas__contact-icon mr-20">
                                <span>
                                    <img src="assets/img/icon/contact.png" alt="" />
                                </span>
                            </div>
                            <div className="offcanvas__contact-content">
                                <h3 className="offcanvas__contact-title">
                                    <a href="tel:0886860224">0886860224</a>
                                </h3>
                            </div>
                        </div>
                        <div className="offcanvas__btn">
                            <a href="contact.html" className="tp-btn-2 tp-btn-border-2">
                                Contact Us
                            </a>
                        </div>
                    </div>
                    <div className="offcanvas__bottom">
                        <div className="offcanvas__footer d-flex align-items-center justify-content-between">
                            <div className="offcanvas__currency-wrapper currency">
                                <span
                                    className="offcanvas__currency-selected-currency tp-currency-toggle"
                                    id="tp-offcanvas-currency-toggle"
                                >
                                    Currency : USD
                                </span>
                                <ul className="offcanvas__currency-list tp-currency-list">
                                    <li>USD</li>
                                    <li>ERU</li>
                                    <li>BDT</li>
                                    <li>INR</li>
                                </ul>
                            </div>
                            <div className="offcanvas__select language">
                                <div className="offcanvas__lang d-flex align-items-center justify-content-md-end">
                                    <div className="offcanvas__lang-img mr-15">
                                        <img src="assets/img/icon/language-flag.png" alt="" />
                                    </div>
                                    <div className="offcanvas__lang-wrapper">
                                        <span
                                            className="offcanvas__lang-selected-lang tp-lang-toggle"
                                            id="tp-offcanvas-lang-toggle"
                                        >
                                            English
                                        </span>
                                        <ul className="offcanvas__lang-list tp-lang-list">
                                            <li>Spanish</li>
                                            <li>Portugese</li>
                                            <li>American</li>
                                            <li>Canada</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="body-overlay" />
            {/* offcanvas area end */}
            {/* mobile menu area start */}
            <div id="tp-bottom-menu-sticky" className="tp-mobile-menu d-lg-none">
                <div className="flex justify-content-between flex-wrap p-2">
                    <div className="tp-mobile-item text-center  flex align-items-center">
                        <Link to={`/`} className="tp-mobile-item-btn">
                            <i className="fa-light fa-house"></i>
                            <span>Trang chủ</span>
                        </Link>
                    </div>
                    <div className="tp-mobile-item text-center  flex align-items-center">
                        <Link to={`/shop`} className="tp-mobile-item-btn">
                            <i className="flaticon-store" />
                            <span>Cửa hàng</span>
                        </Link>
                    </div>

                    <div className="tp-mobile-item text-center flex align-items-center">
                        <button
                            className="tp-mobile-item-btn tp-offcanvas-open-btn"
                            onClick={() => setVisibleRight(!visibleRight)}
                        >
                            <svg
                                width={21}
                                height={22}
                                viewBox="0 0 21 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.48626 20.5H14.8341C17.9004 20.5 20.2528 19.3924 19.5847 14.9348L18.8066 8.89359C18.3947 6.66934 16.976 5.81808 15.7311 5.81808H5.55262C4.28946 5.81808 2.95308 6.73341 2.4771 8.89359L1.69907 14.9348C1.13157 18.889 3.4199 20.5 6.48626 20.5Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6.34902 5.5984C6.34902 3.21232 8.28331 1.27803 10.6694 1.27803V1.27803C11.8184 1.27316 12.922 1.72619 13.7362 2.53695C14.5504 3.3477 15.0081 4.44939 15.0081 5.5984V5.5984"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M7.70365 10.1018H7.74942"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M13.5343 10.1018H13.5801"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span>Giỏ</span>
                        </button>
                    </div>
                    <div className="tp-mobile-item text-center flex align-items-center">
                        <button
                            className="tp-mobile-item-btn tp-offcanvas-open-btn"
                            onClick={() => setVisibleMenuRight(true)}
                        >
                            <i className="flaticon-menu-1" />
                            <span>Menu</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* mobile menu area end */}
            {/* search area start */}
            {/* <section className="tp-search-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="tp-search-form">
                                <div className="tp-search-close text-center mb-20">
                                    <button className="tp-search-close-btn tp-search-close-btn" />
                                </div>
                                <form action="#">
                                    <div className="tp-search-input mb-10">
                                        <input type="text" placeholder="Search for product..." />
                                        <button type="submit">
                                            <i className="flaticon-search-1" />
                                        </button>
                                    </div>
                                    <div className="tp-search-category">
                                        <span>Search by : </span>
                                        <Link to="/">Men, </Link>
                                        <Link to="/">Women, </Link>
                                        <Link to="/">Children, </Link>
                                        <Link to="/">Shirt, </Link>
                                        <Link to="/">Demin</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* search area end */}
            {/* cart mini area start */}
            <CartMini visibleRight={visibleRight} callBackVisibleRight={callBackVisibleRight} />
            {/* cart mini area end */}
            {/* header area start */}
            <header>
                <div className="tp-header-area tp-header-style-primary tp-header-height">
                    {/* header top start  */}
                    <div className="tp-header-top-2 p-relative z-index-11 tp-header-top-border d-none d-md-block">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="tp-header-info d-flex align-items-center">
                                        <div className="tp-header-info-item">
                                            <Link to="https://www.facebook.com/korostore68" target="_blank">
                                                <span>
                                                    <svg
                                                        width={8}
                                                        height={15}
                                                        viewBox="0 0 8 15"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M8 0H5.81818C4.85376 0 3.92883 0.383116 3.24688 1.06507C2.56493 1.74702 2.18182 2.67194 2.18182 3.63636V5.81818H0V8.72727H2.18182V14.5455H5.09091V8.72727H7.27273L8 5.81818H5.09091V3.63636C5.09091 3.44348 5.16753 3.25849 5.30392 3.1221C5.44031 2.98571 5.6253 2.90909 5.81818 2.90909H8V0Z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                1500k Followers
                                            </Link>
                                        </div>
                                        <div className="tp-header-info-item">
                                            <a href="tel:402-763-282-46">
                                                <span>
                                                    <svg
                                                        width={16}
                                                        height={16}
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M1.359 2.73916C1.59079 2.35465 2.86862 0.958795 3.7792 1.00093C4.05162 1.02426 4.29244 1.1883 4.4881 1.37943H4.48885C4.93737 1.81888 6.22423 3.47735 6.29648 3.8265C6.47483 4.68282 5.45362 5.17645 5.76593 6.03954C6.56213 7.98771 7.93402 9.35948 9.88313 10.1549C10.7455 10.4679 11.2392 9.44752 12.0956 9.62511C12.4448 9.6981 14.1042 10.9841 14.5429 11.4333V11.4333C14.7333 11.6282 14.8989 11.8698 14.9214 12.1422C14.9553 13.1016 13.4728 14.3966 13.1838 14.5621C12.502 15.0505 11.6125 15.0415 10.5281 14.5373C7.50206 13.2784 2.66618 8.53401 1.38384 5.39391C0.893174 4.31561 0.860062 3.42016 1.359 2.73916Z"
                                                            stroke="currentColor"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M9.84082 1.18318C12.5534 1.48434 14.6952 3.62393 15 6.3358"
                                                            stroke="currentColor"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M9.84082 3.77927C11.1378 4.03207 12.1511 5.04544 12.4039 6.34239"
                                                            stroke="currentColor"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>{" "}
                                                +(84) 0886860224
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="tp-header-top-right tp-header-top-black d-flex align-items-center justify-content-end">
                                        <div className="tp-header-top-menu d-flex align-items-center justify-content-end"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* header bottom start */}
                    <div id="header-sticky" className="tp-header-bottom-2 tp-header-sticky">
                        <div className="container">
                            <div className="tp-mega-menu-wrapper p-relative">
                                <div className="row align-items-center">
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-6">
                                        <div className="logo">
                                            <a href="/">
                                                <img src={IMAGE.logo05} alt="logo" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 d-none d-lg-block">
                                        <div className="main-menu menu-style-2 ps-5">
                                            <nav className="tp-main-menu-content">
                                                <ul>
                                                    <li className="">
                                                        <Link to="/">TRANG CHỦ</Link>
                                                    </li>
                                                    <li className="has-dropdown has-mega-menu">
                                                        <Link to="/shop" className="">
                                                            CỬA HÀNG
                                                        </Link>
                                                        <div className="shop-mega-menu tp-submenu tp-mega-menu">
                                                            <div className="row">
                                                                <div className="col-lg-2">
                                                                    <div className="shop-mega-menu-list">
                                                                        <Link to="/shop"
                                                                           className="shop-mega-menu-title">Danh mục</Link>
                                                                        <ul>
                                                                            {categories.map((category) => {
                                                                                return (
                                                                                    // <div key={category.id}
                                                                                    //      className="flex align-items-center">
                                                                                    //     <Checkbox
                                                                                    //         inputId={category.id} // Chuyển đổi id sang chuỗi nếu cần
                                                                                    //         name="category"
                                                                                    //         value={category}
                                                                                    //         onChange={onCategoryChange}
                                                                                    //         checked={selectedCategories.some(
                                                                                    //             (item) => item && item.id === category.id
                                                                                    //         )}
                                                                                    //     />
                                                                                    //     <label htmlFor={category.id}
                                                                                    //            className="ml-2">
                                                                                    //         {category.name}
                                                                                    //     </label>
                                                                                    // </div>
                                                                                    <div key={category.id} className="p-2">
                                                                                    <li >{category.name}</li>
                                                                                    </div>
                                                                                );
                                                                            })}

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2">

                                                                </div>
                                                                <div className="col-lg-2">

                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="shop-mega-menu-img">
                                                                        <img
                                                                            src="https://mediaservice.koro.asia/api/v1/file/images/GIW7Xom3-discount.png"
                                                                            alt=""/>
                                                                        <div className="shop-mega-menu-btn">
                                                                            <Link to="/shop"
                                                                               className="tp-menu-showcase-btn tp-menu-showcase-btn-2">Ưu đãi</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="shop-mega-menu-img">
                                                                        <img
                                                                            src="https://mediaservice.koro.asia/api/v1/file/images/DIrk4SmP-freeship.png"
                                                                            alt=""/>
                                                                        <div className="shop-mega-menu-btn">
                                                                            <Link to="/cart"
                                                                               className="tp-menu-showcase-btn tp-menu-showcase-btn-2">Đặt hàng</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-8 col-6">
                                        <div
                                            className="tp-header-bottom-right d-flex align-items-center justify-content-end ">
                                            <div className="tp-header-action d-flex align-items-center ml-30">
                                                <div className="tp-header-action-item">
                                                    <button
                                                        className="tp-header-action-btn cartmini-open-btn"
                                                        onClick={() => setVisibleRight(!visibleRight)}
                                                    >
                                                        <svg
                                                            width={21}
                                                            height={22}
                                                            viewBox="0 0 21 22"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M6.48626 20.5H14.8341C17.9004 20.5 20.2528 19.3924 19.5847 14.9348L18.8066 8.89359C18.3947 6.66934 16.976 5.81808 15.7311 5.81808H5.55262C4.28946 5.81808 2.95308 6.73341 2.4771 8.89359L1.69907 14.9348C1.13157 18.889 3.4199 20.5 6.48626 20.5Z"
                                                                stroke="currentColor"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M6.34902 5.5984C6.34902 3.21232 8.28331 1.27803 10.6694 1.27803V1.27803C11.8184 1.27316 12.922 1.72619 13.7362 2.53695C14.5504 3.3477 15.0081 4.44939 15.0081 5.5984V5.5984"
                                                                stroke="currentColor"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M7.70365 10.1018H7.74942"
                                                                stroke="currentColor"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M13.5343 10.1018H13.5801"
                                                                stroke="currentColor"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        <span className="tp-header-action-badge">{totalItemCart}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* header area end */}
        </>
    );
}

export default Header;
