import { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { CommonService } from "../../services/CommonService";
// import { Galleria } from "primereact/galleria";
// import { InputText } from "primereact/inputtext";
import EventBus from "../../evenBus/EvenBus";
import { Tag } from "primereact/tag";
import { Link } from "react-router-dom";
import { Carousel } from "primereact/carousel";
import IMAGE from "../../const/image";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";

function ButtonProductDetailAction(props) {
    const { product } = props;
    const [visible, setVisible] = useState(false);
    const [images, setImages] = useState(null);
    const [priceProDetail, setPriceProDetail] = useState(null);
    const [productQuantities, setProductQuantities] = useState([]);
    const [value, setValue] = useState(0);
    const toast = useRef(null);
    const showSuccess = (message, time) => {
        toast.current.show({ severity: "success", summary: "Thành công", detail: message, life: time });
    };
    const showError = (message, time) => {
        toast.current.show({ severity: "error", summary: "Thất bại", detail: message, life: time });
    };
    const handleQuantityChange = (inputId, inputQuantity, inputProDe) => {
        if (inputQuantity < inputProDe.validQuantity && inputQuantity > 0) {
            const item = {
                id: inputId,
                quantity: inputQuantity,
                proDe: inputProDe,
                productId: product.id,
                productName: product.name,
                productImage: product.image,
            };
            setProductQuantities([...productQuantities, item]);
            return;
        }
        setValue(0);
        showError("Quá số lượng cho phép");
    };

    useEffect(() => {
        setImages(product.productImage);
    }, []);

    const productTemplate = (product) => {
        return (
            <img
                src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${product.urlImage + "-" + product.fileName}`}
                style={{ width: "100%", display: "block" }}
            />
        );
    };
    const onClickAddItemInCart = (productQuantities) => {
        CommonService.addItemToCart(productQuantities);
        EventBus.emit("cartUpdated");
        setProductQuantities([]);
        setVisible(false);
    };
    const clickProDetail = (proDetail) => {
        setPriceProDetail(proDetail.price - proDetail.discount);
    };
    const onHideDialog = () => {
        setVisible(false);
        setPriceProDetail(null);
    };
    return (
        <>
            <Toast ref={toast} />
            <div className="tp-product-action-2 tp-product-action-3 tp-product-action-4 tp-product-action-blackStyle">
                <div className="tp-product-action-item-2 tp-product-action-item-3 d-flex flex-column">
                    <button
                        onClick={() => setVisible(true)}
                        type="button"
                        className="tp-product-action-btn-2 tp-product-quick-view-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#producQuickViewModal"
                    >
                        <svg width={18} height={15} viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.99948 5.06828C7.80247 5.06828 6.82956 6.04044 6.82956 7.23542C6.82956 8.42951 7.80247 9.40077 8.99948 9.40077C10.1965 9.40077 11.1703 8.42951 11.1703 7.23542C11.1703 6.04044 10.1965 5.06828 8.99948 5.06828ZM8.99942 10.7482C7.0581 10.7482 5.47949 9.17221 5.47949 7.23508C5.47949 5.29705 7.0581 3.72021 8.99942 3.72021C10.9407 3.72021 12.5202 5.29705 12.5202 7.23508C12.5202 9.17221 10.9407 10.7482 8.99942 10.7482Z"
                                fill="currentColor"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1.41273 7.2346C3.08674 10.9265 5.90646 13.1215 8.99978 13.1224C12.0931 13.1215 14.9128 10.9265 16.5868 7.2346C14.9128 3.54363 12.0931 1.34863 8.99978 1.34773C5.90736 1.34863 3.08674 3.54363 1.41273 7.2346ZM9.00164 14.4703H8.99804H8.99714C5.27471 14.4676 1.93209 11.8629 0.0546754 7.50073C-0.0182251 7.33091 -0.0182251 7.13864 0.0546754 6.96883C1.93209 2.60759 5.27561 0.00288103 8.99714 0.000185582C8.99894 -0.000712902 8.99894 -0.000712902 8.99984 0.000185582C9.00164 -0.000712902 9.00164 -0.000712902 9.00254 0.000185582C12.725 0.00288103 16.0676 2.60759 17.945 6.96883C18.0188 7.13864 18.0188 7.33091 17.945 7.50073C16.0685 11.8629 12.725 14.4676 9.00254 14.4703H9.00164Z"
                                fill="currentColor"
                            />
                        </svg>
                        <span className="tp-product-tooltip tp-product-tooltip-right">Quick View</span>
                    </button>
                </div>
            </div>
            <Dialog
                header={`${product.name}`}
                visible={visible}
                onHide={() => onHideDialog()}
                draggable={false}
                resizable={false}
                style={{ width: "70vw" }}
                className="custom-dialog"
            >
                <div className="grid flex">
                    <div className="lg:col-7 sm:col-12 pt-3">
                        <Carousel
                            value={images}
                            numVisible={1}
                            numScroll={1}
                            className="custom-carousel"
                            circular
                            autoplayInterval={3000}
                            itemTemplate={productTemplate}
                        />
                    </div>
                    <div className="lg:col-5 sm:col-12 pt-3">
                        <Link to={`/product/${product.id}/${product.slug}`}>
                            <h3 className="tp-product-details-title">{product.name}</h3>
                        </Link>
                        <p>
                            <b>Mô tả:</b>
                            <br />
                            {product.contentShort}
                            <br />
                            <Link to={`/product/${product.id}/${product.slug}`}>
                                <span>Xem thêm</span>
                            </Link>
                        </p>
                        <div className="tp-product-details-price-wrapper mb-20 ">
                            <span className="tp-product-details-price new-price">
                                {priceProDetail === null ? (
                                    product.productDetail ? (
                                        <>
                                            {CommonService.checkDiscount(product.productDetail)
                                                ? CommonService.handleDiscountProduct(product.productDetail)
                                                : CommonService.handlePriceProduct(product.productDetail)}
                                        </>
                                    ) : (
                                        <>0 đ</>
                                    )
                                ) : (
                                    <>{CommonService.handleFomatCurrency(priceProDetail)}</>
                                )}
                            </span>
                            <br />
                            <span className="tp-product-details-price old-price text-red-500">
                                {product.productDetail ? (
                                    <>
                                        {" "}
                                        {CommonService.checkDiscount(product.productDetail)
                                            ? CommonService.handlePriceProduct(product.productDetail)
                                            : null}{" "}
                                    </>
                                ) : (
                                    <>0 đ</>
                                )}
                            </span>
                        </div>
                        <div className="tp-product-details-variation">
                            <div className="tp-product-details-variation-item">
                                <h4 className="tp-product-details-variation-title">
                                    <b>Phân loại:</b>
                                </h4>
                                <div className="tp-product-details-variation-list">
                                    <div className="flex justify-content-between flex-wrap">
                                        {product.productDetail.map((proDetail, index) => (
                                            <span
                                                className="flex"
                                                key={index}
                                                onClick={() => clickProDetail(proDetail)}
                                            >
                                                {proDetail.validQuantity > 0 && (
                                                    <>
                                                        <div className="flex align-items-center justify-content-center">
                                                            <img
                                                                src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${proDetail.image}`}
                                                                alt={proDetail.image}
                                                                style={{ width: "50px", height: "auto" }}
                                                            />
                                                            <label
                                                                htmlFor="ingredient1"
                                                                className="cursor-pointer ml-2"
                                                            >
                                                                <Tag
                                                                    severity="success"
                                                                    value={proDetail.skuName}
                                                                    style={{
                                                                        width: "125px",
                                                                        height: "50px",
                                                                        background: "#677489",
                                                                    }}
                                                                ></Tag>
                                                            </label>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center p-2">
                                                            <InputNumber
                                                                // inputStyle={{ height: "25px", width: "125px" }}
                                                                value={value}
                                                                onChange={(e) =>
                                                                    handleQuantityChange(
                                                                        proDetail.id,
                                                                        e.value,
                                                                        proDetail
                                                                    )
                                                                }
                                                                mix={0}
                                                                max={proDetail.validQuantity}
                                                            />
                                                        </div>
                                                        <div> còn {proDetail.validQuantity}</div>
                                                    </>
                                                )}
                                                {proDetail.validQuantity <= 0 && (
                                                    <>
                                                        <div className="flex align-items-center justify-content-center">
                                                            <img
                                                                src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${proDetail.image}`}
                                                                alt={proDetail.image}
                                                                style={{ width: "50px", height: "auto" }}
                                                            />
                                                            <label
                                                                htmlFor="ingredient1"
                                                                className="cursor-pointer ml-2"
                                                            >
                                                                <Tag
                                                                    severity="danger"
                                                                    value={proDetail.skuName}
                                                                    style={{ width: "125px" }}
                                                                ></Tag>
                                                            </label>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-center ps-2 pe-2">
                                                            <InputNumber
                                                                inputStyle={{ height: "25px", width: "125px" }}
                                                                value={null}
                                                                disabled
                                                                onChange={(e) =>
                                                                    handleQuantityChange(
                                                                        proDetail.id,
                                                                        e.value,
                                                                        proDetail
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="text-red-400 text-xs">tạm hết</div>
                                                    </>
                                                )}
                                                <hr />
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tp-product-details-action-wrapper">
                            <div className="tp-product-details-action-item-wrapper d-flex align-items-center">
                                <div className="tp-product-details-add-to-cart mb-15 w-100">
                                    <button
                                        onClick={() => onClickAddItemInCart(productQuantities)}
                                        className="tp-product-details-add-to-cart-btn w-100"
                                    >
                                        Thêm giỏ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="tp-product-details-category">
                            <span>
                                <b>Danh mục: </b>
                                {CommonService.handleCategory(product.categoryProduct)}
                            </span>
                        </div>
                        <div className="tp-product-details-social">
                            <span>Share: </span>
                            <Link to="https://www.facebook.com/korostore68" target="_blank">
                                <i className="fa-brands fa-facebook-f" />
                            </Link>
                            <Link to="https://www.instagram.com/koro_socks/" target="_blank">
                                <i className="fa-brands fa-linkedin-in" />
                            </Link>
                            <Link
                                to={product.linkTiktok ? product.linkTiktok : `https://www.tiktok.com/@korohouse`}
                                target="_blank"
                            >
                                <i className="fa-brands fa-tiktok" />
                            </Link>
                            <Link
                                to={product.linkShopee ? product.linkShopee : `https://shopee.vn/korohouse`}
                                target="_blank"
                            >
                                <img src={IMAGE.logoShopee} alt="" className="w-full" />
                            </Link>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default ButtonProductDetailAction;
