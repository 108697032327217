import {AxiosService} from "./AxiosService";

const BASE_URL = process.env.REACT_APP_BASE_URL;
export const CategoryServices = {
    getAllCategories: async () => {
        try {
            const response = await AxiosService.get(`${BASE_URL}/api/v1/categories`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};
export default CategoryServices;
