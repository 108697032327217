import { useEffect, useState } from "react";

import { Galleria } from "primereact/galleria";
import { RadioButton } from "primereact/radiobutton";

function TestUi() {
    const data = [
        {
            id: 1,
            urlImage: "/file/images/3plm1Tr5",
            fileName: "O1CN01tP9i871fsRXPZIIcI_!!2853044062-0-cib.jpg",
        },
        {
            id: 2,
            urlImage: "/file/images/xZG57SZC",
            fileName: "O1CN01smCC0i2FfSW0lgdIv_!!1818268907-0-cib.jpg",
        },
        {
            id: 3,
            urlImage: "/file/images/Tjw3iX12",
            fileName: "O1CN01rOMb1p2FfSW5OFQVq_!!1818268907-0-cib.jpg",
        },
    ];
    const [images, setImages] = useState(null);
    const [position, setPosition] = useState("bottom");
    const positionOptions = [
        {
            label: "Bottom",
            value: "bottom",
        },
        {
            label: "Top",
            value: "top",
        },
        {
            label: "Left",
            value: "left",
        },
        {
            label: "Right",
            value: "right",
        },
    ];
    const responsiveOptions = [
        {
            breakpoint: "991px",
            numVisible: 4,
        },
        {
            breakpoint: "767px",
            numVisible: 3,
        },
        {
            breakpoint: "575px",
            numVisible: 1,
        },
    ];

    useEffect(() => {
        setImages(data);
    }, []);

    const itemTemplate = (item) => {
        return (
            <img
                src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${item.urlImage + "-" + item.fileName}`}
                alt={item.alt}
                style={{ width: "100%", display: "block" }}
            />
        );
    };

    const thumbnailTemplate = (item) => {
        return (
            <img
                src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${item.urlImage + "-" + item.fileName}`}
                alt={item.alt}
                style={{ width: "100%", display: "block" }}
            />
        );
    };
    return (
        <>
            <div className="card">
                <div className="flex flex-wrap gap-3 mb-5">
                    {positionOptions.map((option) => {
                        const { label, value } = option;

                        return (
                            <div className="flex align-items-center" key={label}>
                                <RadioButton
                                    value={value}
                                    onChange={(e) => setPosition(e.value)}
                                    checked={position === value}
                                />
                                <label htmlFor={label} className="ml-2">
                                    {label}
                                </label>
                            </div>
                        );
                    })}
                </div>
                <Galleria
                    style={{ maxWidth: "640px" }}
                    value={images}
                    responsiveOptions={responsiveOptions}
                    numVisible={5}
                    item={itemTemplate}
                    thumbnailsPosition={position}
                    thumbnail={thumbnailTemplate}
                />
            </div>
        </>
    );
}

export default TestUi;
