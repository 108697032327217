// src/redux/slices/dataSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Định nghĩa action async để gọi API và xử lý dữ liệu
export const fetchData = createAsyncThunk("data/fetchData", async (_, { rejectWithValue }) => {
    try {
        const response = await fetch(process.env.REACT_APP_URL+"/api/v1/products");
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const dataSlice = createSlice({
    name: "data",
    initialState: {
        loading: false,
        error: null,
        data: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default dataSlice.reducer;
