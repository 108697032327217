import axios from "axios";
import {AxiosService} from "./AxiosService";

const BASE_URL = process.env.REACT_APP_BASE_URL; // Đổi thành URL thật của bạn

const ReviewServices = {
    getAllReview: async () => {
        try {
            const response = await AxiosService.get(`${BASE_URL}/api/v1/reviews`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
};
export default ReviewServices;