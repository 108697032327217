import { useState, useEffect } from "react";
import { CommonService } from "../../../services/CommonService";
import { Link } from "react-router-dom";
function TrackingOrder() {
    const [infoCustomer, setInfoCustomer] = useState([]);

    useEffect(() => {
        const infoKoro = JSON.parse(localStorage.getItem("infoKoro"));
        setInfoCustomer(infoKoro);
    }, []); // Chỉ chạy một lần khi component được mount

    return (
        <>
            <main>
                {/* breadcrumb area start */}
                <section className="breadcrumb__area include-bg pt-95 pb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="breadcrumb__content p-relative z-index-1">
                                    <h3 className="breadcrumb__title">Đơn hàng của bạn</h3>
                                    <div className="breadcrumb__list">
                                        <span>
                                            <Link to="/">Trang chủ</Link>
                                        </span>
                                        <span>Đơn hàng của bạn</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* breadcrumb area end */}
                {/* order area start */}
                <section className="tp-order-area pb-160">
                    <div className="container">
                        <div className="tp-order-inner">
                            <div className="row gx-0">
                                <div className="col-lg-6">
                                    <div className="tp-order-details" style={{ background: "#4F3D97" }}>
                                        <div className="tp-order-details-top text-center mb-70">
                                            <div className="tp-order-details-icon">
                                                <span>
                                                    <svg
                                                        width={52}
                                                        height={52}
                                                        viewBox="0 0 52 52"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M46 26V51H6V26"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M51 13.5H1V26H51V13.5Z"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M26 51V13.5"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M26 13.5H14.75C13.0924 13.5 11.5027 12.8415 10.3306 11.6694C9.15848 10.4973 8.5 8.9076 8.5 7.25C8.5 5.5924 9.15848 4.00269 10.3306 2.83058C11.5027 1.65848 13.0924 1 14.75 1C23.5 1 26 13.5 26 13.5Z"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M26 13.5H37.25C38.9076 13.5 40.4973 12.8415 41.6694 11.6694C42.8415 10.4973 43.5 8.9076 43.5 7.25C43.5 5.5924 42.8415 4.00269 41.6694 2.83058C40.4973 1.65848 38.9076 1 37.25 1C28.5 1 26 13.5 26 13.5Z"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="tp-order-details-content">
                                                <h3 className="tp-order-details-title">Xác nhận đơn hàng</h3>
                                                <p>
                                                    Chúng tôi sẽ sớm gửi cho bạn email xác nhận<br />
                                                    khi đơn đặt hàng được vận chuyển
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tp-order-details-item-wrapper">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="tp-order-details-item">
                                                        <h4>Ngày đặt:</h4>
                                                        <p>{CommonService.formatDate(infoCustomer.orderTime ? infoCustomer.orderTime : null)}</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="tp-order-details-item">
                                                        <h4>Ngày dự kiến nhận: </h4>
                                                        <p>Nội thành HN 1 ngày, liên tỉnh 2-4 ngày</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="tp-order-details-item">
                                                        <h4>Mã đơn hàng:</h4>
                                                        <p>#{infoCustomer.billCode ? infoCustomer.billCode : null}</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="tp-order-details-item">
                                                        <h4>Phương thức thanh toán:</h4>
                                                        <p>COD</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="tp-order-info-wrapper">
                                        <h4 className="tp-order-info-title">Chi tiết đơn hàng</h4>
                                        <div className="tp-order-info-list">
                                            <ul>
                                                {/* header */}
                                                <li className="tp-order-info-list-header">
                                                    <h4></h4>
                                                    <h4>Tổng</h4>
                                                </li>
                                                {/* item list */}
                                                {/* {infoCustomer.} */}
                                                {infoCustomer &&
                                                    infoCustomer.orderDetail &&
                                                    infoCustomer.orderDetail.length > 0 &&
                                                    infoCustomer.orderDetail.map((orDe, index) => (
                                                        <li className="tp-order-info-list-desc" key={index}>
                                                            <i>
                                                                {orDe.productName}
                                                                <span className="text-blue-500">
                                                                    {" "}
                                                                    x {orDe.proDetail[0].quantity}
                                                                </span>
                                                            </i>
                                                            <span>
                                                                <i className="text-blue-500">
                                                                    {CommonService.handleFomatCurrency(orDe.proDetail[0].proDe.price -
                                                                        orDe.proDetail[0].proDe.discount)}
                                                                </i>
                                                            </span>
                                                        </li>
                                                    ))}
                                                {/* subtotal */}
                                                <li className="tp-order-info-list-subtotal">
                                                    <span>Tiền hàng</span>
                                                    <span>
                                                        {CommonService.handleFomatCurrency(
                                                            infoCustomer.productMoney ? infoCustomer.productMoney : 0
                                                        )}
                                                    </span>
                                                </li>
                                                {/* shipping */}
                                                <li className="tp-order-info-list-subtotal">
                                                    <span>Phí ship</span>
                                                    <span>
                                                        {CommonService.handleFomatCurrency(
                                                            infoCustomer.shippingPrice ? infoCustomer.shippingPrice : 0
                                                        )}
                                                    </span>
                                                </li>
                                                <li className="tp-order-info-list-subtotal">
                                                    <span>Giảm giá ưu đãi</span>
                                                    <span>
                                                        -{CommonService.handleFomatCurrency(
                                                            infoCustomer.shippingPrice ? infoCustomer.discount : 0
                                                        )}
                                                    </span>
                                                </li>
                                                {/* shipping */}
                                                {/* total */}
                                                <li className="tp-order-info-list-total">
                                                    <span>Thanh toán</span>
                                                    <span>
                                                        {CommonService.handleFomatCurrency(
                                                            infoCustomer.totalMoney ? infoCustomer.totalMoney : 0
                                                        )}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* order area end */}
            </main>
        </>
    );
}

export default TrackingOrder;
