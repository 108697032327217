import { useState, useEffect } from "react";
import { Carousel } from "primereact/carousel";
import ProductServices from "../../services/ProductServices";
import ButtonProductDetailAction from "../Button/ButtonProductDetailAction";
import { Link } from "react-router-dom";
import { CommonService } from "../../services/CommonService";

function ProductRelatedCarousel(props) {
    const { productDetail } = props;
    const [productsSliderRelated, setProductsSliderRelated] = useState([]);
    useEffect(() => {
        getAllProductsRelated();
    }, []);
    const getAllProductsRelated = () => {
        const idArray = productDetail.categoryProduct.map((item) => item.id);
        const categoryId = idArray.join(",");

        ProductServices.getAllProductRelated(categoryId)
            .then((data) => {
                setProductsSliderRelated(data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const handleClickScroll = () => {
        window.scrollTo({
            top: 0,
        });
    };
    const productTemplate = (product) => {
        return (
            <>
                <div className="swiper-slide">
                    <div className="tp-product-item-3 tp-product-style-primary mb-50" style={{ marginRight: 10 }}>
                        <div className="tp-product-thumb-3 mb-15 fix p-relative z-index-1">
                            <Link to={`/product/${product.id}/${product.slug}`} onClick={handleClickScroll}>
                                <img src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${product.image}`} alt="" />
                            </Link>
                            <ButtonProductDetailAction product={product} />
                            <Link to={`/product/${product.id}/${product.slug}`} onClick={handleClickScroll}>
                                <div className="tp-product-add-cart-btn-large-wrapper">
                                    <button type="button" className="tp-product-add-cart-btn-large">
                                        Xem chi tiết
                                    </button>
                                </div>
                            </Link>
                        </div>
                        <div className="tp-product-content-3">
                            <div className="tp-product-tag-3">
                                <span>{product.category}</span>
                            </div>
                            <h3 className="tp-product-title-3">
                                <a href="product-details.html">{product.name}</a>
                            </h3>
                            <div className="tp-product-price-wrapper-3">
                                <span className="tp-product-price-3 new-price">
                                    {product.productDetail ? (
                                        <>
                                            {CommonService.checkDiscount(product.productDetail)
                                                ? CommonService.handleDiscountProduct(product.productDetail)
                                                : CommonService.handlePriceProduct(product.productDetail)}
                                        </>
                                    ) : (
                                        <>0 đ</>
                                    )}
                                </span>
                                <br />
                                <span className="tp-product-price-3 old-price text-red-500">
                                    {product.productDetail ? (
                                        <>
                                            {" "}
                                            {CommonService.checkDiscount(product.productDetail)
                                                ? CommonService.handlePriceProduct(product.productDetail)
                                                : null}{" "}
                                        </>
                                    ) : (
                                        <>0 đ</>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return (
        <>
            <Carousel
                value={productsSliderRelated}
                numVisible={4}
                numScroll={1}
                className="custom-carousel"
                circular
                // autoplayInterval={3000}
                itemTemplate={productTemplate}
            />
        </>
    );
}

export default ProductRelatedCarousel;
