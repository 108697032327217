import axios from "axios";
import {AxiosService} from "./AxiosService";

const BASE_URL = process.env.REACT_APP_BASE_URL;
export const OrderServices = {
    sendPlaceOrder: async (data) => {
        try {
            const response = await AxiosService.get(`${BASE_URL}/api/v1/categories`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    // async sendRequest(uri, method = "get", data = null, params = null) {
    //     try {
    //         const response = await axios({
    //             method: method,
    //             url: `${BASE_URL}${uri}`,
    //             data: data,
    //             params: params,
    //         });
    //         return response.data;
    //     } catch (error) {
    //         throw error;
    //     }
    // },
    async sendRequest(uri, data = null, params = null) {
        try {
            const response = await AxiosService.post(`${BASE_URL}${uri}`,data,params);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};
export default OrderServices;
