import { useEffect, useState } from "react";
import ProductServices from "../../../services/ProductServices";
import ProductDetail from "../../../components/Product/ProductDetail";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import ProductDetailList from "../../../components/Product/ProductDetailList";
import { Link } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { CategoryServices } from "../../../services/CategoryServices";

function Shop() {
    const [dataProducts, setDataProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [pageNumber, setPageNumber] = useState([]);
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState("center");
    const [showProduct, setShowProduct] = useState(true);
    //page
    const [page, setPage] = useState(1);
    const [first, setFirst] = useState(1);
    const [rows, setRows] = useState(12);

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const getCategories = () => {
        CategoryServices.getAllCategories()
            .then((data) => {
                setCategories(data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const onCategoryChange = (e) => {
        let _selectedCategories = [...selectedCategories];

        if (e.checked && e.value && e.value.id !== undefined) {
            _selectedCategories.push(e.value);
        } else {
            _selectedCategories = _selectedCategories.filter((category) => category && category.id !== e.value.id);
        }
        setSelectedCategories(_selectedCategories);
    };
    const selectSort = [
        { id: 1, name: "Low to hight" },
        { id: 2, name: "Hight to low" },
        { id: 3, name: "New added" },
        { id: 4, name: "On sale" },
    ];
    const show = (position) => {
        setPosition(position);
        setVisible(true);
    };
    const clickFilter = () => {
        setVisible(false);
        const categoryIds = selectedCategories.map((category) => category.id);

        ProductServices.getAllProductByCategories(categoryIds, page, rows, "id,asc")
            .then((data) => {
                //setProduct
                setProducts(data.data.content);
                setDataProducts(data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const toggleShowProduct = (data) => {
        setShowProduct(data); //show list or grid
    };

    const footerContent = (
        <div>
            <Button
                label="Áp dụng"
                icon="pi pi-check"
                outlined
                onClick={() => clickFilter()}
                className="text-white bg-bluegray-300 hover:text-white hover:bg-bluegray-900"
            />
        </div>
    );
    useEffect(() => {
        getAllProducts();
    }, [page, rows, first]);

    useEffect(() => {
        getCategories();
    }, []);
    const getAllProducts = () => {
        ProductServices.getAllProducts(page, rows, "id,asc")
            .then((data) => {
                //setProduct
                setProducts(data.data.content);
                setDataProducts(data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const onPageChange = (event) => {
        window.scrollTo(0, 0);
        setPage(event.page + 1);
        setRows(event.rows);
        setFirst(event.first);
    };

    return (
        <>
            <main>
                {/* breadcrumb area start */}
                <section className="breadcrumb__area include-bg pt-100 pb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="breadcrumb__content p-relative z-index-1">
                                    <h3 className="breadcrumb__title">Koro Shop</h3>
                                    <div className="breadcrumb__list">
                                        <span>
                                            <Link to="/">Trang chủ</Link>
                                        </span>
                                        <span>Cửa hàng</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* breadcrumb area end */}
                {/* shop area start */}
                <section className="tp-shop-area pb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="tp-shop-main-wrapper">
                                    <div className="tp-shop-top mb-45">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="tp-shop-top-left d-flex align-items-center ">
                                                    <div className="tp-shop-top-tab tp-tab">
                                                        <ul className="nav nav-tabs" id="productTab" role="tablist">
                                                            <li className="nav-item mr-1" role="presentation">
                                                                <Button
                                                                    className="text-bluegray-900 hover:bg-bluegray-900 hover:text-white"
                                                                    icon="pi pi-th-large"
                                                                    outlined
                                                                    onClick={() => toggleShowProduct(true)}
                                                                />
                                                            </li>
                                                            <li className="nav-item mr-1" role="presentation">
                                                                <Button
                                                                    className="text-bluegray-900 hover:bg-bluegray-900 hover:text-white"
                                                                    icon="pi pi-align-justify"
                                                                    outlined
                                                                    onClick={() => toggleShowProduct(false)}
                                                                />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="tp-shop-top-result">
                                                        {/* <p>Hiển thị 1–14 of {products.length} results</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="tp-shop-top-right tp-shop-top-right-2 d-sm-flex align-items-center justify-content-md-end">
                                                    {/* <div className="tp-shop-top-select">
                                                        <Dropdown
                                                            value={selectedSort}
                                                            onChange={(e) => setSelectedSort(e.value)}
                                                            options={selectSort}
                                                            optionLabel="name"
                                                            placeholder="Sắp xếp"
                                                            className="w-full md:w-14rem"
                                                        />
                                                    </div> */}
                                                    <div className="tp-shop-top-filter">
                                                        <Button
                                                            style={{ minWidth: "10rem" }}
                                                            onClick={() => show("top")}
                                                            icon="pi pi-sliders-h"
                                                            label="Lọc"
                                                            className="bg-bluegray-900 hover:bg-white border-bluegray-900 hover:text-bluegray-900"
                                                        ></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tp-shop-items-wrapper tp-shop-item-primary">
                                        <div className="" id="">
                                            {showProduct && (
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="grid-tab-pane"
                                                    role="tabpanel"
                                                    aria-labelledby="grid-tab"
                                                    tabIndex={0}
                                                >
                                                    <div className="row">
                                                        {products.map((product, index) => (
                                                            <div
                                                                key={index}
                                                                className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6"
                                                            >
                                                                <ProductDetail product={product} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {!showProduct && (
                                                <div
                                                    className="tab-pane"
                                                    id="list-tab-pane"
                                                    role="tabpanel"
                                                    aria-labelledby="list-tab"
                                                    tabIndex={0}
                                                >
                                                    <div className="tp-shop-list-wrapper tp-shop-item-primary mb-70">
                                                        <div className="row justify-content-center">
                                                            <div className="col-xl-10">
                                                                {products.map((product, index) => (
                                                                    <ProductDetailList key={index} product={product} />
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="tp-shop-pagination mt-20">
                                        <div className="tp-pagination">
                                            <nav>
                                                <ul>
                                                    <Paginator
                                                        first={first}
                                                        rows={rows}
                                                        totalRecords={dataProducts.totalElements}
                                                        rowsPerPageOptions={[12, 24, 48]}
                                                        onPageChange={onPageChange}
                                                    />
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* shop area end */}
                <Dialog
                    header="Lọc sản phẩm"
                    visible={visible}
                    position={position}
                    style={{ width: "30vw" }}
                    onHide={() => setVisible(false)}
                    footer={footerContent}
                    draggable={false}
                    resizable={false}
                    breakpoints={{ "960px": "90vw", "641px": "60vw" }}
                >
                    <div className="row">
                        <div className="col-lg-12">
                            {/* status */}
                            <div className="tp-shop-widget mb-50">
                                <h3 className="tp-shop-widget-title">Danh mục</h3>
                                <div className="tp-shop-widget-content">
                                    <div className="tp-shop-widget-checkbox">
                                        {/* {categories.map((category) => {
                                            return (
                                                <div key={category.id} className="flex align-items-center">
                                                    <Checkbox
                                                        inputId={category.id}
                                                        name="category"
                                                        value={category}
                                                        onChange={onCategoryChange}
                                                        checked={selectedCategories.some(
                                                            (item) => item.id === category.id
                                                        )}
                                                    />
                                                    <label htmlFor={category.id} className="ml-2">
                                                        {category.name}
                                                    </label>
                                                </div>
                                            );
                                        })} */}
                                        {categories.map((category) => {
                                            return (
                                                <div key={category.id} className="flex align-items-center">
                                                    <Checkbox
                                                        inputId={category.id} // Chuyển đổi id sang chuỗi nếu cần
                                                        name="category"
                                                        value={category}
                                                        onChange={onCategoryChange}
                                                        checked={selectedCategories.some(
                                                            (item) => item && item.id === category.id
                                                        )}
                                                    />
                                                    <label htmlFor={category.id} className="ml-2">
                                                        {category.name}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </main>
        </>
    );
}

export default Shop;
