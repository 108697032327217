import { CommonService } from "../../services/CommonService";
import { Link } from "react-router-dom";
import ButtonProductDetailAction from "../Button/ButtonProductDetailAction";

function ProductDetailList(props) {
    const { product } = props;
    return (
        <>
            <div className="tp-product-list-item d-md-flex">
                <div className="tp-product-list-thumb p-relative fix">
                    <a href="#">
                        <img
                            src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${product.image}`}
                            alt=""
                            style={{ width: 300, height: "auto" }}
                        />
                    </a>
                    <div className="tp-product-action-2 tp-product-action-blackStyle">
                        <div className="tp-product-action-item-2 d-flex flex-column">
                            <ButtonProductDetailAction product={product} />
                        </div>
                    </div>
                </div>
                <div className="tp-product-list-content">
                    <div className="tp-product-content-2 pt-15">
                        <div className="tp-product-tag-2">
                            <Link href="/product">{product.category}</Link>
                        </div>
                        <h3 className="tp-product-title-2">
                            <Link href="/product">{product.name}</Link>
                        </h3>
                        <div className="tp-product-price-wrapper-2">
                            <span className="tp-product-price-2 new-price">
                                {product.productDetail ? (
                                    <>
                                        {CommonService.checkDiscount(product.productDetail)
                                            ? CommonService.handleDiscountProduct(product.productDetail)
                                            : CommonService.handlePriceProduct(product.productDetail)}
                                    </>
                                ) : (
                                    <>0 đ</>
                                )}
                            </span>
                            <span className="tp-product-price-2 old-price text-red-500">
                                {product.productDetail ? (
                                    <>
                                        {" "}
                                        {CommonService.checkDiscount(product.productDetail)
                                            ? CommonService.handlePriceProduct(product.productDetail)
                                            : null}{" "}
                                    </>
                                ) : (
                                    <>0 đ</>
                                )}
                            </span>
                        </div>
                        <p>{product.contentShort}</p>
                        <div className="tp-product-list-add-to-cart">
                            <button className="tp-product-list-add-to-cart-btn">Add To Cart</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductDetailList;
