import { useState, useEffect } from "react";
import { Carousel } from "primereact/carousel";
import { ProductService } from "../../services/ProductService";

function ProductImageCarousel(props) {
    const { productDetail } = props;
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const productDetailMainData = ProductService.getProductDetailMain();
        setProducts(productDetailMainData);
        productDetail.productImage.map((image, index) => {});
    }, []);

    const productTemplate = (product) => {
        return <img src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${product.urlImage + "-" + product.fileName}`} />;
    };

    return (
        <>
            <div className="tp-product-details-thumb-slider p-relative">
                <div className="tp-product-details-thumb-slider-active swiper-container">
                    <div className="swiper-wrapper">
                        <div className="tp-prouct-details-thumb-item w-img swiper-slide">
                            <Carousel
                                value={productDetail.productImage}
                                numVisible={1}
                                numScroll={1}
                                className="custom-carousel"
                                circular
                                autoplayInterval={3000}
                                itemTemplate={productTemplate}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductImageCarousel;
