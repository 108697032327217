// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "./slices/dataSlice";
import productReducer from "./slices/productSlice";
import categoryReducer from "./slices/categorySlice";

const store = configureStore({
    reducer: {
        data: dataReducer,
        products: productReducer,
        categories: categoryReducer,
    },
});

export default store;
