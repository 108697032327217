import { TabView, TabPanel } from "primereact/tabview";

function ProductDescription(props) {
    const { productDetail } = props;
    return (
        <>
            <div className="">
                <TabView>
                    <TabPanel header="Mô tả chi tiết">
                        <div className="tp-product-details-desc-wrapper pt-80">
                            <div className="row justify-content-center">
                                <div className="col-xl-10">
                                    <div className="tp-product-details-desc-item pb-105">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="tp-product-details-desc-content pt-25">
                                                    <h3 className="tp-product-details-desc-title">
                                                        <span>{productDetail.name}</span>
                                                    </h3>
                                                    {productDetail.content}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </>
    );
}

export default ProductDescription;
