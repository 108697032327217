import React from 'react';
import { Carousel } from 'primereact/carousel';

function HistoryNav() {
    const years = ['2016', '2017', '2018', '2019'];

    const itemTemplate = (year) => (
        <div className="tp-history-nav-year text-center">
            <p>{year}</p>
        </div>
    );

    return (
        <div className="tp-history-nav">
            <div className="row">
                <div className="col-xl-12">
                    <Carousel
                        value={years}
                        itemTemplate={itemTemplate}
                        numVisible={4}
                        numScroll={1}
                        responsiveOptions={[
                            {
                                breakpoint: '1024px',
                                numVisible: 3,
                                numScroll: 1,
                            },
                            {
                                breakpoint: '768px',
                                numVisible: 2,
                                numScroll: 1,
                            },
                            {
                                breakpoint: '560px',
                                numVisible: 1,
                                numScroll: 1,
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
}

export default HistoryNav;