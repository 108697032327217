import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { CommonService } from "../../services/CommonService";
import { Link } from "react-router-dom";
import EventBus from "../../evenBus/EvenBus";

function CartMini({ visibleRight, callBackVisibleRight }) {
    const [visible, setVisible] = useState(visibleRight);
    const [itemCart, setItemCart] = useState([]);
    const [carts, setCarts] = useState([]);

    useEffect(() => {
        setCarts(JSON.parse(localStorage.getItem("shoppingCartKoro")));
        setVisible(visibleRight);
        getProductsCart();
    }, [visibleRight]);

    const getProductsCart = () => {
        const cartData = CommonService.getCart();
        setCarts(cartData);
    };
    const sendCallBackVisibleRight = () => {
        callBackVisibleRight(false);
    };
    const setLocalStorage = () => {
        localStorage.setItem("shoppingCartKoro", JSON.stringify(itemCart));
    };

    const removeProductInCart = (idProduct) => {
        CommonService.handleRemoveProductId(idProduct);
        getProductsCart();
        EventBus.emit("cartUpdated");
    };

    const removeProductDetailInCart = (productId, idProDetail) => {
        CommonService.handleRemoveProductDetailId(productId, idProDetail);
        getProductsCart();
        EventBus.emit("cartUpdated");
    };

    return (
        <>
            <Sidebar visible={visible} position="right" onHide={() => sendCallBackVisibleRight()} className="w-50rem">
                <div className="cartmini__top-wrapper">
                    <div className="cartmini__top p-relative">
                        <div className="cartmini__top-title">
                            <h4 onClick={() => setLocalStorage()}>Giỏ hàng</h4>
                        </div>
                    </div>
                    <div className="cartmini__widget">
                        {carts &&
                            carts.map((item, index) => (
                                <div key={index} className="cartmini__widget-item">
                                    <div className="cartmini__thumb">
                                        <Link to="/product">
                                            <img
                                                src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${item.productImage}`}
                                                alt=""
                                                className="w-3rem"
                                            />
                                        </Link>
                                    </div>
                                    <div className="cartmini__content">
                                        <h5 className="cartmini__title">
                                            <Link to="/product">{item.productName}</Link>
                                        </h5>
                                        <div className="cartmini__price-wrapper">
                                            <div>
                                                {item.proDetail.map((proItemDetail, index) => (
                                                    <div key={index}>
                                                        <div className="flex">
                                                            <div className="flex align-items-center">
                                                                <img
                                                                    src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${proItemDetail.proDe.image}`}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "auto" }}
                                                                />
                                                                <div className=" text-xs ms-2">
                                                                    <div className="flex align-items-center">
                                                                        <span>{proItemDetail.proDe.codeName}</span>
                                                                    </div>
                                                                    <div className="flex align-items-center">
                                                                        <span className="cartmini__price">
                                                                            {CommonService.formatNumberToK(
                                                                                proItemDetail.proDe.price
                                                                            )}
                                                                        </span>
                                                                        <span className="cartmini__quantity">
                                                                            {" "}
                                                                            x {proItemDetail.quantity}
                                                                        </span>
                                                                        <span className="cartmini__price">
                                                                            {" "}
                                                                            ={" "}
                                                                            {CommonService.formatNumberToK(
                                                                                (proItemDetail.proDe.price -
                                                                                    proItemDetail.proDe.discount) *
                                                                                    proItemDetail.quantity
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <span>
                                                                        <i
                                                                            onClick={() =>
                                                                                removeProductDetailInCart(
                                                                                    item.productId,
                                                                                    proItemDetail.id
                                                                                )
                                                                            }
                                                                            className="text-red-500 ms-3 fa-regular fa-xmark font-bold text-xs cursor-pointer"
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <Link href="/" className="cartmini__del"> */}
                                    <i
                                        onClick={() => removeProductInCart(item.productId)}
                                        className="fa-regular fa-xmark text-red-500 font-bold cursor-pointer"
                                    />
                                    {/* </Link> */}
                                </div>
                            ))}
                    </div>
                </div>
                <div className="cartmini__checkout">
                    <div className="cartmini__checkout-title mb-30">
                        <h4>Tổng tiền:</h4>
                        <span>{CommonService.formatNumberToK(CommonService.handleTotalPriceCart())}</span>
                    </div>
                    <div className="cartmini__checkout-btn">
                        <Link to="/cart" onClick={() => sendCallBackVisibleRight()} className="tp-btn mb-10 w-100">
                            {" "}
                            Giỏ hàng
                        </Link>
                    </div>
                </div>
            </Sidebar>
        </>
    );
}

export default CartMini;
