import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "swiper/css";

import "./assets/css/bootstrap.css";
import "./assets/css/main.css";
import "./assets/css/animate.css";
import "./assets/css/swiper-bundle.css";
import "./assets/css/slick.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/font-awesome-pro.css";
import "./assets/css/flaticon_shofy.css";
import "./assets/css/spacing.css";

import reportWebVitals from "./reportWebVitals";
import router from "./router";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    </Provider>
);

reportWebVitals();
