import {AxiosService} from "./AxiosService";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const BannerServices = {
    getAllBanner: async () => {
        try {
            const response = await AxiosService.get(`${BASE_URL}/api/v1/client/banners`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    
};
export default BannerServices;

    