import IMAGE from "../../../const/image";
function BlogDetails() {
        return (

            <main>
                {/* blog details area start */}
                <section className="tp-postbox-details-area pb-120 pt-95">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-9">
                                <div className="tp-postbox-details-top">
                                    <div className="tp-postbox-details-category">
                    <span>
                      <a href="#">Beauty,</a>
                    </span>
                                        <span>
                      <a href="#">Trends</a>
                    </span>
                                    </div>
                                    <h3 className="tp-postbox-details-title">Here's the first Valentino's new Makeup Collection</h3>
                                    <div className="tp-postbox-details-meta mb-50">
                    <span data-meta="author">
                      <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.4104 8C9.33922 8 10.9028 6.433 10.9028 4.5C10.9028 2.567 9.33922 1 7.4104 1C5.48159 1 3.91797 2.567 3.91797 4.5C3.91797 6.433 5.48159 8 7.4104 8Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.4102 15.0001C13.4102 12.2911 10.721 10.1001 7.41016 10.1001C4.09933 10.1001 1.41016 12.2911 1.41016 15.0001" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      By <a href="#">TraoStudio</a>
                    </span>
                                        <span>
                      <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M10.5972 10.7259L8.42721 9.43093C8.04921 9.20693 7.74121 8.66793 7.74121 8.22693V5.35693" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      30 October, 2023
                    </span>
                                        <span>
                      <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5287 11.881L12.8017 14.093C12.8717 14.674 12.2487 15.08 11.7517 14.779L8.8187 13.036C8.4967 13.036 8.1817 13.015 7.8737 12.973C8.3917 12.364 8.6997 11.594 8.6997 10.761C8.6997 8.77299 6.9777 7.16302 4.8497 7.16302C4.0377 7.16302 3.2887 7.394 2.6657 7.8C2.6447 7.625 2.6377 7.44999 2.6377 7.26799C2.6377 4.08299 5.4027 1.5 8.8187 1.5C12.2347 1.5 14.9997 4.08299 14.9997 7.26799C14.9997 9.15799 14.0267 10.831 12.5287 11.881Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      <a href="#">Comments (2)</a>
                    </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div className="tp-postbox-details-thumb">
                                    <img src={IMAGE.blogDetailBig1} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-9 col-lg-8">
                                <div className="tp-postbox-details-main-wrapper">
                                    <div className="tp-postbox-details-content">
                                        <p className="tp-dropcap">sales process is critically important to the success of your reps and your business. If you've never seen a really skilled salesperson work, it seems almost effortless. They ask great questions, craftt perfect proposal, answer questions, address concerns and seamlessly seal the Underneath the surface of all of that, the salesperson has probably dedicated hours honing their craft and ensuring the process moves smoothly.</p>
                                        <p>One of the challenges that often surfaces when  working with a remote sales team is a lack of transparency over what is happening, and where in the process things are taking place. We’re going to peel back the curtain and show you how to create the best sales.</p>
                                        <h4 className="tp-postbox-details-heading">Breaking Up With Fast Fashion Has Been Easier</h4>
                                        <p>Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus magnis dis parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque euro, pretium, sem. Nulla onsequat massa quis enim. donec pede justo fringilla vel aliquet.</p>
                                        <div className="tp-postbox-details-desc-thumb text-center">
                                            <img src={IMAGE.blogDetailsSm1} alt="" />
                                            <span className="tp-postbox-details-desc-thumb-caption">Gucci’s Women’s Cruise Collection 2023 Lookbook Has Arrived</span>
                                        </div>
                                        <p>“We’re so glad we’ll be working with you to get your new marketing strategy up and running. I've attached the details of your package. Next you’ll get an email from Jen to schedule your kick-off meeting and be assigned your account rep. During your kick-off meeting, we will introduce your project team, let you know what access we need to start.” </p>
                                        <div className="tp-postbox-details-quote">
                                            <blockquote>
                                                <div className="tp-postbox-details-quote-shape">
                                                    <img className="tp-postbox-details-quote-shape-1" src={IMAGE.line} alt="" />
                                                    <img className="tp-postbox-details-quote-shape-2" src={IMAGE.qoute} alt="" />
                                                </div>
                                                <p>There is a way out of every box, a solution to every puzzle its just a matter of finding it.</p>
                                                <cite>Shahnewaz Sakil</cite>
                                            </blockquote>
                                        </div>
                                        <h4 className="tp-postbox-details-heading">Exploring the English Countryside</h4>
                                        <p>Lorem ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis parturient montes lorem,nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque euro, pretium quis, sem. Nulla onsequat massa quis enim.</p>
                                        <div className="tp-postbox-details-list">
                                            <ul>
                                                <li>Lorem ipsum dolor sit amet.</li>
                                                <li>At vero eos et accusamus et iusto odio.</li>
                                                <li>Excepteur sint occaecat cupidatat non proident.</li>
                                            </ul>
                                        </div>
                                        <p>Rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer cidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae lorem.</p>
                                        <div className="tp-postbox-details-share-wrapper">
                                            <div className="row">
                                                <div className="col-xl-8 col-lg-6">
                                                    <div className="tp-postbox-details-tags tagcloud">
                                                        <span>Tags:</span>
                                                        <a href="#">Lifesttyle</a>
                                                        <a href="#">Awesome</a>
                                                        <a href="#">Winter</a>
                                                        <a href="#">Sunglasses</a>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-lg-6">
                                                    <div className="tp-postbox-details-share text-md-end">
                                                        <span>Share:</span>
                                                        <a href="#"><i className="fa-brands fa-facebook-f" /></a>
                                                        <a href="#"><i className="fa-brands fa-twitter" /></a>
                                                        <a href="#"><i className="fa-brands fa-linkedin-in" /></a>
                                                        <a href="#"><i className="fa-brands fa-vimeo-v" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tp-postbox-details-navigation d-none d-md-flex justify-content-between align-items-center">
                                            <div className="tp-postbox-details-navigation-item d-flex align-items-center">
                                                <div className="tp-postbox-details-navigation-icon mr-15">
                          <span>
                            <a href="#">
                              <svg width={17} height={14} viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.999965 7.04891L15.939 7.04891" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M7.02588 1.04883L1.00048 7.04833L7.02588 13.0488" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </a>
                          </span>
                                                </div>
                                                <div className="tp-postbox-details-navigation-content">
                                                    <span>Previous Post</span>
                                                    <h3 className="tp-postbox-details-navigation-title">
                                                        <a href="#">Hiring the Right Sales Team at <br /> the Right Time</a>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="tp-postbox-details-navigation-item d-flex align-items-center text-end">
                                                <div className="tp-postbox-details-navigation-content">
                                                    <span>Next Post</span>
                                                    <h3 className="tp-postbox-details-navigation-title">
                                                        <a href="#">Fully embrace the return of <br /> 90s fashion</a>
                                                    </h3>
                                                </div>
                                                <div className="tp-postbox-details-navigation-icon ml-15">
                          <span>
                            <a href="#">
                              <svg width={17} height={14} viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.939 7.00008L1 7.00008" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.91211 1L15.9375 6.9995L9.91211 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </a>
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tp-postbox-details-author d-sm-flex align-items-start" data-bg-color="#F4F7F9">
                                            <div className="tp-postbox-details-author-thumb">
                                                <a href="#">
                                                    <img src={IMAGE.user11} alt="" />
                                                </a>
                                            </div>
                                            <div className="tp-postbox-details-author-content">
                                                <span>Written by</span>
                                                <h5 className="tp-postbox-details-author-title">
                                                    <a href="#">Theodore Handle</a>
                                                </h5>
                                                <p>By defining and following internal and external processes, your team will have clarity on resources to attract and retain customers for your business.</p>
                                                <div className="tp-postbox-details-author-social">
                                                    <a href="#"><i className="fa-brands fa-facebook-f" /></a>
                                                    <a href="#"><i className="fa-brands fa-twitter" /></a>
                                                    <a href="#"><i className="fa-brands fa-linkedin-in" /></a>
                                                    <a href="#"><i className="fa-brands fa-vimeo-v" /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tp-postbox-details-comment-wrapper">
                                            <h3 className="tp-postbox-details-comment-title">Comments (2)</h3>
                                            <div className="tp-postbox-details-comment-inner">
                                                <ul>
                                                    <li>
                                                        <div className="tp-postbox-details-comment-box d-sm-flex align-items-start">
                                                            <div className="tp-postbox-details-comment-thumb">
                                                                <img src={IMAGE.user2} alt="" />
                                                            </div>
                                                            <div className="tp-postbox-details-comment-content">
                                                                <div className="tp-postbox-details-comment-top d-flex justify-content-between align-items-start">
                                                                    <div className="tp-postbox-details-comment-avater">
                                                                        <h4 className="tp-postbox-details-comment-avater-title">Lance Bogrol</h4>
                                                                        <span className="tp-postbox-details-avater-meta">12 April, 2023 at 3.50pm</span>
                                                                    </div>
                                                                    <div className="tp-postbox-details-comment-reply">
                                                                        <a href="#">Reply</a>
                                                                    </div>
                                                                </div>
                                                                <p>By defining and following internal and external processes, your team will have clarity on resources to <br /> attract and retain customers for your business.</p>
                                                            </div>
                                                        </div>
                                                        <ul className="children">
                                                            <li>
                                                                <div className="tp-postbox-details-comment-box d-sm-flex align-items-start">
                                                                    <div className="tp-postbox-details-comment-thumb">
                                                                        <img src={IMAGE.user3} alt="" />
                                                                    </div>
                                                                    <div className="tp-postbox-details-comment-content">
                                                                        <div className="tp-postbox-details-comment-top d-flex justify-content-between align-items-start">
                                                                            <div className="tp-postbox-details-comment-avater">
                                                                                <h4 className="tp-postbox-details-comment-avater-title">Dasy Lily</h4>
                                                                                <span className="tp-postbox-details-avater-meta">12 April, 2023 at 3.50pm</span>
                                                                            </div>
                                                                            <div className="tp-postbox-details-comment-reply">
                                                                                <a href="#">Reply</a>
                                                                            </div>
                                                                        </div>
                                                                        <p>By defining and following internal and external processes, your team will have clarity on resources to <br /> attract and retain customers for your business.</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <div className="tp-postbox-details-comment-box d-sm-flex align-items-start">
                                                            <div className="tp-postbox-details-comment-thumb">
                                                                <img src={IMAGE.user4} alt="" />
                                                            </div>
                                                            <div className="tp-postbox-details-comment-content">
                                                                <div className="tp-postbox-details-comment-top d-flex justify-content-between align-items-start">
                                                                    <div className="tp-postbox-details-comment-avater">
                                                                        <h4 className="tp-postbox-details-comment-avater-title">Shahnewaz Sakil</h4>
                                                                        <span className="tp-postbox-details-avater-meta">12 April, 2023 at 3.50pm</span>
                                                                    </div>
                                                                    <div className="tp-postbox-details-comment-reply">
                                                                        <a href="#">Reply</a>
                                                                    </div>
                                                                </div>
                                                                <p>By defining and following internal and external processes, your team will have clarity on resources to <br /> attract and retain customers for your business.</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tp-postbox-details-form">
                                            <h3 className="tp-postbox-details-form-title">Leave a Reply</h3>
                                            <p>Your email address will not be published. Required fields are marked *</p>
                                            <div className="tp-postbox-details-form-wrapper">
                                                <div className="tp-postbox-details-form-inner">
                                                    <div className="tp-postbox-details-input-box">
                                                        <div className="tp-contact-input">
                                                            <input name="name" id="name" type="text" placeholder="Shahnewaz Sakil" />
                                                        </div>
                                                        <div className="tp-postbox-details-input-title">
                                                            <label htmlFor="name">Your Name</label>
                                                        </div>
                                                    </div>
                                                    <div className="tp-postbox-details-input-box">
                                                        <div className="tp-contact-input">
                                                            <input name="email" id="email" type="email" placeholder="shofy@mail.com" />
                                                        </div>
                                                        <div className="tp-postbox-details-input-title">
                                                            <label htmlFor="email">Your Email</label>
                                                        </div>
                                                    </div>
                                                    <div className="tp-postbox-details-input-box">
                                                        <div className="tp-contact-input">
                                                            <textarea id="msg" placeholder="Write your message here..." defaultValue={""} />
                                                        </div>
                                                        <div className="tp-postbox-details-input-title">
                                                            <label htmlFor="msg">Your Message</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tp-postbox-details-suggetions mb-20">
                                                    <div className="tp-postbox-details-remeber">
                                                        <input id="remeber" type="checkbox" />
                                                        <label htmlFor="remeber">Save my name, email, and website in this browser for the next time I comment.</label>
                                                    </div>
                                                </div>
                                                <div className="tp-postbox-details-input-box">
                                                    <button className="tp-postbox-details-input-btn" type="submit">Post Comment</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4">
                                <div className="tp-sidebar-wrapper tp-sidebar-ml--24">
                                    <div className="tp-sidebar-widget mb-35">
                                        <div className="tp-sidebar-search">
                                            <form action="#">
                                                <div className="tp-sidebar-search-input">
                                                    <input type="text" placeholder="Search..." />
                                                    <button type="submit">
                                                        <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.11111 15.2222C12.0385 15.2222 15.2222 12.0385 15.2222 8.11111C15.2222 4.18375 12.0385 1 8.11111 1C4.18375 1 1 4.18375 1 8.11111C1 12.0385 4.18375 15.2222 8.11111 15.2222Z" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M16.9995 17L13.1328 13.1333" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* about */}
                                    <div className="tp-sidebar-widget mb-35">
                                        <h3 className="tp-sidebar-widget-title">About me</h3>
                                        <div className="tp-sidebar-widget-content">
                                            <div className="tp-sidebar-about">
                                                <div className="tp-sidebar-about-thumb mb-25">
                                                    <a href="#">
                                                        <img src={IMAGE.user11} alt="" />
                                                    </a>
                                                </div>
                                                <div className="tp-sidebar-about-content">
                                                    <h3 className="tp-sidebar-about-title">
                                                        <a href="#">Ravi O'Leigh</a>
                                                    </h3>
                                                    <span className="tp-sidebar-about-designation">Photographer &amp; Blogger</span>
                                                    <p>Lorem ligula eget dolor. Aenean massa. Cum sociis que penatibus magnis dis parturient</p>
                                                    <div className="tp-sidebar-about-signature">
                                                        <img src={IMAGE.signature} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* about end */}
                                    {/* latest post start */}
                                    <div className="tp-sidebar-widget mb-35">
                                        <h3 className="tp-sidebar-widget-title">Latest Posts</h3>
                                        <div className="tp-sidebar-widget-content">
                                            <div className="tp-sidebar-blog-item-wrapper">
                                                <div className="tp-sidebar-blog-item d-flex align-items-center">
                                                    <div className="tp-sidebar-blog-thumb">
                                                        <a href="#">
                                                            <img src={IMAGE.blogSlideBar1} alt="" />
                                                        </a>
                                                    </div>
                                                    <div className="tp-sidebar-blog-content">
                                                        <div className="tp-sidebar-blog-meta">
                                                            <span>12 April, 2023</span>
                                                        </div>
                                                        <h3 className="tp-sidebar-blog-title">
                                                            <a href="#">Sweeten your summer Wardrobes</a>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="tp-sidebar-blog-item d-flex align-items-center">
                                                    <div className="tp-sidebar-blog-thumb">
                                                        <a href="#">
                                                            <img src={IMAGE.blogSlideBar2} alt="" />
                                                        </a>
                                                    </div>
                                                    <div className="tp-sidebar-blog-content">
                                                        <div className="tp-sidebar-blog-meta">
                                                            <span>8 July, 2023</span>
                                                        </div>
                                                        <h3 className="tp-sidebar-blog-title">
                                                            <a href="#">Exploring the English Countryside</a>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="tp-sidebar-blog-item d-flex align-items-center">
                                                    <div className="tp-sidebar-blog-thumb">
                                                        <a href="#">
                                                            <img src={IMAGE.blogSlideBar3} alt="" />
                                                        </a>
                                                    </div>
                                                    <div className="tp-sidebar-blog-content">
                                                        <div className="tp-sidebar-blog-meta">
                                                            <span>12 April, 2023</span>
                                                        </div>
                                                        <h3 className="tp-sidebar-blog-title">
                                                            <a href="#">Freelancer Days 2023, What’s new?</a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* latest post end */}
                                    {/* categories start */}
                                    <div className="tp-sidebar-widget widget_categories mb-35">
                                        <h3 className="tp-sidebar-widget-title">Categories</h3>
                                        <div className="tp-sidebar-widget-content">
                                            <ul>
                                                <li><a href="#">Farming <span>(12)</span></a></li>
                                                <li><a href="#">Crisp Bread &amp; Cake <span>(6)</span></a></li>
                                                <li><a href="#">Milk &amp; Meat <span>(2)</span></a></li>
                                                <li><a href="#">Organic Fruits <span>(8)</span></a></li>
                                                <li><a href="#">Sea Foods <span>(0)</span></a></li>
                                                <li><a href="#">Vegetable <span>(3)</span></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* categories end */}
                                    {/* tag cloud start */}
                                    <div className="tp-sidebar-widget mb-35">
                                        <h3 className="tp-sidebar-widget-title">Popular Tags</h3>
                                        <div className="tp-sidebar-widget-content tagcloud">
                                            <a href="#">Summer</a>
                                            <a href="#">Vintage</a>
                                            <a href="#">Sunglasses</a>
                                            <a href="#">Organic Food</a>
                                            <a href="#">Lifesttyle</a>
                                            <a href="#">Nature</a>
                                        </div>
                                    </div>
                                    {/* tag cloud end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* blog details area end */}
            </main>
        );
    }
export default BlogDetails;
