import { useState } from "react";
import { Password } from 'primereact/password';
import IMAGE from "../../../const/image";

function Register() {
    const [password, setPassword] = useState('');
    return (
        <>
            <main>
                {/* breadcrumb area start */}
                <section className="breadcrumb__area include-bg text-center pt-95 pb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="breadcrumb__content p-relative z-index-1">
                                    <h3 className="breadcrumb__title">Register Now</h3>
                                    <div className="breadcrumb__list">
                                        <span><a href="#">Home</a></span>
                                        <span>Register</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* breadcrumb area end */}
                {/* login area start */}
                <section className="tp-login-area pb-140 p-relative z-index-1 fix">
                    <div className="tp-login-shape">
                        <img className="tp-login-shape-1" src={IMAGE.loginShape1} alt=""/>
                        <img className="tp-login-shape-2" src={IMAGE.loginShape2} alt=""/>
                        <img className="tp-login-shape-3" src={IMAGE.loginShape3} alt=""/>
                        <img className="tp-login-shape-4" src={IMAGE.loginShape4} alt=""/>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-8">
                                <div className="tp-login-wrapper">
                                    <div className="tp-login-top text-center mb-30">
                                        <h3 className="tp-login-title">Sign Up Shofy.</h3>
                                        <p>Already have an account? <span><a href="#">Sign In</a></span></p>
                                    </div>
                                    <div className="tp-login-option">
                                        <div
                                            className="tp-login-social mb-10 d-flex flex-wrap align-items-center justify-content-center">
                                            <div className="tp-login-option-item has-google">
                                                <a href="#">
                                                    <img src={IMAGE.google} alt=""/>
                                                    Sign up with google
                                                </a>
                                            </div>
                                            <div className="tp-login-option-item">
                                                <a href="#">
                                                    <img src={IMAGE.facebook} alt=""/>
                                                </a>
                                            </div>
                                            <div className="tp-login-option-item">
                                                <a href="#">
                                                    <img className="apple" src={IMAGE.apple}
                                                         alt=""/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="tp-login-mail text-center mb-40">
                                            <p>or Sign up with <a href="#">Email</a></p>
                                        </div>
                                        <div className="tp-login-input-wrapper">
                                            <div className="tp-login-input-box">
                                                <div className="tp-login-input">
                                                    <input id="name" type="text" placeholder="Shahnewaz Sakil"/>
                                                </div>
                                                <div className="tp-login-input-title">
                                                    <label htmlFor="name">Your Name</label>
                                                </div>
                                            </div>
                                            <div className="tp-login-input-box">
                                                <div className="tp-login-input">
                                                    <input id="email" type="email" placeholder="shofy@mail.com"/>
                                                </div>
                                                <div className="tp-login-input-title">
                                                    <label htmlFor="email">Your Email</label>
                                                </div>
                                            </div>
                                            <div className="tp-login-input-box">
                                                <div className="tp-login-input">
                                                    <Password id="password" placeholder="Min. 8 character" value={password} onChange={(e) => setPassword(e.target.password)} toggleMask className="w-full"/>
                                                </div>
                                                <div className="tp-login-input-title">
                                                    <label htmlFor="password">Password</label>
                                                </div>
                                            </div>

                                        </div>
                                        <div
                                            className="tp-login-suggetions d-sm-flex align-items-center justify-content-between mb-20">
                                            <div className="">
                                                <input id="remeber" type="checkbox"/>
                                                <label htmlFor="remeber">I accept the terms of the Service &amp; <a
                                                    href="#">Privacy Policy</a>.</label>
                                            </div>
                                        </div>
                                        <div className="tp-login-bottom">
                                            <a href="#" className="tp-login-btn w-100">Sign Up</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* login area end */}
            </main>
        </>

    )

}

export default Register;