import {AxiosService} from "./AxiosService";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const AddressServices = {
    getAllProvince: async () => {
        try {
            const response = await AxiosService.get(`${BASE_URL}/api/v1/address/province`);
            return response.data.data;
        } catch (error) {
            throw error;
        }
    },
    getAllDistrict: async (province) => {
        const firstProvince = "An Giang";
        try {
            const response = await AxiosService.get(`${BASE_URL}/api/v1/address/district?province=${province?province:firstProvince}`);
            return response.data.data;
        } catch (error) {
            throw error;
        }
    },
    getAllWard: async (district) => {
        const firstDistrict = "Huyện An Phú";
        try {
            const response = await AxiosService.get(`${BASE_URL}/api/v1/address/ward?district=${district?district:firstDistrict}`);
            return response.data.data;
        } catch (error) {
            throw error;
        }
    },
    
};
export default AddressServices;

    