import { useState, useRef } from "react";
import { CommonService } from "../../services/CommonService";
import { Tag } from "primereact/tag";
import EventBus from "../../evenBus/EvenBus";
import IMAGE from "../../const/image";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
function ProductDesc(props) {
    const { productDetail } = props;
    // const [product, setProduct] = useState([]);
    const [productQuantities, setProductQuantities] = useState([]);
    const [priceProDetail, setPriceProDetail] = useState(null);
    const [value, setValue] = useState(0);
    const toast = useRef(null);
    const showSuccess = (message, time) => {
        toast.current.show({ severity: "success", summary: "Thành công", detail: message, life: time });
    };
    const showError = (message, time) => {
        toast.current.show({ severity: "error", summary: "Thất bại", detail: message, life: time });
    };

    const handleQuantityChange = (inputId, inputQuantity, inputProDe) => {
        if (inputQuantity > inputProDe.validQuantity) {
            // Xóa đối tượng có inputId khỏi mảng productQuantities
            showError("Bạn hãy chọn số lượng nhỏ hơn");
            return;
        }
        if(inputQuantity === 0){
            const updatedQuantities = productQuantities.filter(item => item.id !== inputId);
            setProductQuantities(updatedQuantities);
        }

        if (inputQuantity <= inputProDe.validQuantity && inputQuantity>0) {
            const item = {
                id: inputId,
                quantity: inputQuantity,
                proDe: inputProDe,
                productId: productDetail.id,
                productName: productDetail.name,
                productImage: productDetail.image,
            };
            setProductQuantities([...productQuantities, item]);
            console.log(productQuantities)
        }
    };
    const clickProDetail = (proDetail) => {
        setPriceProDetail(proDetail.price - proDetail.discount);
    };
    //thêm giỏ
    const onClickAddItemInCart = (productQuantities) => {
        CommonService.addItemToCart(productQuantities);
        EventBus.emit("cartUpdated");
        setProductQuantities([]);
    };
    return (
        <>
            <Toast ref={toast} />
            <div className="tp-product-details-wrapper">
                <h3 className="tp-product-details-title">{productDetail.name}</h3>
                {/* inventory details */}
                <div className="tp-product-details-inventory d-flex align-items-center mb-10">
                    <div className="tp-product-details-stock mb-10">
                        {/* <span>{product.status}</span> */}
                        <p>
                            <b>Mô tả: </b>
                            <br />
                            {productDetail.contentShort}
                        </p>
                    </div>
                </div>
                <p>{productDetail.desc}</p>
                {/* price */}
                <div className="tp-product-details-price-wrapper mb-20">
                    <span className="tp-product-details-price new-price">
                        {priceProDetail === null ? (
                            productDetail.productDetail ? (
                                <>
                                    {CommonService.checkDiscount(productDetail.productDetail)
                                        ? CommonService.handleDiscountProduct(productDetail.productDetail)
                                        : CommonService.handlePriceProduct(productDetail.productDetail)}
                                </>
                            ) : (
                                <>0 đ</>
                            )
                        ) : (
                            <>{CommonService.handleFomatCurrency(priceProDetail)}</>
                        )}
                    </span>
                    <br />
                    <span className="tp-product-details-price old-price text-red-500">
                        {productDetail.productDetail ? (
                            <>
                                {" "}
                                {CommonService.checkDiscount(productDetail.productDetail)
                                    ? CommonService.handlePriceProduct(productDetail.productDetail)
                                    : null}{" "}
                            </>
                        ) : (
                            <>0 đ</>
                        )}
                    </span>
                </div>
                {/* variations */}
                <div className="tp-product-details-variation">
                    {/* single item */}
                    <div className="tp-product-details-variation-item">
                        <h4 className="tp-product-details-variation-title">
                            <b>Phân loại:</b>
                        </h4>
                        <div className="tp-product-details-variation-list">
                            <div className="flex justify-content-between flex-wrap">
                                {productDetail.productDetail.map((proDetail, index) => (
                                    <span className="flex" key={index} onClick={() => clickProDetail(proDetail)}>
                                        {proDetail.validQuantity > 0 && (
                                            <>
                                                <div className="flex align-items-center justify-content-center">
                                                        <Image
                                                            src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${proDetail.image}`}
                                                            alt="Image"
                                                            width="70px"
                                                            style={{borderRadius:"5px"}}
                                                            className="border-1 m-2"
                                                            preview
                                                        />
                                                        <label htmlFor="ingredient1" className="cursor-pointer p-2">
                                                            <Tag
                                                                style={{
                                                                    width: "100px",
                                                                    // height: "50px",
                                                                    background: "#677489",
                                                                }}
                                                                severity="success"
                                                                value={proDetail.skuName}
                                                                rounded
                                                            ></Tag>
                                                        </label>
                                                </div>
                                                <div className="flex align-items-center justify-content-center p-2">
                                                    <InputNumber
                                                        value={value}
                                                        onChange={(e) =>{
                                                            console.log("Event Value: ", e.value);
                                                            handleQuantityChange(proDetail.id, e.value, proDetail)
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    {proDetail.validQuantity < 5 ?
                                                        <div>Còn {proDetail.validQuantity}</div> : null}
                                                </div>
                                            </>
                                        )}
                                        {proDetail.validQuantity <= 0 && (
                                            <>
                                                <div className="flex align-items-center justify-content-center">
                                                    <img
                                                        src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${proDetail.image}`}
                                                        alt={proDetail.image}
                                                        style={{ width: "30px", height: "auto" }}
                                                    />
                                                    <label htmlFor="ingredient1" className="cursor-pointer ml-2">
                                                        <Tag
                                                            severity="danger"
                                                            value={proDetail.skuName}
                                                            style={{ width: "125px", background: "#677489" }}
                                                        ></Tag>
                                                    </label>
                                                </div>
                                                <div className="flex align-items-center justify-content-center ps-2 pe-2">
                                                    <InputNumber
                                                        inputStyle={{ height: "25px", width: "125px" }}
                                                        value={value}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="text-red-400 text-xs">tạm hết</div>
                                            </>
                                        )}
                                        <hr />
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {/* actions */}
                <div className="tp-product-details-action-wrapper">
                    <div className="tp-product-details-action-item-wrapper d-flex align-items-center">
                        <div className="tp-product-details-add-to-cart mb-15 w-100">
                            <Button
                                className="w-100"
                                onClick={() => onClickAddItemInCart(productQuantities)}
                                label="Thêm giỏ ngay"
                                severity={productQuantities.length>0 ?"success":"secondary"}
                            />
                        </div>
                    </div>
                </div>
                <div className="tp-product-details-query">
                    <div className="tp-product-details-query-item d-flex align-items-center">
                        <span>SKU:</span>
                        <p>{productDetail.slug}</p>
                    </div>
                    <div className="tp-product-details-query-item d-flex align-items-center">
                        <span>Danh mục:</span>
                        <p>{CommonService.handleCategory(productDetail.categoryProduct)}</p>
                    </div>
                </div>
                <div className="tp-product-details-social">
                    <span>Share: </span>
                    <a href="https://www.facebook.com/korostore68" target="_blank">
                        <i className="fa-brands fa-facebook-f" />
                    </a>
                    <a href="https://www.instagram.com/koro_socks/" target="_blank">
                        <i className="fa-brands fa-linkedin-in" />
                    </a>
                    <a
                        href={productDetail.linkTiktok ? productDetail.linkTiktok : `https://www.tiktok.com/@korohouse`}
                        target="_blank"
                    >
                        <i className="fa-brands fa-tiktok" />
                    </a>
                    <a
                        href={productDetail.linkShopee ? productDetail.linkShopee : `https://shopee.vn/korohouse`}
                        target="_blank"
                    >
                        <img src={IMAGE.logoShopee} alt="" className="w-full" />
                    </a>
                </div>
                <div className="tp-product-details-msg mb-15">
                    <ul>
                        <li>Đổi trả trong 2 ngày kể từ khi nhận hàng</li>
                    </ul>
                </div>
                <div className="tp-product-details-payment d-flex align-items-center flex-wrap justify-content-between">
                    <p>Hình thức thanh toán khi nhận hàng</p>
                    <img src="assets/img/product/icons/payment-option.png" alt="" />
                </div>
            </div>
        </>
    );
}

export default ProductDesc;
