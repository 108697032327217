import { useState, useEffect } from "react";
import { Carousel } from "primereact/carousel";
// import { ProductService } from "../../services/ProductService";
import IMAGE from "../../const/image";
// import { Link } from "react-router-dom";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import BannerServices from "../../services/BannerServices";
function BannerTop() {
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        getAllBanner();
    }, []);
    async function getAllBanner() {
        try {
            const getbanner = await BannerServices.getAllBanner();
            setBanners(getbanner);
        } catch (error) {
            console.error("Đã xảy ra lỗi:", error);
        }
    }
    const bannerTemplate = (banner) => {
        return (
            <>
                <div className="tp-slider-2-shape">
                    <img className="tp-slider-2-shape-1" src={IMAGE.homeBannerShape1} alt="" />
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="tp-slider-content-2">
                                <span>{banner.desc}</span>
                                <h3 className="tp-slider-title-2">{banner.title}</h3>
                                <div className="tp-slider-btn-2">
                                    <a href={banner.buttonUrl} className="tp-btn tp-btn-border">
                                        {banner.button}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="tp-slider-thumb-2-wrapper p-relative">
                                <div className="tp-slider-thumb-2-shape">
                                    <img className="tp-slider-thumb-2-shape-1" src={IMAGE.homeBannerShape2} alt="" />
                                    <img className="tp-slider-thumb-2-shape-2" src={IMAGE.homeBannerShape3} alt="" />
                                </div>
                                <div className="tp-slider-thumb-2 text-end">
                                    <span className="tp-slider-thumb-2-gradient" />
                                    <img src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${banner.image}`} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return (
        <>
            <Carousel
                value={banners}
                numVisible={1}
                numScroll={1}
                className="custom-carousel"
                circular
                autoplayInterval={5000}
                itemTemplate={bannerTemplate}
            />
        </>
    );
}

export default BannerTop;
