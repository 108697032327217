import productDetailNav1 from "../assets/img/product/details/nav/product-details-nav-1.jpg";
import productDetailNav2 from "../assets/img/product/details/nav/product-details-nav-2.jpg";
import productDetailNav3 from "../assets/img/product/details/nav/product-details-nav-3.jpg";
import productDetailNav4 from "../assets/img/product/details/nav/product-details-nav-4.jpg";
import productDetailNav5 from "../assets/img/product/details/nav/product-details-nav-5.jpg";

import productDetailMain1 from "../assets/img/product/details/main/product-details-main-1.jpg";
import productDetailMain2 from "../assets/img/product/details/main/product-details-main-2.jpg";
import productDetailMain3 from "../assets/img/product/details/main/product-details-main-3.jpg";
import productDetailMain4 from "../assets/img/product/details/main/product-details-main-4.jpg";
import productDetailMain5 from "../assets/img/product/details/main/product-details-main-5.jpg";

import productDetailSlider1 from "../assets/img/product/details/slider/product-details-slider-1.jpg";
import productDetailSlider2 from "../assets/img/product/details/slider/product-details-slider-2.jpg";
import productDetailSlider3 from "../assets/img/product/details/slider/product-details-slider-3.jpg";
import productDetailSlider4 from "../assets/img/product/details/slider/product-details-slider-4.jpg";
import productDetailSlider5 from "../assets/img/product/details/slider/product-details-slider-5.jpg";
import productDetailSlider6 from "../assets/img/product/details/slider/product-details-slider-6.jpg";

import iconPaymentOption from "../assets/img/product/icons/payment-option.png";

import productDetailsDesc1 from "../assets/img/product/details/desc/product-details-desc-1.jpg";
import productDetailsDesc2 from "../assets/img/product/details/desc/product-details-desc-2.jpg";
import productDetailsDesc3 from "../assets/img/product/details/desc/product-details-desc-3.jpg";

import user2 from "../assets/img/users/user-2.jpg";
import user3 from "../assets/img/users/user-3.jpg";

import productRelated1 from "../assets/img/product/related/product-related-1.jpg";
import productRelated2 from "../assets/img/product/related/product-related-2.jpg";
import productRelated3 from "../assets/img/product/related/product-related-3.jpg";
import productRelated4 from "../assets/img/product/related/product-related-4.jpg";

import loginShape1 from "../assets/img/login/login-shape-1.png";
import loginShape2 from "../assets/img/login/login-shape-2.png";
import loginShape3 from "../assets/img/login/login-shape-3.png";
import loginShape4 from "../assets/img/login/login-shape-4.png";

import loginGoogle from "../assets/img/icon/login/google.svg";
import loginFacebook from "../assets/img/icon/login/facebook.svg";
import loginApple from "../assets/img/icon/login/apple.svg";

//About - Halee
import aboutBig from "../assets/img/about/about-big-1.jpg";
import history1 from "../assets/img/history/history-1.jpg";
import history2 from "../assets/img/history/history-2.jpg";
import user4 from "../assets/img/users/user-4.jpg";
import authorBg from "../assets/img/author/author-bg.jpg";

import logo01 from "../assets/img/brand/author/logo_01.png";
import logo02 from "../assets/img/brand/author/logo_02.png";
import logo03 from "../assets/img/brand/author/logo_03.png";
import logo04 from "../assets/img/brand/author/logo_04.png";

import productProdCut1 from "../assets/img/product/2/prodcut-1.jpg";
import productProdCut2 from "../assets/img/product/2/prodcut-2.jpg";
import productProdCut3 from "../assets/img/product/2/prodcut-3.jpg";
import productProdCut4 from "../assets/img/product/2/prodcut-4.jpg";
import productProdCut5 from "../assets/img/product/2/prodcut-5.jpg";
import productProdCut6 from "../assets/img/product/2/prodcut-6.jpg";

import filterShopSM1 from "../assets/img/product/shop/sm/shop-sm-1.jpg";
import filterShopSM2 from "../assets/img/product/shop/sm/shop-sm-2.jpg";
import filterShopSM3 from "../assets/img/product/shop/sm/shop-sm-3.jpg";
import filterShopSM4 from "../assets/img/product/shop/sm/shop-sm-4.jpg";

//Blog - Halee
import blogGrid1 from "../assets/img/blog/grid/blog-grid-1.jpg";
import blogGrid2 from "../assets/img/blog/grid/blog-grid-2.jpg";
import blogGrid3 from "../assets/img/blog/grid/blog-grid-3.jpg";
import blogGrid4 from "../assets/img/blog/grid/blog-grid-4.jpg";
import blogGrid5 from "../assets/img/blog/grid/blog-grid-5.jpg";
import blogGrid6 from "../assets/img/blog/grid/blog-grid-6.jpg";
import blogGrid7 from "../assets/img/blog/grid/blog-grid-7.jpg";
import blogGrid8 from "../assets/img/blog/grid/blog-grid-8.jpg";

import user11 from "../assets/img/users/user-11.jpg";
import signature from "../assets/img/blog/signature/signature.png";
import blogSlideBar1 from "../assets/img/blog/sidebar/blog-sidebar-1.jpg";
import blogSlideBar3 from "../assets/img/blog/sidebar/blog-sidebar-3.jpg";

import blogList2 from "../assets/img/blog/list/blog-list-2.jpg";
import blogList3 from "../assets/img/blog/list/blog-list-3.jpg";
import blogList4 from "../assets/img/blog/list/blog-list-4.jpg";
import blogList5 from "../assets/img/blog/list/blog-list-5.jpg";
import blogList6 from "../assets/img/blog/list/blog-list-6.jpg";

//Contact - Halee
import laptop from "../assets/img/login/laptop.png";
import man from "../assets/img/login/man.png";
import shape1 from "../assets/img/login/shape-1.png";
import shape2 from "../assets/img/login/shape-2.png";
import shape3 from "../assets/img/login/shape-3.png";
import shape4 from "../assets/img/login/shape-4.png";
import user10 from "../assets/img/users/user-10.jpg";

//BlogDetails - Halee
import blogDetailBig1 from "../assets/img/blog/details/blog-big-1.jpg";
import blogDetailsSm1 from "../assets/img/blog/details/blog-details-sm-1.jpg";
import blogSlideBar2 from "../assets/img/blog/sidebar/blog-sidebar-2.jpg";
import line from "../assets/img/blog/details/shape/line.png";
import qoute from "../assets/img/blog/details/shape/quote.png";

//Register - Halee
import google from "../assets/img/icon/login/google.svg";
import facebook from "../assets/img/icon/login/facebook.svg";
import apple from "../assets/img/icon/login/apple.svg";

//Contact - Halee
import contactIcon1 from "../assets/img/contact/contact-icon-1.png";
import contactIcon2 from "../assets/img/contact/contact-icon-2.png";
import contactIcon3 from "../assets/img/contact/contact-icon-3.png";

//footer
import logo05 from "../assets/img/logo/logokoro.png";

//menu
import productMenu01 from "../assets/img/menu/product/menu-product-img-1.jpg";
import productMenu02 from "../assets/img/menu/product/menu-product-img-2.jpg";

//cart
import productCart01 from "../assets/img/product/product-1.jpg";

//home
import homeBannerShape1 from "../assets/img/slider/2/shape/shape-1.png";
import homeBannerShape2 from "../assets/img/slider/2/shape/shape-2.png";
import homeBannerShape3 from "../assets/img/slider/2/shape/shape-3.png";

import homeBannerSlider1 from "../assets/img/slider/2/slider-1.png";
import homeBannerSlider2 from "../assets/img/slider/2/slider-2.png";
import homeBannerSlider3 from "../assets/img/slider/2/slider-3.png";

import homeBanner01 from "../assets/img/banner/2/banner-1.jpg";
import homeBanner02 from "../assets/img/banner/2/banner-2.jpg";
import homeBanner03 from "../assets/img/banner/2/banner-3.jpg";

import homeBannerThirty01 from "../assets/img/category/category-1.jpg";
import homeBannerThirty02 from "../assets/img/category/category-2.jpg";
import homeBannerThirty03 from "../assets/img/category/category-3.jpg";
import homeBannerThirty04 from "../assets/img/category/category-4.jpg";
import homeBannerThirty05 from "../assets/img/category/category-5.jpg";
import homeBannerThirty06 from "../assets/img/category/category-6.jpg";

import homeProduct01 from "../assets/img/product/2/prodcut-1.jpg";
import homeProduct02 from "../assets/img/product/2/prodcut-2.jpg";
import homeProduct03 from "../assets/img/product/2/prodcut-3.jpg";

import homeProductSlider01 from "../assets/img/product/slider/product-slider-1.jpg";
import homeProductSlider02 from "../assets/img/product/slider/product-slider-2.jpg";
import homeProductSlider03 from "../assets/img/product/slider/product-slider-3.jpg";
import homeProductSlider04 from "../assets/img/product/slider/product-slider-4.jpg";
import homeProductSlider05 from "../assets/img/product/slider/product-slider-5.jpg";

//assets/img/product/2/prodcut-9.jpg
import homeProductTrending1 from "../assets/img/product/trending/trending-1.jpg";
import homeProductTrending2 from "../assets/img/product/trending/trending-2.jpg";
import homeProductTrending3 from "../assets/img/product/trending/trending-3.jpg";

import homeBannerTrending1 from "../assets/img/product/trending/banner/trending-banner.jpg";

import product09 from "../assets/img/product/2/prodcut-9.jpg";
import product10 from "../assets/img/product/2/prodcut-10.jpg";
import product11 from "../assets/img/product/2/prodcut-11.jpg";
import product12 from "../assets/img/product/2/prodcut-12.jpg";


import blog01 from "../assets/img/blog/2/blog-1.jpg";
import blog02 from "../assets/img/blog/2/blog-2.jpg";
import blog03 from "../assets/img/blog/2/blog-3.jpg";

//assets/img/instagram/2/insta-2.jpg
import instagram01 from "../assets/img/instagram/2/insta-1.jpg";
import instagram02 from "../assets/img/instagram/2/insta-2.jpg";
import instagram03 from "../assets/img/instagram/2/insta-3.jpg";
import instagram04 from "../assets/img/instagram/2/insta-4.jpg";
import instagramIcon from "../assets/img/instagram/2/insta-icon.png";

import productShoppingCart01 from "../assets/img/product/cart/product-cart-1.jpg";
import productShoppingCart02 from "../assets/img/product/cart/product-cart-2.jpg";
import productShoppingCart03 from "../assets/img/product/cart/product-cart-3.jpg";
import productShoppingCart04 from "../assets/img/product/cart/product-cart-4.jpg";


import checkOutPaymentOption from "../assets/img/icon/payment-option.png";
import logoShopee from "../assets/img/logo/shopee.png";



const IMAGE = {
    productDetailNav1,
    productDetailNav2,
    productDetailNav3,
    productDetailNav4,
    productDetailNav5,

    productDetailMain1,
    productDetailMain2,
    productDetailMain3,
    productDetailMain4,
    productDetailMain5,

    productDetailSlider1,
    productDetailSlider2,
    productDetailSlider3,
    productDetailSlider4,
    productDetailSlider5,
    productDetailSlider6,

    iconPaymentOption,

    productDetailsDesc1,
    productDetailsDesc2,
    productDetailsDesc3,

    user2,
    user3,

    productRelated1,
    productRelated2,
    productRelated3,
    productRelated4,

    loginShape1,
    loginShape2,
    loginShape3,
    loginShape4,

    loginGoogle,
    loginFacebook,
    loginApple,

    //About - Halee
    aboutBig,

    history1,
    history2,

    user4,
    authorBg,

    logo01,
    logo02,
    logo03,
    logo04,

    productProdCut1,
    productProdCut2,
    productProdCut3,
    productProdCut4,
    productProdCut5,
    productProdCut6,

    filterShopSM1,
    filterShopSM2,
    filterShopSM3,
    filterShopSM4,

    //Blog - Halee
    blogGrid1,
    blogGrid2,
    blogGrid3,
    blogGrid4,
    blogGrid5,
    blogGrid6,
    blogGrid7,
    blogGrid8,

    user11,
    signature,
    blogSlideBar1,
    blogSlideBar3,

    blogList2,
    blogList3,
    blogList4,
    blogList5,
    blogList6,

    //Contact - Halee
    user10,
    laptop,
    man,
    shape1,
    shape2,
    shape3,
    shape4,

    //BlogDetails - Halee
    blogDetailBig1,
    blogDetailsSm1,
    blogSlideBar2,
    line,
    qoute,

    //Register - Halee
    facebook,
    google,
    apple,

    //Contact - Halee
    contactIcon1,
    contactIcon2,
    contactIcon3,


    logo05,

    productMenu01,
    productMenu02,

    productCart01,

    homeBannerShape1,
    homeBannerShape2,
    homeBannerShape3,

    homeBannerSlider1,
    homeBannerSlider2,
    homeBannerSlider3,

    homeBanner01,
    homeBanner02,
    homeBanner03,

    homeBannerThirty01,
    homeBannerThirty02,
    homeBannerThirty03,
    homeBannerThirty04,
    homeBannerThirty05,
    homeBannerThirty06,

    homeProduct01,
    homeProduct02,
    homeProduct03,

    homeProductSlider01,
    homeProductSlider02,
    homeProductSlider03,
    homeProductSlider04,
    homeProductSlider05,

    homeProductTrending1,
    homeProductTrending2,
    homeProductTrending3,

    homeBannerTrending1,

    product09,
    product10,
    product11,
    product12,

    blog01,
    blog02,
    blog03,

    instagram01,
    instagram02,
    instagram03,
    instagram04,
    instagramIcon,

    productShoppingCart01,
    productShoppingCart02,
    productShoppingCart03,
    productShoppingCart04,

    checkOutPaymentOption,
    logoShopee,
};

export default IMAGE;
