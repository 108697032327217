import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { CommonService } from "../../../services/CommonService";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import AddressServices from "../../../services/AddressServices";
import EventBus from "../../../evenBus/EvenBus";
import OrderServices from "../../../services/OrderServices";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import VoucherServices from "../../../services/VoucherServices";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";

// import CryptoJS from "crypto-js";

function ShoppingCart() {
    const [productsCart, setProductsCart] = useState([]);
    const [totalCart, setTotalCart] = useState(0);
    const [nameCustomer, setNameCustomer] = useState("");
    const [phoneCustomer, setPhoneCustomer] = useState("");
    const [emailCustomer, setEmailCustomer] = useState("");
    const [heightCustomer, setHeightCustomer] = useState(0);
    const [weightCustomer, setWeightCustomer] = useState(0);
    const [addressCustomer, setAddressCustomer] = useState("");
    const [noteCustomer, setNoteCustomer] = useState("");
    const [provinces, setProvinces] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [districts, setDistricts] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState(null);

    const [wards, setWards] = useState([]);
    const [selectedWard, setSelectedWard] = useState(null);
    const [shippingFee, setShippingFee] = useState(0);
    const [discountShippingFee, setDiscountShippingFee] = useState(0);

    const [visibleVoucher, setVisibleVoucher] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [visibleSelecteVoucher, setVisibleSelecteVoucher] = useState(false);

    const [listVoucher, setListVoucher] = useState([]);
    const [listFreeship, setListFreeship] = useState([]);
    const getAllVoucher = async () => {
        try {
            const data = await VoucherServices.getAllVoucher();
            const listVoucher = data.data.filter((item) => item.type === 1);
            const listFreeship = data.data.filter((item) => item.type === 3);

            setListVoucher(listVoucher);
            setListFreeship(listFreeship);
        } catch (error) {
            showError("Hệ thống voucher đang bảo trì", 1000);
        }
    };
    const [selectedFreeship, setSelectedFreeship] = useState(null);
    const [selectedVoucher, setSelectedVoucher] = useState("");
    const [infoOldCustomer, setFnfoOldCustomer] = useState(null);
    const toast = useRef(null);
    const navigate = useNavigate();
    // const showSuccess = (message, time) => {
    //     toast.current.show({ severity: "success", summary: "Thành công", detail: message, life: time });
    // };
    const showError = (message, time) => {
        toast.current.show({ severity: "error", summary: "Thất bại", detail: message, life: time });
    };
    const getInfoOldCustomer = () => {
        const infoOldCustomers = JSON.parse(localStorage.getItem("infoKoro"));
        setFnfoOldCustomer(infoOldCustomers);
    };
    useEffect(() => {
        getInfoOldCustomer();
        getProductsCart();
        getProvinces();
        getCart();
    }, []);

    useEffect(() => {
        if (selectedProvince) {
            getDistricts(selectedProvince);
        }
    }, [selectedProvince]);

    useEffect(() => {
        if (selectedDistrict) {
            getWards(selectedDistrict);
        }
    }, [selectedDistrict]);

    useEffect(() => {
        if (selectedWard !== null) {
            setShippingFee(30000);
        }
    }, [selectedWard]);

    useEffect(() => {
        getTotalCart();
    }, [productsCart]);
    const getCart = () => {
        const carts = JSON.parse(localStorage.getItem("shoppingCartKoro"));
    };
    const getProductsCart = () => {
        const cartData = CommonService.getCart();
        setProductsCart(cartData);
    };
    //get address
    const getProvinces = async () => {
        try {
            const data = await AddressServices.getAllProvince();
            setProvinces(data);
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu từ API", error);
        }
    };

    const getDistricts = async () => {
        try {
            const data = await AddressServices.getAllDistrict(selectedProvince);
            setDistricts(data);
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu từ API", error);
        }
    };

    const getWards = async () => {
        try {
            const data = await AddressServices.getAllWard(selectedDistrict);
            setWards(data);
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu từ API", error);
        }
    };

    const changeQuantity = (proDeId, quantity, validQuantity) => {
        if (quantity < validQuantity) {
            console.log(validQuantity);
            CommonService.handleChangeQuantityProDe(proDeId, quantity);
            getTotalCart();
            EventBus.emit("cartUpdated");
            return;
        }
        showError("Số lượng không được quá: "+validQuantity);
    };

    const getTotalCart = () => {
        const totalCart = CommonService.handleTotalPriceCart();
        setTotalCart(totalCart);
        return totalCart;
    };

    const removeProductInCart = (idProduct) => {
        CommonService.handleRemoveProductId(idProduct);
        getProductsCart();
        EventBus.emit("cartUpdated");
    };
    const removeProductDetailInCart = (productId, idProDetail) => {
        CommonService.handleRemoveProductDetailId(productId, idProDetail);
        getProductsCart();
        EventBus.emit("cartUpdated");
    };

    const checkAllInputs = () => {
        const validations = [
            { input: nameCustomer, message: "Tên không được bỏ trống" },
            { input: phoneCustomer, message: "SDT không được bỏ trống", validator: checkValidInputPhone },
            { input: addressCustomer, message: "Địa chỉ chi tiết không được bỏ trống" },
            { input: selectedProvince, message: "Hãy chọn tỉnh-thành phố" },
            { input: selectedDistrict, message: "Hãy chọn quận-huyện" },
            { input: selectedWard, message: "Hãy chọn phường-xã" },
        ];

        let isValid = true;

        validations.forEach((validation) => {
            if (!isValid) return; // Nếu đã có lỗi thì không kiểm tra tiếp
            const { input, message, validator } = validation;
            const validationFunction = validator || checkValidInput; // Sử dụng checkValidInput nếu không có validator

            isValid = validationFunction(input, message);
        });

        return isValid;
    };

    const clickOrder = () => {
        const carts = JSON.parse(localStorage.getItem("shoppingCartKoro"));
        if (carts.length < 1) {
            alert("Giỏ hàng rỗng");
            return;
        }
        if (checkAllInputs()) {
            setVisibleVoucher(true);
        }
    };
    const checkValidInput = (input, desc) => {
        if (!input) {
            showError(desc, 2000);
            return false;
        }
        return true;
    };
    const checkValidInputPhone = (input, desc) => {
        if (!input || input.length !== 10) {
            showError(desc, 3000);
            return false;
        }
        return true;
    };
    const clickSelectVoucher = () => {
        setVisibleSelecteVoucher(true);
        getAllVoucher();
    };
    async function clickApplyVoucher() {
        setVisibleSelecteVoucher(false);

        const objectReq = {
            voucher: [selectedFreeship, selectedVoucher],
            totalMoney: totalCart,
            phone: phoneCustomer,
        };

        try {
            const res = await VoucherServices.applyVoucher(objectReq);
            if (res.data !== null) {
                res.data.forEach((vou) => {
                    if (vou.isValid && vou.type === 1) {
                        setDiscount(vou.value);
                    } else if (vou.isValid && vou.type === 3) {
                        if (shippingFee >= vou.value) {
                            setDiscountShippingFee(vou.value);
                        } else {
                            setDiscountShippingFee(0);
                        }
                    } else {
                        showError(vou.message, 3000);
                    }
                });
            }
        } catch (error) {
            showError("Hệ thống voucher đang bảo trì", 1000);
        }
    }

    async function handlePlaceOrder() {
        const carts = JSON.parse(localStorage.getItem("shoppingCartKoro"));
        if (carts.length < 1) {
            alert("Giỏ hàng rỗng");
        } else {
            const proDetails = [];
            carts.forEach((product) => {
                product.proDetail.forEach((proDetail) => {
                    proDetails.push({
                        proDeId: proDetail.id,
                        quantity: parseInt(proDetail.quantity),
                        price: proDetail.proDe.price - proDetail.proDe.discount,
                    });
                });
            });

            const order = {
                deliveryId: 6,
                totalMoney: CommonService.handleTotalPriceCart() + shippingFee - discountShippingFee - discount,
                productMoney: totalCart,
                shippingPrice: shippingFee - discountShippingFee,
                discount: discount,
                paid: 0,
                paymentAmount: CommonService.handleTotalPriceCart() + shippingFee - discountShippingFee - discount,
                internalNotes: noteCustomer,
                shippingNotes: "Cảm ơn shipper đã giao hàng tận tình. Cảm ơn khách hàng đã tin tưởng ủng hộ shop! <3",
                name: nameCustomer,
                phone: phoneCustomer,
                address: addressCustomer,
                email: emailCustomer,
                height: parseInt(heightCustomer),
                weight: parseInt(weightCustomer),
                birthday: null,
                userId: 1,
                creatorId: 1,
                businessId: 15,
                statusId: 2,
                typeId: 1,
                province: selectedProvince,
                district: selectedDistrict,
                ward: selectedWard,
                orderDetailDTO: proDetails,
                orderTag: [],
                sex: true,
            };
            if (order) {
                try {
                    const result = await OrderServices.sendRequest("/api/v1/orders", order, null);
                    if (result.status === "success") {
                        CommonService.handleSaveInfo(result.data, carts);
                        navigate(`/tracking-order`);
                    }
                    // xoa cart
                    if (carts && Array.isArray(carts)) {
                        carts.length = 0;
                        localStorage.setItem("shoppingCartKoro", JSON.stringify(carts));
                    } else {
                        localStorage.removeItem("shoppingCartKoro");
                    }
                } catch (error) {
                    console.error("Đã xảy ra lỗi:", error);
                }
            }
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <main>
                {/* breadcrumb area start */}
                <section className="breadcrumb__area include-bg pt-95 pb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="breadcrumb__content p-relative z-index-1">
                                    <h3 className="breadcrumb__title">Giỏ hàng & Thanh toán</h3>
                                    <div className="breadcrumb__list">
                                        <span>
                                            <Link to="/">Trang chủ</Link>
                                        </span>
                                        <span>Giỏ hàng & Thanh toán</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* breadcrumb area end */}
                {/* cart area start */}
                <section className="tp-cart-area pb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-lg-8">
                                <div className="tp-cart-list mb-25 mr-30">
                                    <table className="table">
                                        <thead>
                                            {/* <tr> */}
                                            {/* <th colSpan={2} className="tp-cart-header-product"></th> */}
                                            {/* <th className="tp-cart-header-product">Sản phẩm trong giỏ hàng</th> */}
                                            {/* </tr> */}
                                        </thead>
                                        <tbody>
                                            {productsCart ? (
                                                productsCart.map((productCart, index) => (
                                                    <tr key={index}>
                                                        {/* action */}
                                                        <td className="tp-cart-action">
                                                            <i
                                                                onClick={() =>
                                                                    removeProductInCart(productCart.productId)
                                                                }
                                                                className="text-red-500 ms-3 fa-regular fa-xmark font-bold text-xs cursor-pointer"
                                                            />
                                                        </td>
                                                        {/* img */}
                                                        <td className="tp-cart-img">
                                                            <Link to="/product">
                                                                <div>{productCart.productName}.</div>
                                                                <div>
                                                                    <img
                                                                        src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${productCart.productImage}`}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </Link>
                                                        </td>
                                                        {/* title */}
                                                        <td className="tp-cart-title">
                                                            <div className="ms-5">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th>Phân loại</th>
                                                                            <th>Giá</th>
                                                                            <th>Số lượng</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {productCart.proDetail.map((item, index) => (
                                                                            <tr key={index}>
                                                                                <td className="">
                                                                                    <i
                                                                                        onClick={() =>
                                                                                            removeProductDetailInCart(
                                                                                                productCart.productId,
                                                                                                item.id
                                                                                            )
                                                                                        }
                                                                                        className="text-red-500 ms-3 fa-regular fa-xmark font-bold text-xs cursor-pointer me-2"
                                                                                    />
                                                                                    <img
                                                                                        src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${item.proDe.image}`}
                                                                                        alt=""
                                                                                        style={{ maxWidth: 50 }}
                                                                                    />
                                                                                </td>
                                                                                <td className="tp-cart-title">
                                                                                    {item.proDe.codeName}
                                                                                </td>
                                                                                <td className="tp-cart-price">
                                                                                    {CommonService.handleFomatCurrency(
                                                                                        item.proDe.price -
                                                                                            item.proDe.discount
                                                                                    )}
                                                                                </td>
                                                                                <td className="tp-cart-quantity">
                                                                                    <div className="tp-product-quantity mt-10 mb-10">
                                                                                        <span className="tp-cart-minus">
                                                                                            <svg
                                                                                                width={10}
                                                                                                height={2}
                                                                                                viewBox="0 0 10 2"
                                                                                                fill="none"
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                            >
                                                                                                <path
                                                                                                    d="M1 1H9"
                                                                                                    stroke="currentColor"
                                                                                                    strokeWidth="1.5"
                                                                                                    strokeLinecap="round"
                                                                                                    strokeLinejoin="round"
                                                                                                />
                                                                                            </svg>
                                                                                        </span>
                                                                                        <input
                                                                                            className="tp-cart-input"
                                                                                            type="text"
                                                                                            defaultValue={item.quantity}
                                                                                            onChange={(e) =>
                                                                                                changeQuantity(
                                                                                                    item.id,
                                                                                                    e.target.value,
                                                                                                    item.proDe.validQuantity
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                        <span className="tp-cart-plus">
                                                                                            <svg
                                                                                                width={10}
                                                                                                height={10}
                                                                                                viewBox="0 0 10 10"
                                                                                                fill="none"
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                            >
                                                                                                <path
                                                                                                    d="M5 1V9"
                                                                                                    stroke="currentColor"
                                                                                                    strokeWidth="1.5"
                                                                                                    strokeLinecap="round"
                                                                                                    strokeLinejoin="round"
                                                                                                />
                                                                                                <path
                                                                                                    d="M1 5H9"
                                                                                                    stroke="currentColor"
                                                                                                    strokeWidth="1.5"
                                                                                                    strokeLinecap="round"
                                                                                                    strokeLinejoin="round"
                                                                                                />
                                                                                            </svg>
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <></>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-4 col-md-6">
                                <div className="tp-cart-checkout-wrapper">
                                    <div className="tp-cart-checkout-top d-flex align-items-center justify-content-between">
                                        <span className="tp-cart-checkout-top-title">Tiền sản phẩm</span>
                                        <span className="tp-cart-checkout-top-price">
                                            {CommonService.handleFomatCurrency(totalCart)}
                                        </span>
                                    </div>
                                </div>
                                <div className="tp-cart-checkout-wrapper pt-5">
                                    <h4>Thông tin nhận hàng</h4>
                                    <div className="p-fluid formgrid grid">
                                        <div className="field col-12 md:col-6">
                                            <label htmlFor="username">
                                                Họ tên <span className="text-red-500">(*)</span>
                                            </label>
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-user" />
                                                <InputText
                                                    placeholder="Nhập tên..."
                                                    value={nameCustomer}
                                                    onChange={(e) => setNameCustomer(e.target.value)}
                                                />
                                            </span>
                                        </div>
                                        <div className="field col-12 md:col-6">
                                            <label htmlFor="phoneCustomer">
                                                Số điện thoại <span className="text-red-500">(*)</span>
                                            </label>
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-phone" />
                                                <InputText
                                                    placeholder="Nhập số..."
                                                    value={phoneCustomer}
                                                    onChange={(e) => setPhoneCustomer(e.target.value)}
                                                />
                                            </span>
                                        </div>
                                        <div className="field col-12 md:col-6">
                                            <label htmlFor="addressDetail">
                                                Địa chỉ chi tiết <span className="text-red-500">(*)</span>
                                            </label>
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-map" />
                                                <InputTextarea
                                                    autoResize
                                                    placeholder="Số nhà, ngõ, thôn xóm, ấp..."
                                                    value={addressCustomer}
                                                    onChange={(e) => setAddressCustomer(e.target.value)}
                                                    rows={5}
                                                    cols={50}
                                                />
                                            </span>
                                        </div>
                                        <div className="field col-6">
                                            <label htmlFor="state">
                                                Tỉnh/Thành phố <span className="text-red-500">(*)</span>
                                            </label>
                                            <Dropdown
                                                id="state"
                                                value={selectedProvince}
                                                onChange={(e) => setSelectedProvince(e.value)}
                                                options={provinces}
                                                placeholder="Chọn"
                                                filter
                                            />
                                        </div>
                                        <div className="field col-6">
                                            <label htmlFor="district">
                                                Quận/Huyện <span className="text-red-500">(*)</span>
                                            </label>
                                            <Dropdown
                                                value={selectedDistrict}
                                                onChange={(e) => setSelectedDistrict(e.value)}
                                                options={districts}
                                                placeholder="Chọn"
                                                filter
                                            />
                                        </div>

                                        <div className="field col-6 md:col-6">
                                            <label htmlFor="ward">
                                                Phường/Xã <span className="text-red-500">(*)</span>
                                            </label>
                                            <Dropdown
                                                value={selectedWard}
                                                onChange={(e) => setSelectedWard(e.value)}
                                                options={wards}
                                                placeholder="Chọn"
                                                filter
                                            />
                                        </div>
                                        <div className="field col-6 md:col-6">
                                            <label htmlFor="emailCustomer">Email (Không bắt buộc)</label>
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-inbox" />
                                                <InputText
                                                    placeholder="Nhập email@abc.com..."
                                                    value={emailCustomer}
                                                    onChange={(e) => setEmailCustomer(e.target.value)}
                                                />
                                            </span>
                                        </div>
                                        <div className="field col-6 md:col-6">
                                            <label htmlFor="height">Chiều cao (Không bắt buộc)</label>
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-sliders-v" />
                                                <InputText
                                                    placeholder="centimet"
                                                    value={heightCustomer}
                                                    onChange={(e) => setHeightCustomer(e.target.value)}
                                                />
                                            </span>
                                        </div>
                                        <div className="field col-6 md:col-6">
                                            <label htmlFor="weight">Cân nặng (Không bắt buộc)</label>
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-sliders-h" />
                                                <InputText
                                                    placeholder="kg"
                                                    value={weightCustomer}
                                                    onChange={(e) => setWeightCustomer(e.target.value)}
                                                />
                                            </span>
                                        </div>

                                        <div className="field col-12 md:col-6">
                                            <label htmlFor="Note">Ghi chú (Không bắt buộc)</label>
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-file-edit" />
                                                <InputTextarea
                                                    autoResize
                                                    placeholder="Ghi chú cho shop và đơn vị vận chuyển"
                                                    value={noteCustomer}
                                                    onChange={(e) => setNoteCustomer(e.target.value)}
                                                    rows={5}
                                                    cols={50}
                                                />
                                            </span>
                                        </div>
                                        <div className="tp-cart-checkout-proceed">
                                            <button onClick={() => clickOrder()} className="tp-cart-checkout-btn w-100">
                                                Đặt hàng
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* cart area end */}
            </main>
            <Dialog
                header="Chọn mã voucher?"
                visible={visibleVoucher}
                onHide={() => setVisibleVoucher(false)}
                draggable={false}
                resizable={false}
                style={{ width: "40vw" }}
                //breakpoints={{ "1200px": "40vw", "992px": "90vw", "641px": "100vw" }}
                className="custom-dialog"
            >
                <div className="tp-cart-checkout-wrapper">
                    <div className="tp-cart-checkout-top d-flex align-items-center justify-content-between">
                        <span className="tp-cart-checkout-top-title">Tiền sản phẩm</span>
                        <span className="tp-cart-checkout-top-price">
                            {CommonService.handleFomatCurrency(totalCart)}
                        </span>
                    </div>
                    <div className="tp-cart-checkout-shipping">
                        <div className="tp-order-info-list">
                            <ul>
                                <li className="tp-order-info-list-desc">
                                    <p>Phí ship:</p>
                                    <span>
                                        {CommonService.handleFomatCurrency(shippingFee)} <br /> -
                                        {CommonService.handleFomatCurrency(discountShippingFee)}{" "}
                                    </span>
                                </li>
                                <li className="tp-order-info-list-desc">
                                    <p>Giảm giá:</p>
                                    <span>-{CommonService.handleFomatCurrency(discount)}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tp-cart-checkout-total d-flex align-items-center justify-content-between">
                        <span>Tổng</span>
                        <span className="align-items-center justify-content-between">
                            {CommonService.handleFomatCurrency(
                                CommonService.handleTotalPriceCart() + shippingFee - discountShippingFee - discount
                            )}{" "}
                            <br />
                            {discountShippingFee + discount > 0 && (
                                <i className="text-red-500 text-xs">
                                    (Tiết kiệm -{CommonService.handleFomatCurrency(discountShippingFee + discount)})
                                </i>
                            )}
                        </span>
                    </div>
                </div>
                <div className="overflow-hidden">
                    <div className="flex py-3">
                        <div className="flex-1 flex align-items-center justify-content-center">
                            <Button
                                severity="success"
                                label="Chọn ưu đãi"
                                onClick={() => clickSelectVoucher()}
                                className="tp-cart-checkout-btn w-90"
                            />
                        </div>
                        <div className="flex-1 flex align-items-center justify-content-center">
                            <Button
                                label="Đặt hàng"
                                onClick={() => handlePlaceOrder()}
                                className="tp-cart-checkout-btn w-90"
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog
                header="Chọn mã giảm giá"
                visible={visibleSelecteVoucher}
                onHide={() => setVisibleSelecteVoucher(false)}
                draggable={false}
                resizable={false}
                style={{ width: "40vw" }}
                className="custom-dialog"
            >
                <div className="card flex justify-content-center px-6 py-5 ">
                    <div>
                        <b>Chọn mã freeship:</b>
                    </div>
                    <div className="flex flex-column gap-3 overflow-y-auto">
                        {listFreeship.map((freeship) => {
                            return (
                                <div key={freeship.id} className="flex align-items-center">
                                    <img
                                        src={`${process.env.REACT_APP_MEDIA_URL}/api/v1/file/images/w6nyXPNi-freeship.png`}
                                        style={{ width: "50px", display: "block" }}
                                    />
                                    <label htmlFor={freeship.id} className="ml-2 mr-2 ">
                                        {freeship.codeName}
                                        <span className="text-xs">
                                            <i>({freeship.description})</i>
                                        </span>
                                    </label>
                                    <RadioButton
                                        inputId={freeship.id}
                                        name="freeship"
                                        value={freeship.id}
                                        onClick={(e) => setSelectedFreeship(e.value)}
                                        checked={selectedFreeship === freeship.id}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <br />
                    <div>
                        <b>Chọn mã khuyến mại:</b>
                    </div>
                    <div className="flex flex-column gap-3 overflow-y-auto">
                        {listVoucher.map((voucher) => {
                            return (
                                <div key={voucher.id} className="flex align-items-center">
                                    <img
                                        src={`${process.env.REACT_APP_MEDIA_URL}/api/v1/file/images/uaREuezb-discount.png`}
                                        style={{ width: "50px", display: "block" }}
                                    />
                                    <label htmlFor={voucher.id} className="ml-2 mr-2">
                                        {voucher.codeName}
                                        <span className="text-xs">
                                            <i>({voucher.description})</i>
                                        </span>
                                    </label>
                                    <RadioButton
                                        inputId={voucher.id}
                                        name="voucher"
                                        value={voucher.id}
                                        onChange={(e) => setSelectedVoucher(e.value)}
                                        checked={selectedVoucher === voucher.id}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="overflow-hidden">
                    <div className="flex">
                        <div className="flex-1 flex align-items-center justify-content-center">
                            <Button severity="success" label="Áp dụng" onClick={() => clickApplyVoucher()} />
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default ShoppingCart;
