import IMAGE from "../../../const/image";

function Blog() {
    return (
        <>
            <main>
                {/* section title area start */}
                <section className="tp-section-title-area pt-95 pb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="tp-section-title-wrapper-7">
                                    <span className="tp-section-title-pre-7">Blog Standard</span>
                                    <h3 className="tp-section-title-7">
                                        Insight and advice <br /> From our Expert team.
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* section title area end */}
                {/* blog grid area start */}
                <section className="tp-blog-grid-area pb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-9 col-lg-8">
                                <div className="tp-blog-grid-wrapper">
                                    <div className="tp-blog-grid-top d-flex justify-content-between mb-40">
                                        <div className="tp-blog-grid-result">
                                            <p>Showing 1–14 of 26 results</p>
                                        </div>
                                        <div className="tp-blog-grid-tab tp-tab">
                                            <nav>
                                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                    <button
                                                        className="nav-link"
                                                        id="nav-grid-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-grid"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-grid"
                                                        aria-selected="true"
                                                    >
                                                        <svg
                                                            width={18}
                                                            height={18}
                                                            viewBox="0 0 18 18"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M16.3328 6.01317V2.9865C16.3328 2.0465 15.9061 1.6665 14.8461 1.6665H12.1528C11.0928 1.6665 10.6661 2.0465 10.6661 2.9865V6.0065C10.6661 6.95317 11.0928 7.3265 12.1528 7.3265H14.8461C15.9061 7.33317 16.3328 6.95317 16.3328 6.01317Z"
                                                                stroke="currentColor"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M16.3328 15.18V12.4867C16.3328 11.4267 15.9061 11 14.8461 11H12.1528C11.0928 11 10.6661 11.4267 10.6661 12.4867V15.18C10.6661 16.24 11.0928 16.6667 12.1528 16.6667H14.8461C15.9061 16.6667 16.3328 16.24 16.3328 15.18Z"
                                                                stroke="currentColor"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M7.33281 6.01317V2.9865C7.33281 2.0465 6.90614 1.6665 5.84614 1.6665H3.1528C2.0928 1.6665 1.66614 2.0465 1.66614 2.9865V6.0065C1.66614 6.95317 2.0928 7.3265 3.1528 7.3265H5.84614C6.90614 7.33317 7.33281 6.95317 7.33281 6.01317Z"
                                                                stroke="currentColor"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M7.33281 15.18V12.4867C7.33281 11.4267 6.90614 11 5.84614 11H3.1528C2.0928 11 1.66614 11.4267 1.66614 12.4867V15.18C1.66614 16.24 2.0928 16.6667 3.1528 16.6667H5.84614C6.90614 16.6667 7.33281 16.24 7.33281 15.18Z"
                                                                stroke="currentColor"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                    <button
                                                        className="nav-link active"
                                                        id="nav-list-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#nav-list"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-list"
                                                        aria-selected="false"
                                                    >
                                                        <svg
                                                            width={16}
                                                            height={15}
                                                            viewBox="0 0 16 15"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M15 7.11133H1"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M15 1H1"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M15 13.2222H1"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </nav>
                                        </div>
                                    </div>{" "}
                                    {/* top end */}
                                    <div className="tab-content" id="nav-tabContent">
                                        <div
                                            className="tab-pane fade"
                                            id="nav-grid"
                                            role="tabpanel"
                                            aria-labelledby="nav-grid-tab"
                                            tabIndex={0}
                                        >
                                            {/* blog grid item wrapper */}
                                            <div className="tp-blog-grid-item-wrapper">
                                                <div className="row tp-gx-30">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="tp-blog-grid-item p-relative mb-30">
                                                            <div className="tp-blog-grid-thumb w-img fix mb-30">
                                                                <a href="#">
                                                                    <img src={IMAGE.blogGrid1} alt="" />
                                                                </a>
                                                            </div>
                                                            <div className="tp-blog-grid-content">
                                                                <div className="tp-blog-grid-meta">
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={100}
                                                                                height={200}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M10.5972 10.7259L8.42715 9.43093C8.04915 9.20693 7.74115 8.66793 7.74115 8.22693V5.35693"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        24 April, 2023
                                                                    </span>
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M12.5289 11.881L12.8019 14.093C12.8719 14.674 12.2489 15.08 11.7519 14.779L8.81888 13.036C8.49688 13.036 8.18189 13.015 7.87389 12.973C8.39189 12.364 8.69988 11.594 8.69988 10.761C8.69988 8.77299 6.97788 7.16302 4.84988 7.16302C4.03788 7.16302 3.28888 7.394 2.66588 7.8C2.64488 7.625 2.63788 7.44999 2.63788 7.26799C2.63788 4.08299 5.40288 1.5 8.81888 1.5C12.2349 1.5 14.9999 4.08299 14.9999 7.26799C14.9999 9.15799 14.0269 10.831 12.5289 11.881Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        Comments (0)
                                                                    </span>
                                                                </div>
                                                                <h3 className="tp-blog-grid-title">
                                                                    <a href="#">
                                                                        Hiring the Right Sales Team at the Right Time
                                                                    </a>
                                                                </h3>
                                                                <p>
                                                                    Cursus mattis sociis natoque penatibus et magnis
                                                                    montes,nascetur ridiculus.
                                                                </p>
                                                                <div className="tp-blog-grid-btn">
                                                                    <a href="#" className="tp-link-btn-3">
                                                                        Read More
                                                                        <span>
                                                                            <svg
                                                                                width={17}
                                                                                height={15}
                                                                                viewBox="0 0 17 15"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M16 7.5L1 7.5"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="tp-blog-grid-item p-relative mb-30">
                                                            <div className="tp-blog-grid-thumb w-img fix mb-30">
                                                                <a href="#">
                                                                    <img src={IMAGE.blogGrid2} alt="" />
                                                                </a>
                                                            </div>
                                                            <div className="tp-blog-grid-content">
                                                                <div className="tp-blog-grid-meta">
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M10.5972 10.7259L8.42715 9.43093C8.04915 9.20693 7.74115 8.66793 7.74115 8.22693V5.35693"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        12 jun, 2023
                                                                    </span>
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M12.5289 11.881L12.8019 14.093C12.8719 14.674 12.2489 15.08 11.7519 14.779L8.81888 13.036C8.49688 13.036 8.18189 13.015 7.87389 12.973C8.39189 12.364 8.69988 11.594 8.69988 10.761C8.69988 8.77299 6.97788 7.16302 4.84988 7.16302C4.03788 7.16302 3.28888 7.394 2.66588 7.8C2.64488 7.625 2.63788 7.44999 2.63788 7.26799C2.63788 4.08299 5.40288 1.5 8.81888 1.5C12.2349 1.5 14.9999 4.08299 14.9999 7.26799C14.9999 9.15799 14.0269 10.831 12.5289 11.881Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        Comments (2)
                                                                    </span>
                                                                </div>
                                                                <h3 className="tp-blog-grid-title">
                                                                    <a href="#">
                                                                        Fully Embrace the Return of 90s fashion
                                                                    </a>
                                                                </h3>
                                                                <p>
                                                                    Cursus mattis sociis natoque penatibus et magnis
                                                                    montes,nascetur ridiculus.
                                                                </p>
                                                                <div className="tp-blog-grid-btn">
                                                                    <a href="#" className="tp-link-btn-3">
                                                                        Read More
                                                                        <span>
                                                                            <svg
                                                                                width={17}
                                                                                height={15}
                                                                                viewBox="0 0 17 15"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M16 7.5L1 7.5"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="tp-blog-grid-item p-relative mb-30">
                                                            <div className="tp-blog-grid-thumb w-img fix mb-30">
                                                                <a href="#">
                                                                    <img src={IMAGE.blogGrid3} alt="" />
                                                                </a>
                                                            </div>
                                                            <div className="tp-blog-grid-content">
                                                                <div className="tp-blog-grid-meta">
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M10.5972 10.7259L8.42715 9.43093C8.04915 9.20693 7.74115 8.66793 7.74115 8.22693V5.35693"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        12 jun, 2023
                                                                    </span>
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M12.5289 11.881L12.8019 14.093C12.8719 14.674 12.2489 15.08 11.7519 14.779L8.81888 13.036C8.49688 13.036 8.18189 13.015 7.87389 12.973C8.39189 12.364 8.69988 11.594 8.69988 10.761C8.69988 8.77299 6.97788 7.16302 4.84988 7.16302C4.03788 7.16302 3.28888 7.394 2.66588 7.8C2.64488 7.625 2.63788 7.44999 2.63788 7.26799C2.63788 4.08299 5.40288 1.5 8.81888 1.5C12.2349 1.5 14.9999 4.08299 14.9999 7.26799C14.9999 9.15799 14.0269 10.831 12.5289 11.881Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        Comments (2)
                                                                    </span>
                                                                </div>
                                                                <h3 className="tp-blog-grid-title">
                                                                    <a href="#">Exploring the English Countryside</a>
                                                                </h3>
                                                                <p>
                                                                    Cursus mattis sociis natoque penatibus et magnis
                                                                    montes,nascetur ridiculus.
                                                                </p>
                                                                <div className="tp-blog-grid-btn">
                                                                    <a href="#" className="tp-link-btn-3">
                                                                        Read More
                                                                        <span>
                                                                            <svg
                                                                                width={17}
                                                                                height={15}
                                                                                viewBox="0 0 17 15"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M16 7.5L1 7.5"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="tp-blog-grid-item p-relative mb-30">
                                                            <div className="tp-blog-grid-thumb w-img fix mb-30">
                                                                <a href="#">
                                                                    <img src={IMAGE.blogGrid4} alt="" />
                                                                </a>
                                                            </div>
                                                            <div className="tp-blog-grid-content">
                                                                <div className="tp-blog-grid-meta">
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M10.5972 10.7259L8.42715 9.43093C8.04915 9.20693 7.74115 8.66793 7.74115 8.22693V5.35693"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        12 jun, 2023
                                                                    </span>
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M12.5289 11.881L12.8019 14.093C12.8719 14.674 12.2489 15.08 11.7519 14.779L8.81888 13.036C8.49688 13.036 8.18189 13.015 7.87389 12.973C8.39189 12.364 8.69988 11.594 8.69988 10.761C8.69988 8.77299 6.97788 7.16302 4.84988 7.16302C4.03788 7.16302 3.28888 7.394 2.66588 7.8C2.64488 7.625 2.63788 7.44999 2.63788 7.26799C2.63788 4.08299 5.40288 1.5 8.81888 1.5C12.2349 1.5 14.9999 4.08299 14.9999 7.26799C14.9999 9.15799 14.0269 10.831 12.5289 11.881Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        Comments (2)
                                                                    </span>
                                                                </div>
                                                                <h3 className="tp-blog-grid-title">
                                                                    <a href="#">
                                                                        Here’s the First Valentino’s New Makeup
                                                                        Collection
                                                                    </a>
                                                                </h3>
                                                                <p>
                                                                    Cursus mattis sociis natoque penatibus et magnis
                                                                    montes,nascetur ridiculus.
                                                                </p>
                                                                <div className="tp-blog-grid-btn">
                                                                    <a href="#" className="tp-link-btn-3">
                                                                        Read More
                                                                        <span>
                                                                            <svg
                                                                                width={17}
                                                                                height={15}
                                                                                viewBox="0 0 17 15"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M16 7.5L1 7.5"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="tp-blog-grid-item p-relative mb-30">
                                                            <div className="tp-blog-grid-thumb w-img fix mb-30">
                                                                <a href="#">
                                                                    <img src={IMAGE.blogGrid5} alt="" />
                                                                </a>
                                                            </div>
                                                            <div className="tp-blog-grid-content">
                                                                <div className="tp-blog-grid-meta">
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M10.5972 10.7259L8.42715 9.43093C8.04915 9.20693 7.74115 8.66793 7.74115 8.22693V5.35693"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        12 jun, 2023
                                                                    </span>
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M12.5289 11.881L12.8019 14.093C12.8719 14.674 12.2489 15.08 11.7519 14.779L8.81888 13.036C8.49688 13.036 8.18189 13.015 7.87389 12.973C8.39189 12.364 8.69988 11.594 8.69988 10.761C8.69988 8.77299 6.97788 7.16302 4.84988 7.16302C4.03788 7.16302 3.28888 7.394 2.66588 7.8C2.64488 7.625 2.63788 7.44999 2.63788 7.26799C2.63788 4.08299 5.40288 1.5 8.81888 1.5C12.2349 1.5 14.9999 4.08299 14.9999 7.26799C14.9999 9.15799 14.0269 10.831 12.5289 11.881Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        Comments (2)
                                                                    </span>
                                                                </div>
                                                                <h3 className="tp-blog-grid-title">
                                                                    <a href="#">
                                                                        Follow Your own Design process, whatever gets
                                                                    </a>
                                                                </h3>
                                                                <p>
                                                                    Cursus mattis sociis natoque penatibus et magnis
                                                                    montes,nascetur ridiculus.
                                                                </p>
                                                                <div className="tp-blog-grid-btn">
                                                                    <a href="#" className="tp-link-btn-3">
                                                                        Read More
                                                                        <span>
                                                                            <svg
                                                                                width={17}
                                                                                height={15}
                                                                                viewBox="0 0 17 15"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M16 7.5L1 7.5"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="tp-blog-grid-item p-relative mb-30">
                                                            <div className="tp-blog-grid-thumb w-img fix mb-30">
                                                                <a href="#">
                                                                    <img src={IMAGE.blogGrid6} alt="" />
                                                                </a>
                                                            </div>
                                                            <div className="tp-blog-grid-content">
                                                                <div className="tp-blog-grid-meta">
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M10.5972 10.7259L8.42715 9.43093C8.04915 9.20693 7.74115 8.66793 7.74115 8.22693V5.35693"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        12 jun, 2023
                                                                    </span>
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M12.5289 11.881L12.8019 14.093C12.8719 14.674 12.2489 15.08 11.7519 14.779L8.81888 13.036C8.49688 13.036 8.18189 13.015 7.87389 12.973C8.39189 12.364 8.69988 11.594 8.69988 10.761C8.69988 8.77299 6.97788 7.16302 4.84988 7.16302C4.03788 7.16302 3.28888 7.394 2.66588 7.8C2.64488 7.625 2.63788 7.44999 2.63788 7.26799C2.63788 4.08299 5.40288 1.5 8.81888 1.5C12.2349 1.5 14.9999 4.08299 14.9999 7.26799C14.9999 9.15799 14.0269 10.831 12.5289 11.881Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        Comments (2)
                                                                    </span>
                                                                </div>
                                                                <h3 className="tp-blog-grid-title">
                                                                    <a href="#">Freelancer Days 2022, What’s new?</a>
                                                                </h3>
                                                                <p>
                                                                    Cursus mattis sociis natoque penatibus et magnis
                                                                    montes,nascetur ridiculus.
                                                                </p>
                                                                <div className="tp-blog-grid-btn">
                                                                    <a href="#" className="tp-link-btn-3">
                                                                        Read More
                                                                        <span>
                                                                            <svg
                                                                                width={17}
                                                                                height={15}
                                                                                viewBox="0 0 17 15"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M16 7.5L1 7.5"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="tp-blog-grid-item p-relative mb-30">
                                                            <div className="tp-blog-grid-thumb w-img fix mb-30">
                                                                <a href="#">
                                                                    <img src={IMAGE.blogGrid7} alt="" />
                                                                </a>
                                                            </div>
                                                            <div className="tp-blog-grid-content">
                                                                <div className="tp-blog-grid-meta">
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M10.5972 10.7259L8.42715 9.43093C8.04915 9.20693 7.74115 8.66793 7.74115 8.22693V5.35693"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        12 jun, 2023
                                                                    </span>
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M12.5289 11.881L12.8019 14.093C12.8719 14.674 12.2489 15.08 11.7519 14.779L8.81888 13.036C8.49688 13.036 8.18189 13.015 7.87389 12.973C8.39189 12.364 8.69988 11.594 8.69988 10.761C8.69988 8.77299 6.97788 7.16302 4.84988 7.16302C4.03788 7.16302 3.28888 7.394 2.66588 7.8C2.64488 7.625 2.63788 7.44999 2.63788 7.26799C2.63788 4.08299 5.40288 1.5 8.81888 1.5C12.2349 1.5 14.9999 4.08299 14.9999 7.26799C14.9999 9.15799 14.0269 10.831 12.5289 11.881Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        Comments (2)
                                                                    </span>
                                                                </div>
                                                                <h3 className="tp-blog-grid-title">
                                                                    <a href="#">
                                                                        Hiring the Right Sales Team at the Right Time
                                                                    </a>
                                                                </h3>
                                                                <p>
                                                                    Cursus mattis sociis natoque penatibus et magnis
                                                                    montes,nascetur ridiculus.
                                                                </p>
                                                                <div className="tp-blog-grid-btn">
                                                                    <a href="#" className="tp-link-btn-3">
                                                                        Read More
                                                                        <span>
                                                                            <svg
                                                                                width={17}
                                                                                height={15}
                                                                                viewBox="0 0 17 15"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M16 7.5L1 7.5"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="tp-blog-grid-item p-relative mb-30">
                                                            <div className="tp-blog-grid-thumb w-img fix mb-30">
                                                                <a href="#">
                                                                    <img src={IMAGE.blogGrid8} alt="" />
                                                                </a>
                                                            </div>
                                                            <div className="tp-blog-grid-content">
                                                                <div className="tp-blog-grid-meta">
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M10.5972 10.7259L8.42715 9.43093C8.04915 9.20693 7.74115 8.66793 7.74115 8.22693V5.35693"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        12 jun, 2023
                                                                    </span>
                                                                    <span>
                                                                        <span>
                                                                            <svg
                                                                                width={16}
                                                                                height={17}
                                                                                viewBox="0 0 16 17"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M12.5289 11.881L12.8019 14.093C12.8719 14.674 12.2489 15.08 11.7519 14.779L8.81888 13.036C8.49688 13.036 8.18189 13.015 7.87389 12.973C8.39189 12.364 8.69988 11.594 8.69988 10.761C8.69988 8.77299 6.97788 7.16302 4.84988 7.16302C4.03788 7.16302 3.28888 7.394 2.66588 7.8C2.64488 7.625 2.63788 7.44999 2.63788 7.26799C2.63788 4.08299 5.40288 1.5 8.81888 1.5C12.2349 1.5 14.9999 4.08299 14.9999 7.26799C14.9999 9.15799 14.0269 10.831 12.5289 11.881Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        Comments (2)
                                                                    </span>
                                                                </div>
                                                                <h3 className="tp-blog-grid-title">
                                                                    <a href="#">
                                                                        Quality Foods Requirments For Every Human Body’s
                                                                    </a>
                                                                </h3>
                                                                <p>
                                                                    Cursus mattis sociis natoque penatibus et magnis
                                                                    montes,nascetur ridiculus.
                                                                </p>
                                                                <div className="tp-blog-grid-btn">
                                                                    <a href="#" className="tp-link-btn-3">
                                                                        Read More
                                                                        <span>
                                                                            <svg
                                                                                width={17}
                                                                                height={15}
                                                                                viewBox="0 0 17 15"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    d="M16 7.5L1 7.5"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                                <path
                                                                                    d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244"
                                                                                    stroke="currentColor"
                                                                                    strokeWidth="1.5"
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade show active"
                                            id="nav-list"
                                            role="tabpanel"
                                            aria-labelledby="nav-list-tab"
                                            tabIndex={0}
                                        >
                                            {/* blog list wrapper */}
                                            <div className="tp-blog-list-item-wrapper">
                                                <div className="tp-blog-list-item d-md-flex d-lg-block d-xl-flex">
                                                    <div className="tp-blog-list-thumb">
                                                        <a href="#">
                                                            <img src={IMAGE.blogGrid1} alt="" />
                                                        </a>
                                                    </div>
                                                    <div className="tp-blog-list-content">
                                                        <div className="tp-blog-grid-content">
                                                            <div className="tp-blog-grid-meta">
                                                                <span>
                                                                    <span>
                                                                        <svg
                                                                            width={16}
                                                                            height={17}
                                                                            viewBox="0 0 16 17"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M10.5972 10.7259L8.42715 9.43093C8.04915 9.20693 7.74115 8.66793 7.74115 8.22693V5.35693"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    24 April, 2023
                                                                </span>
                                                                <span>
                                                                    <span>
                                                                        <svg
                                                                            width={16}
                                                                            height={17}
                                                                            viewBox="0 0 16 17"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M12.5289 11.881L12.8019 14.093C12.8719 14.674 12.2489 15.08 11.7519 14.779L8.81888 13.036C8.49688 13.036 8.18189 13.015 7.87389 12.973C8.39189 12.364 8.69988 11.594 8.69988 10.761C8.69988 8.77299 6.97788 7.16302 4.84988 7.16302C4.03788 7.16302 3.28888 7.394 2.66588 7.8C2.64488 7.625 2.63788 7.44999 2.63788 7.26799C2.63788 4.08299 5.40288 1.5 8.81888 1.5C12.2349 1.5 14.9999 4.08299 14.9999 7.26799C14.9999 9.15799 14.0269 10.831 12.5289 11.881Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    Comments (0)
                                                                </span>
                                                            </div>
                                                            <h3 className="tp-blog-grid-title">
                                                                <a href="#">Sweeten your Summer Wardrobes</a>
                                                            </h3>
                                                            <p>
                                                                Cursus mattis sociis natoque penatibus et magnis dis
                                                                Parturient montes,nascetur ridiculus.!
                                                            </p>
                                                            <div className="tp-blog-grid-btn">
                                                                <a href="#" className="tp-link-btn-3">
                                                                    Read More
                                                                    <span>
                                                                        <svg
                                                                            width={17}
                                                                            height={15}
                                                                            viewBox="0 0 17 15"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M16 7.5L1 7.5"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tp-blog-list-item d-md-flex d-lg-block d-xl-flex">
                                                    <div className="tp-blog-list-thumb">
                                                        <a href="#">
                                                            <img src={IMAGE.blogList2} alt="" />
                                                        </a>
                                                    </div>
                                                    <div className="tp-blog-list-content">
                                                        <div className="tp-blog-grid-content">
                                                            <div className="tp-blog-grid-meta">
                                                                <span>
                                                                    <span>
                                                                        <svg
                                                                            width={16}
                                                                            height={17}
                                                                            viewBox="0 0 16 17"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M10.5972 10.7259L8.42715 9.43093C8.04915 9.20693 7.74115 8.66793 7.74115 8.22693V5.35693"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    8 Jun, 2023
                                                                </span>
                                                                <span>
                                                                    <span>
                                                                        <svg
                                                                            width={16}
                                                                            height={17}
                                                                            viewBox="0 0 16 17"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M12.5289 11.881L12.8019 14.093C12.8719 14.674 12.2489 15.08 11.7519 14.779L8.81888 13.036C8.49688 13.036 8.18189 13.015 7.87389 12.973C8.39189 12.364 8.69988 11.594 8.69988 10.761C8.69988 8.77299 6.97788 7.16302 4.84988 7.16302C4.03788 7.16302 3.28888 7.394 2.66588 7.8C2.64488 7.625 2.63788 7.44999 2.63788 7.26799C2.63788 4.08299 5.40288 1.5 8.81888 1.5C12.2349 1.5 14.9999 4.08299 14.9999 7.26799C14.9999 9.15799 14.0269 10.831 12.5289 11.881Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    Comments (0)
                                                                </span>
                                                            </div>
                                                            <h3 className="tp-blog-grid-title">
                                                                <a href="#">Exploring the English Countryside</a>
                                                            </h3>
                                                            <p>
                                                                Cursus mattis sociis natoque penatibus et magnis dis
                                                                Parturient montes,nascetur ridiculus.!
                                                            </p>
                                                            <div className="tp-blog-grid-btn">
                                                                <a href="#" className="tp-link-btn-3">
                                                                    Read More
                                                                    <span>
                                                                        <svg
                                                                            width={17}
                                                                            height={15}
                                                                            viewBox="0 0 17 15"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M16 7.5L1 7.5"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tp-blog-list-item d-md-flex d-lg-block d-xl-flex">
                                                    <div className="tp-blog-list-thumb">
                                                        <a href="#">
                                                            <img src={IMAGE.blogList3} alt="" />
                                                        </a>
                                                    </div>
                                                    <div className="tp-blog-list-content">
                                                        <div className="tp-blog-grid-content">
                                                            <div className="tp-blog-grid-meta">
                                                                <span>
                                                                    <span>
                                                                        <svg
                                                                            width={16}
                                                                            height={17}
                                                                            viewBox="0 0 16 17"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M10.5972 10.7259L8.42715 9.43093C8.04915 9.20693 7.74115 8.66793 7.74115 8.22693V5.35693"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    12 jun, 2023
                                                                </span>
                                                                <span>
                                                                    <span>
                                                                        <svg
                                                                            width={16}
                                                                            height={17}
                                                                            viewBox="0 0 16 17"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M12.5289 11.881L12.8019 14.093C12.8719 14.674 12.2489 15.08 11.7519 14.779L8.81888 13.036C8.49688 13.036 8.18189 13.015 7.87389 12.973C8.39189 12.364 8.69988 11.594 8.69988 10.761C8.69988 8.77299 6.97788 7.16302 4.84988 7.16302C4.03788 7.16302 3.28888 7.394 2.66588 7.8C2.64488 7.625 2.63788 7.44999 2.63788 7.26799C2.63788 4.08299 5.40288 1.5 8.81888 1.5C12.2349 1.5 14.9999 4.08299 14.9999 7.26799C14.9999 9.15799 14.0269 10.831 12.5289 11.881Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    Comments (0)
                                                                </span>
                                                            </div>
                                                            <h3 className="tp-blog-grid-title">
                                                                <a href="#">Fully Embrace the Return of ’90s fashion</a>
                                                            </h3>
                                                            <p>
                                                                Cursus mattis sociis natoque penatibus et magnis dis
                                                                Parturient montes,nascetur ridiculus.!
                                                            </p>
                                                            <div className="tp-blog-grid-btn">
                                                                <a href="#" className="tp-link-btn-3">
                                                                    Read More
                                                                    <span>
                                                                        <svg
                                                                            width={17}
                                                                            height={15}
                                                                            viewBox="0 0 17 15"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M16 7.5L1 7.5"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tp-blog-list-item d-md-flex d-lg-block d-xl-flex">
                                                    <div className="tp-blog-list-thumb">
                                                        <a href="#">
                                                            <img src={IMAGE.blogList4} alt="" />
                                                        </a>
                                                    </div>
                                                    <div className="tp-blog-list-content">
                                                        <div className="tp-blog-grid-content">
                                                            <div className="tp-blog-grid-meta">
                                                                <span>
                                                                    <span>
                                                                        <svg
                                                                            width={16}
                                                                            height={17}
                                                                            viewBox="0 0 16 17"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M10.5972 10.7259L8.42715 9.43093C8.04915 9.20693 7.74115 8.66793 7.74115 8.22693V5.35693"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    30 October, 2023
                                                                </span>
                                                                <span>
                                                                    <span>
                                                                        <svg
                                                                            width={16}
                                                                            height={17}
                                                                            viewBox="0 0 16 17"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M12.5289 11.881L12.8019 14.093C12.8719 14.674 12.2489 15.08 11.7519 14.779L8.81888 13.036C8.49688 13.036 8.18189 13.015 7.87389 12.973C8.39189 12.364 8.69988 11.594 8.69988 10.761C8.69988 8.77299 6.97788 7.16302 4.84988 7.16302C4.03788 7.16302 3.28888 7.394 2.66588 7.8C2.64488 7.625 2.63788 7.44999 2.63788 7.26799C2.63788 4.08299 5.40288 1.5 8.81888 1.5C12.2349 1.5 14.9999 4.08299 14.9999 7.26799C14.9999 9.15799 14.0269 10.831 12.5289 11.881Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    Comments (0)
                                                                </span>
                                                            </div>
                                                            <h3 className="tp-blog-grid-title">
                                                                <a href="#">
                                                                    Visiting Great Sand <br /> Dunes Park
                                                                </a>
                                                            </h3>
                                                            <p>
                                                                Cursus mattis sociis natoque penatibus et magnis dis
                                                                Parturient montes,nascetur ridiculus.!
                                                            </p>
                                                            <div className="tp-blog-grid-btn">
                                                                <a href="#" className="tp-link-btn-3">
                                                                    Read More
                                                                    <span>
                                                                        <svg
                                                                            width={17}
                                                                            height={15}
                                                                            viewBox="0 0 17 15"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M16 7.5L1 7.5"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tp-blog-list-item d-md-flex d-lg-block d-xl-flex">
                                                    <div className="tp-blog-list-thumb">
                                                        <a href="#">
                                                            <img src={IMAGE.blogList5} alt="" />
                                                        </a>
                                                    </div>
                                                    <div className="tp-blog-list-content">
                                                        <div className="tp-blog-grid-content">
                                                            <div className="tp-blog-grid-meta">
                                                                <span>
                                                                    <span>
                                                                        <svg
                                                                            width={16}
                                                                            height={17}
                                                                            viewBox="0 0 16 17"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M10.5972 10.7259L8.42715 9.43093C8.04915 9.20693 7.74115 8.66793 7.74115 8.22693V5.35693"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    24 April, 2023
                                                                </span>
                                                                <span>
                                                                    <span>
                                                                        <svg
                                                                            width={16}
                                                                            height={17}
                                                                            viewBox="0 0 16 17"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M12.5289 11.881L12.8019 14.093C12.8719 14.674 12.2489 15.08 11.7519 14.779L8.81888 13.036C8.49688 13.036 8.18189 13.015 7.87389 12.973C8.39189 12.364 8.69988 11.594 8.69988 10.761C8.69988 8.77299 6.97788 7.16302 4.84988 7.16302C4.03788 7.16302 3.28888 7.394 2.66588 7.8C2.64488 7.625 2.63788 7.44999 2.63788 7.26799C2.63788 4.08299 5.40288 1.5 8.81888 1.5C12.2349 1.5 14.9999 4.08299 14.9999 7.26799C14.9999 9.15799 14.0269 10.831 12.5289 11.881Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    Comments (0)
                                                                </span>
                                                            </div>
                                                            <h3 className="tp-blog-grid-title">
                                                                <a href="#">Exploring the English Countryside</a>
                                                            </h3>
                                                            <p>
                                                                Cursus mattis sociis natoque penatibus et magnis dis
                                                                Parturient montes,nascetur ridiculus.!
                                                            </p>
                                                            <div className="tp-blog-grid-btn">
                                                                <a href="#" className="tp-link-btn-3">
                                                                    Read More
                                                                    <span>
                                                                        <svg
                                                                            width={17}
                                                                            height={15}
                                                                            viewBox="0 0 17 15"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M16 7.5L1 7.5"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tp-blog-list-item d-md-flex d-lg-block d-xl-flex">
                                                    <div className="tp-blog-list-thumb">
                                                        <a href="#">
                                                            <img src={IMAGE.blogList6} alt="" />
                                                        </a>
                                                    </div>
                                                    <div className="tp-blog-list-content">
                                                        <div className="tp-blog-grid-content">
                                                            <div className="tp-blog-grid-meta">
                                                                <span>
                                                                    <span>
                                                                        <svg
                                                                            width={16}
                                                                            height={17}
                                                                            viewBox="0 0 16 17"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M15 8.5C15 12.364 11.864 15.5 8 15.5C4.136 15.5 1 12.364 1 8.5C1 4.636 4.136 1.5 8 1.5C11.864 1.5 15 4.636 15 8.5Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M10.5972 10.7259L8.42715 9.43093C8.04915 9.20693 7.74115 8.66793 7.74115 8.22693V5.35693"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    6 April, 2023
                                                                </span>
                                                                <span>
                                                                    <span>
                                                                        <svg
                                                                            width={16}
                                                                            height={17}
                                                                            viewBox="0 0 16 17"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M12.5289 11.881L12.8019 14.093C12.8719 14.674 12.2489 15.08 11.7519 14.779L8.81888 13.036C8.49688 13.036 8.18189 13.015 7.87389 12.973C8.39189 12.364 8.69988 11.594 8.69988 10.761C8.69988 8.77299 6.97788 7.16302 4.84988 7.16302C4.03788 7.16302 3.28888 7.394 2.66588 7.8C2.64488 7.625 2.63788 7.44999 2.63788 7.26799C2.63788 4.08299 5.40288 1.5 8.81888 1.5C12.2349 1.5 14.9999 4.08299 14.9999 7.26799C14.9999 9.15799 14.0269 10.831 12.5289 11.881Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M8.7 10.7611C8.7 11.5941 8.39201 12.3641 7.87401 12.9731C7.18101 13.8131 6.082 14.3521 4.85 14.3521L3.023 15.437C2.715 15.626 2.323 15.3671 2.365 15.0101L2.54 13.6311C1.602 12.9801 1 11.9371 1 10.7611C1 9.52905 1.658 8.44407 2.666 7.80007C3.289 7.39407 4.038 7.16309 4.85 7.16309C6.978 7.16309 8.7 8.77305 8.7 10.7611Z"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    Comments (0)
                                                                </span>
                                                            </div>
                                                            <h3 className="tp-blog-grid-title">
                                                                <a href="#">Freelancer Days 2022, What’s new?</a>
                                                            </h3>
                                                            <p>
                                                                Cursus mattis sociis natoque penatibus et magnis dis
                                                                Parturient montes,nascetur ridiculus.!
                                                            </p>
                                                            <div className="tp-blog-grid-btn">
                                                                <a href="#" className="tp-link-btn-3">
                                                                    Read More
                                                                    <span>
                                                                        <svg
                                                                            width={17}
                                                                            height={15}
                                                                            viewBox="0 0 17 15"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M16 7.5L1 7.5"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M9.9502 1.47541L16.0002 7.49941L9.9502 13.5244"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="tp-blog-pagination mt-30">
                                                    <div className="tp-pagination">
                                                        <nav>
                                                            <ul>
                                                                <li>
                                                                    <a
                                                                        href="#"
                                                                        className="tp-pagination-prev prev page-numbers"
                                                                    >
                                                                        <svg
                                                                            width={15}
                                                                            height={13}
                                                                            viewBox="0 0 15 13"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M1.00017 6.77879L14 6.77879"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M6.24316 11.9999L0.999899 6.77922L6.24316 1.55762"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">1</a>
                                                                </li>
                                                                <li>
                                                                    <span className="current">2</span>
                                                                </li>
                                                                <li>
                                                                    <a href="#">3</a>
                                                                </li>
                                                                <li>
                                                                    <a href="#" className="next page-numbers">
                                                                        <svg
                                                                            width={15}
                                                                            height={13}
                                                                            viewBox="0 0 15 13"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M13.9998 6.77883L1 6.77883"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                            <path
                                                                                d="M8.75684 1.55767L14.0001 6.7784L8.75684 12"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            />
                                                                        </svg>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4">
                                <div className="tp-sidebar-wrapper tp-sidebar-ml--24">
                                    <div className="tp-sidebar-widget mb-35">
                                        <div className="tp-sidebar-search">
                                            <form action="#">
                                                <div className="tp-sidebar-search-input">
                                                    <input type="text" placeholder="Search..." />
                                                    <button type="submit">
                                                        <svg
                                                            width={18}
                                                            height={18}
                                                            viewBox="0 0 18 18"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M8.11111 15.2222C12.0385 15.2222 15.2222 12.0385 15.2222 8.11111C15.2222 4.18375 12.0385 1 8.11111 1C4.18375 1 1 4.18375 1 8.11111C1 12.0385 4.18375 15.2222 8.11111 15.2222Z"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M16.9995 17L13.1328 13.1333"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* about */}
                                    <div className="tp-sidebar-widget mb-35">
                                        <h3 className="tp-sidebar-widget-title">About me</h3>
                                        <div className="tp-sidebar-widget-content">
                                            <div className="tp-sidebar-about">
                                                <div className="tp-sidebar-about-thumb mb-25">
                                                    <a href="#">
                                                        <img src={IMAGE.user11} alt="" />
                                                    </a>
                                                </div>
                                                <div className="tp-sidebar-about-content">
                                                    <h3 className="tp-sidebar-about-title">
                                                        <a href="#">Ravi O'Leigh</a>
                                                    </h3>
                                                    <span className="tp-sidebar-about-designation">
                                                        Photographer &amp; Blogger
                                                    </span>
                                                    <p>
                                                        Lorem ligula eget dolor. Aenean massa. Cum sociis que penatibus
                                                        magnis dis parturient
                                                    </p>
                                                    <div className="tp-sidebar-about-signature">
                                                        <img src={IMAGE.signature} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* about end */}
                                    {/* latest post start */}
                                    <div className="tp-sidebar-widget mb-35">
                                        <h3 className="tp-sidebar-widget-title">Latest Posts</h3>
                                        <div className="tp-sidebar-widget-content">
                                            <div className="tp-sidebar-blog-item-wrapper">
                                                <div className="tp-sidebar-blog-item d-flex align-items-center">
                                                    <div className="tp-sidebar-blog-thumb">
                                                        <a href="#">
                                                            <img src={IMAGE.blogSlideBar1} alt="" />
                                                        </a>
                                                    </div>
                                                    <div className="tp-sidebar-blog-content">
                                                        <div className="tp-sidebar-blog-meta">
                                                            <span>12 April, 2023</span>
                                                        </div>
                                                        <h3 className="tp-sidebar-blog-title">
                                                            <a href="#">Sweeten your summer Wardrobes</a>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="tp-sidebar-blog-item d-flex align-items-center">
                                                    <div className="tp-sidebar-blog-thumb">
                                                        <a href="#">
                                                            <img src={IMAGE.blogSlideBar1} alt="" />
                                                        </a>
                                                    </div>
                                                    <div className="tp-sidebar-blog-content">
                                                        <div className="tp-sidebar-blog-meta">
                                                            <span>8 July, 2023</span>
                                                        </div>
                                                        <h3 className="tp-sidebar-blog-title">
                                                            <a href="#">Exploring the English Countryside</a>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="tp-sidebar-blog-item d-flex align-items-center">
                                                    <div className="tp-sidebar-blog-thumb">
                                                        <a href="#">
                                                            <img src={IMAGE.blogSlideBar3} alt="" />
                                                        </a>
                                                    </div>
                                                    <div className="tp-sidebar-blog-content">
                                                        <div className="tp-sidebar-blog-meta">
                                                            <span>12 April, 2023</span>
                                                        </div>
                                                        <h3 className="tp-sidebar-blog-title">
                                                            <a href="#">Freelancer Days 2023, What’s new?</a>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* latest post end */}
                                    {/* categories start */}
                                    <div className="tp-sidebar-widget widget_categories mb-35">
                                        <h3 className="tp-sidebar-widget-title">Categories</h3>
                                        <div className="tp-sidebar-widget-content">
                                            <ul>
                                                <li>
                                                    <a href="#">
                                                        Farming <span>(12)</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        Crisp Bread &amp; Cake <span>(6)</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        Milk &amp; Meat <span>(2)</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        Organic Fruits <span>(8)</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        Sea Foods <span>(0)</span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        Vegetable <span>(3)</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* categories end */}
                                    {/* tag cloud start */}
                                    <div className="tp-sidebar-widget mb-35">
                                        <h3 className="tp-sidebar-widget-title">Popular Tags</h3>
                                        <div className="tp-sidebar-widget-content tagcloud">
                                            <a href="#">Summer</a>
                                            <a href="#">Vintage</a>
                                            <a href="#">Sunglasses</a>
                                            <a href="#">Organic Food</a>
                                            <a href="#">Lifesttyle</a>
                                            <a href="#">Nature</a>
                                        </div>
                                    </div>
                                    {/* tag cloud end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* blog grid area end */}
            </main>
        </>
    );
}

export default Blog;
