import ButtonProductDetailAction from "../Button/ButtonProductDetailAction";
import { CommonService } from "../../services/CommonService";

import { Link } from "react-router-dom";

function ProductDetail(props) {
    const { product } = props;

    return (
        <div className="tp-product-item-2 mb-40">
            <div className="tp-product-thumb-2 p-relative z-index-1 fix w-img">
                <Link to={`/product/${product.id}/${product.slug}`}>
                    <img src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${product.image}`} alt="" />
                </Link>
                <ButtonProductDetailAction product={product} />
            </div>
            <div className="tp-product-content-2 pt-15">
                <div className="tp-product-tag-2">
                    {product.categoryProduct.map((cate, index) => (
                        <Link key={index} to="/shop">
                            {cate.name},
                        </Link>
                    ))}
                </div>
                <h3 className="tp-product-title-2">
                    <Link to={`/product/${product.id}/${product.slug}`}>
                        {CommonService.truncateText(product.name, 28)}
                    </Link>
                </h3>
                <div className="tp-product-price-wrapper-2">
                    <span className="tp-product-price-2 new-price">
                        {product.productDetail ? (
                            <>
                                {CommonService.checkDiscount(product.productDetail)
                                    ? CommonService.handleDiscountProduct(product.productDetail)
                                    : CommonService.handlePriceProduct(product.productDetail)}
                            </>
                        ) : (
                            <>0 đ</>
                        )}
                    </span>
                    <span className="tp-product-price-2 old-price text-red-500">
                        {product.productDetail ? (
                            <>
                                {" "}
                                {CommonService.checkDiscount(product.productDetail)
                                    ? CommonService.handlePriceProduct(product.productDetail)
                                    : null}{" "}
                            </>
                        ) : (
                            <>0 đ</>
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ProductDetail;
