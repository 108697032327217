import { useEffect, useState, useRef } from "react";
import ProductImageCarousel from "../../../components/Product/ProductImageCarousel";
import ProductRelatedCarousel from "../../../components/Product/ProductRelatedCarousel";
import ProductDesc from "../../../components/Product/ProductDesc";
import ProductDescription from "../../../components/Product/ProductDescription";
import { Link, useParams } from 'react-router-dom';
import ProductServices from "../../../services/ProductServices";
import { Toast } from "primereact/toast";

function Product() {
    const { id, slug } = useParams();
    const [productDetail, setProductDetail] = useState(null);
    const toast = useRef(null);
    const showSuccess = (message, time) => {
        toast.current.show({ severity: "success", summary: "Thành công", detail: message, life: time });
    };
    const showError = (message, time) => {
        toast.current.show({ severity: "error", summary: "Thất bại", detail: message, life: time });
    };
    useEffect(() => {
        getProductDetail();
    },[id])
    
    const getProductDetail = () => {
        ProductServices.getProductDetail(id)
            .then((data) => {
                //setProduct
                setProductDetail(data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };
    return (
        <>
            <Toast ref={toast} />
            {productDetail !== null && (<>
            <main>
                {/* breadcrumb area start */}
                <section className="breadcrumb__area breadcrumb__style-2 include-bg pt-50 pb-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="breadcrumb__content p-relative z-index-1">
                                    <div className="breadcrumb__list has-icon">
                                        <span className="breadcrumb-icon">
                                            <svg
                                                width={17}
                                                height={17}
                                                viewBox="0 0 17 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M1.42393 16H15.5759C15.6884 16 15.7962 15.9584 15.8758 15.8844C15.9553 15.8104 16 15.71 16 15.6054V6.29143C16 6.22989 15.9846 6.1692 15.9549 6.11422C15.9252 6.05923 15.8821 6.01147 15.829 5.97475L8.75305 1.07803C8.67992 1.02736 8.59118 1 8.5 1C8.40882 1 8.32008 1.02736 8.24695 1.07803L1.17098 5.97587C1.11791 6.01259 1.0748 6.06035 1.04511 6.11534C1.01543 6.17033 0.999976 6.23101 1 6.29255V15.6063C1.00027 15.7108 1.04504 15.8109 1.12451 15.8847C1.20398 15.9585 1.31165 16 1.42393 16ZM10.1464 15.2107H6.85241V10.6202H10.1464V15.2107ZM1.84866 6.48977L8.4999 1.88561L15.1517 6.48977V15.2107H10.9946V10.2256C10.9946 10.1209 10.95 10.0206 10.8704 9.94654C10.7909 9.87254 10.683 9.83096 10.5705 9.83096H6.42848C6.316 9.83096 6.20812 9.87254 6.12858 9.94654C6.04904 10.0206 6.00435 10.1209 6.00435 10.2256V15.2107H1.84806L1.84866 6.48977Z"
                                                    fill="#55585B"
                                                    stroke="#55585B"
                                                    strokeWidth="0.5"
                                                />
                                            </svg>
                                        </span>
                                        <span>
                                            <Link to="/">Home</Link>
                                        </span>
                                        <span>
                                            <Link to="/shop">Shop</Link>
                                        </span>
                                        <span>{productDetail.name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* breadcrumb area end */}
                {/* product details area start */}
                <section className="tp-product-details-area">
                    <div className="tp-product-details-top pb-115">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-7 col-lg-6">
                                    {/* product slider */}
                                    <ProductImageCarousel productDetail={productDetail}/>
                                </div>
                                {/* col end */}
                                <div className="col-xl-5 col-lg-6">
                                    <ProductDesc productDetail={productDetail}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tp-product-details-bottom pb-140">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <ProductDescription productDetail={productDetail}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* product details area end */}
                {/* related product area start */}
                <section className="tp-related-product pt-95 pb-120">
                    <div className="container">
                        <div className="row">
                            <div className="tp-section-title-wrapper-6 text-center mb-40">
                                <span className="tp-section-title-pre-6">Món đồ khác</span>
                                <h3 className="tp-section-title-6">Có Thể Bạn Sẽ Thích</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="tp-product-related-slider">
                                <div className="tp-product-related-slider-active swiper-container  mb-10">
                                    <div className="swiper-wrapper">
                                        <ProductRelatedCarousel productDetail={productDetail}/>
                                    </div>
                                </div>
                                <div className="tp-related-swiper-scrollbar tp-swiper-scrollbar" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* related product area end */}
                {/* product desc */}

                {/* product desc end*/}
            </main>
            </>)}    
        </>
    );
}

export default Product;
