import axios from "axios";
// import {CommonService} from "./CommonService";
import {AxiosService} from "./AxiosService";

const BASE_URL = process.env.REACT_APP_BASE_URL; // Đổi thành URL thật của bạn

const ProductServices = {
    
    getAllProducts: async (pageNumber,pageSize,sort) => {
        try {
            // const response = await axios.get(`${BASE_URL}/api/v1/shop/products?page=${pageNumber}&size=${pageSize}&sort=${sort}`);
            const response = await AxiosService.get(`${BASE_URL}/api/v1/shop/products?page=${pageNumber}&size=${pageSize}&sort=${sort}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getAllProductsNoPage: async () => {
        try {
            const response = await AxiosService.get(`${BASE_URL}/api/v1/products`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getProductDetail: async (id) => {
        try {
            const response = await AxiosService.get(`${BASE_URL}/api/v1/shop/products/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getAllProductRelated: async (id) => {
        try {
            const response = await AxiosService.get(`${BASE_URL}/api/v1/shop/products/related?categoryId=${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getAllProductByCategories: async (ids, pageNumber,pageSize,sort) => {
        try {
            const response = await AxiosService.get(`${BASE_URL}/api/v1/client/products/product-by-category?ids=${ids}&page=${pageNumber}&size=${pageSize}&sort=${sort}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
};

export default ProductServices;