import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import productService from "../../services/ProductServices";

export const fetchProducts = createAsyncThunk("products/fetchProducts", async () => {
    const response = await productService.getProducts();
    return response.data;
});

const productSlice = createSlice({
    name: "products",
    initialState: { data: [], loading: "idle", error: null },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.loading = "pending";
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.loading = "fulfilled";
                state.data = action.payload;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.loading = "rejected";
                state.error = action.error.message;
            });
    },
});

export default productSlice.reducer;
