import { Rating } from "primereact/rating";

function BannerFeedback(props) {
    const { feedback } = props;
    return (
        <div className="tp-testimonial-item text-center mb-20 swiper-slide">
            <div className="tp-testimonial-rating">
                <div className="flex justify-content-center">
                    <Rating value={5} readOnly cancel={false} />
                </div>
            </div>
            <div className="tp-testimonial-content">
                <p>"{feedback.title}"</p>
            </div>
            <div className="tp-testimonial-user-wrapper d-flex align-items-center justify-content-center">
                <div className="tp-testimonial-user d-flex align-items-center">
                    <div className="tp-testimonial-avater mr-10">
                        {feedback.image !== null && <img src={`${process.env.REACT_APP_MEDIA_URL}/api/v1${feedback.image}`} alt="" />}
                    </div>
                    <div className="tp-testimonial-user-info tp-testimonial-user-translate">
                        <h3 className="tp-testimonial-user-title">{feedback.customerName}</h3>
                        <span className="tp-testimonial-designation">{feedback.customerProvince}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BannerFeedback;
