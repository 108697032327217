import axios from "axios";

export const AxiosService = {
    async getIpGeolocation() {
        const geoInfoKey = 'geoInfo';
        const geoInfoExpireKey = 'geoInfoExpire';

        try {
            // Lấy thông tin từ sessionStorage nếu có và còn hiệu lực
            const storedGeoInfo = JSON.parse(sessionStorage.getItem(geoInfoKey));
            const expireTime = parseInt(sessionStorage.getItem(geoInfoExpireKey), 10);

            if (storedGeoInfo && expireTime && expireTime > Date.now()) {
                return storedGeoInfo;
            }

            // Gọi API để lấy thông tin mới nếu không có hoặc hết hạn
            const response = await axios.get('https://get.geojs.io/v1/ip/geo.json');
            const geoInfo = response.data;

            // Lưu thông tin vào sessionStorage
            const expireTimeInMinutes = 3;
            const expireTimestamp = Date.now() + expireTimeInMinutes * 60 * 1000;

            sessionStorage.setItem(geoInfoKey, JSON.stringify(geoInfo));
            sessionStorage.setItem(geoInfoExpireKey, expireTimestamp.toString());

            return geoInfo;
        } catch (error) {
            console.error('Error fetching geo info:', error);
            return null;
        }
    },

    setupHeader() {
        try {
            const headers = {};
            const geoInfo = JSON.parse(sessionStorage.getItem('geoInfo'));
            if (geoInfo) {
                headers['X-IP'] = geoInfo.ip;
                headers['X-Region'] = geoInfo.region;
                headers['X-Country'] = geoInfo.country;
                headers['X-City'] = geoInfo.city;
                headers['X-CountryCode'] = geoInfo.country_code;
                headers['X-Longitude'] = geoInfo.longitude;
                headers['X-Latitude'] = geoInfo.latitude;
                headers['X-TimeZone'] = geoInfo.timezone;
                headers['X-OrganizationName'] = geoInfo.organization_name;
            }
            return headers;
        } catch (error) {
            return null;
        }
    },
    get: async (url, params = {}, customHeaders = {}) => {
        try {
            const headers = await AxiosService.setupHeader();
            const mergedHeaders = { ...customHeaders, ...headers };
            return await axios.get(url, {params, headers: mergedHeaders});
        } catch (error) {
            handleError(error);
        }
    },

    post: async (url, data, customHeaders = {}) => {
        try {
            const headers = await AxiosService.setupHeader();
            const mergedHeaders = { ...customHeaders, ...headers };
            return  await axios.post(url, data, { headers: mergedHeaders });
        } catch (error) {
            handleError(error);
        }
    },

    put: async (url, data, customHeaders = {}) => {
        try {
            const headers = await AxiosService.setupHeader();
            const mergedHeaders = { ...customHeaders, ...headers };
            return await axios.put(url, data, { headers: mergedHeaders });
        } catch (error) {
            handleError(error);
        }
    }
};

const handleError = (error) => {
    // Xử lý lỗi tại đây, ví dụ như log, hiển thị thông báo, vv.
    console.error('API call error:', error);
    throw error;
};

AxiosService.getIpGeolocation();
setInterval(() => {
    AxiosService.getIpGeolocation();
}, 5 * 60 * 1000);