import axios from "axios";
import {AxiosService} from "./AxiosService";

const BASE_URL = process.env.REACT_APP_BASE_URL; // Đổi thành URL thật của bạn

const VoucherServices = {
    applyVoucher: async (value) => {
        try {
            const response = await AxiosService.post(`${BASE_URL}/api/v1/client/voucher/apply-voucher`,value,null);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getAllVoucher: async () => {
        try {
            const response = await AxiosService.get(`${BASE_URL}/api/v1/client/voucher`,null);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
};
export default VoucherServices;