import axios from "axios";

export const CommonService = {
    handleCategory(arrayCategories) {
        const stringCategories = arrayCategories.map((category) => category.name).join(", ");
        return stringCategories;
    },
    handleFomatCurrency(input) {
        const formattedCurrency = input.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
        });

        return formattedCurrency;
    },
    formatNumberToK(number) {
        if (number >= 1000) {
            const abbreviatedNumber = number / 1000;
            const formattedString = abbreviatedNumber.toFixed(1).replace(/\.0$/, ""); // Sử dụng replace để loại bỏ số 0 dư thừa
            return formattedString + "k";
        } else {
            return number.toString();
        }
    },
    truncateText(text, maxLength) {
        if (text.length <= maxLength) {
            return <span>{text}</span>;
        }
        const truncatedText = text.substring(0, maxLength) + "...";

        return truncatedText;
    },
    handlePriceProduct(dataInput) {
        let priceRange = 0;
        priceRange = dataInput.reduce(
            (accumulator, productDetail) => {
                const price = productDetail.price;
                if (price < accumulator.minPrice) {
                    accumulator.minPrice = price;
                }
                if (price > accumulator.maxPrice) {
                    accumulator.maxPrice = price;
                }
                return accumulator;
            },
            { minPrice: Infinity, maxPrice: 0 }
        );
        const result = `${CommonService.handleFomatCurrency(priceRange.minPrice)} - ${CommonService.handleFomatCurrency(
            priceRange.maxPrice
        )}`;
        return result;
    },
    handleDiscountProduct(dataInput) {
        let priceRange = 0;
        priceRange = dataInput.reduce(
            (accumulator, productDetail) => {
                const price = productDetail.price - productDetail.discount;
                if (price < accumulator.minPrice) {
                    accumulator.minPrice = price;
                }
                if (price > accumulator.maxPrice) {
                    accumulator.maxPrice = price;
                }
                return accumulator;
            },
            { minPrice: Infinity, maxPrice: 0 }
        );
        const result = `${CommonService.handleFomatCurrency(priceRange.minPrice)} - ${CommonService.handleFomatCurrency(
            priceRange.maxPrice
        )}`;
        return result;
    },
    checkDiscount(input) {
        const hasDiscount = input.some((detail) => detail.discount > 0);
        return hasDiscount;
    },
    handleProToProDetail(data) {
        const outputArray = data.reduce((accumulator, current) => {
            //Tìm kiếm xem có đối tượng trong mảng kết quả đã có productId tương tự hay chưa
            const existingItem = accumulator.find((item) => item.productId === current.productId);

            if (existingItem) {
                // Nếu đã tồn tại, thì thêm proDetail vào mảng proDetail của đối tượng tồn tại
                existingItem.proDetail.push({
                    id: current.id,
                    quantity: current.quantity,
                    proDe: current.proDe,
                });
            } else {
                // Nếu chưa tồn tại, thì thêm đối tượng mới vào mảng kết quả
                accumulator.push({
                    productId: current.productId,
                    productName: current.productName,
                    productImage: current.productImage,
                    proDetail: [
                        {
                            id: current.id,
                            quantity: current.quantity,
                            proDe: current.proDe,
                        },
                    ],
                });
            }
            return accumulator;
        }, []);
        return outputArray;
    },
    handleRemoveProductId(idRemove) {
        const existingCart = JSON.parse(localStorage.getItem("shoppingCartKoro")); //local storage
        // Sử dụng `filter` để loại bỏ phần tử có `productId` bằng `idRemove`
        const updatedData = existingCart.filter((item) => item.productId !== idRemove);
        // Lưu `updatedData` lại vào `localStorage`
        localStorage.setItem("shoppingCartKoro", JSON.stringify(updatedData));
    },
    handleRemoveProductDetailId(productId, proDetailId) {
        // Lấy dữ liệu giỏ hàng từ local storage
        const existingCart = JSON.parse(localStorage.getItem("shoppingCartKoro"));

        // Duyệt qua mảng sản phẩm trong giỏ hàng
        existingCart.forEach((product, productIndex) => {
            if (product.productId === productId) {
                // Tìm sản phẩm con (proDetail) cần xoá
                const proDetailIndex = product.proDetail.findIndex((proDetail) => proDetail.id === proDetailId);

                if (proDetailIndex !== -1) {
                    // Xoá sản phẩm con khỏi mảng proDetail
                    product.proDetail.splice(proDetailIndex, 1);

                    // Kiểm tra nếu proDetail là rỗng hoặc null sau khi xoá
                    if (!product.proDetail || product.proDetail.length === 0) {
                        // Xoá sản phẩm cha nếu không có proDetail hoặc proDetail rỗng
                        existingCart.splice(productIndex, 1);
                    }
                }
                //Xoá sản phẩm nếu proDetail là rỗng
            }
        });

        // Cập nhật dữ liệu giỏ hàng đã xoá lên local storage
        localStorage.setItem("shoppingCartKoro", JSON.stringify(existingCart));
    },
    addItemToCart(data) {
        const checkQuantityData = data.filter(
            (item) => item.quantity !== null && item.quantity !== "" && parseInt(item.quantity) > 0
        );
        const existingCart = JSON.parse(localStorage.getItem("shoppingCartKoro")); //local storage
        if (existingCart) {
            //so sánh SP vừa thêm
            const convertProductNewData = this.handleProToProDetail(checkQuantityData);
            //lap cartData
            convertProductNewData.forEach((itemNewData) => {
                const matchedProduct = existingCart.find((itemCart) => itemCart.productId === itemNewData.productId);
                if (matchedProduct) {
                    itemNewData.proDetail.forEach((proDetailB) => {
                        const matchingProDetailA = matchedProduct.proDetail.find(
                            (proDetailA) => proDetailA.id === proDetailB.id
                        );

                        if (matchingProDetailA) {
                            // Nếu proDetail đã tồn tại trong mảng A, tăng quantity
                            matchingProDetailA.quantity = String(
                                parseInt(matchingProDetailA.quantity) + parseInt(proDetailB.quantity)
                            );
                        } else {
                            // Nếu proDetail không tồn tại trong mảng A, thêm nó vào mảng proDetail của đối tượng trong mảng A
                            matchedProduct.proDetail.push(proDetailB);
                        }
                    });
                    //update
                    localStorage.setItem("shoppingCartKoro", JSON.stringify(existingCart));
                } else {

                    //nếu như chưa tồn tại sản phẩm đó bên trong Cart
                    localStorage.setItem(
                        "shoppingCartKoro",
                        JSON.stringify([...existingCart, ...convertProductNewData])
                    );
                }
            });
        } else {
            localStorage.setItem("shoppingCartKoro", JSON.stringify(this.handleProToProDetail(data)));
        }
    },
    handleTotalPriceCart() {
        const existingCart = JSON.parse(localStorage.getItem("shoppingCartKoro")); //local storage
        let totalPrice = 0;
        if (existingCart) {
            existingCart.forEach((product) => {
                product.proDetail.forEach((detail) => {
                    const quantity = parseInt(detail.quantity);
                    const price = detail.proDe.price - detail.proDe.discount;
                    totalPrice += quantity * price;
                });
            });
        }
        return totalPrice;
    },
    getCart() {
        const existingCart = JSON.parse(localStorage.getItem("shoppingCartKoro"));
        return existingCart;
    },
    handleChangeQuantityProDe(proDeId, quantity) {
        const existingCart = JSON.parse(localStorage.getItem("shoppingCartKoro")); // Lấy dữ liệu từ local storage
        // Duyệt qua mảng sản phẩm trong existingCart
        existingCart.forEach((product) => {
            // Dùng .find() để tìm sản phẩm con với proDeId tương ứng trong mảng sản phẩm con của sản phẩm hiện tại
            const proDeMatched = product.proDetail.find((itemB) => itemB.id === proDeId);
            // Nếu tìm thấy sản phẩm con, bạn có thể thực hiện các thay đổi cần thiết tại đây
            if (proDeMatched) {
                proDeMatched.quantity = String(quantity);
                // Cập nhật lại local storage với dữ liệu mới
                localStorage.setItem("shoppingCartKoro", JSON.stringify(existingCart));
            }
        });
    },
    getTotalItemInCart() {
        const existingCart = JSON.parse(localStorage.getItem("shoppingCartKoro"));
        let totalQuantity = 0;

        // Duyệt qua từng sản phẩm trong giỏ hàng
        if (existingCart !== null) {
            existingCart.forEach((product) => {
                // Duyệt qua từng proDetail trong sản phẩm
                product.proDetail.forEach((proDe) => {
                    // Cộng dồn số lượng của proDetail
                    totalQuantity += parseInt(proDe.quantity);
                });
            });
        }

        return totalQuantity;
    },
    // async sendMail() {
    //     let currentDate = new Date();
    //     currentDate.setDate(currentDate.getDate() + 5);
    //     let formattedDate =
    //         currentDate.getDate() + "/" + (currentDate.getMonth() + 1) + "/" + currentDate.getFullYear();
    //     let data = {
    //         recipient: this.order.email,
    //         msgBody:
    //             "Dear " +
    //             this.order.name +
    //             ",\n\nKoro House rất vinh dự được quý khách tin tưởng và ủng hộ!\nĐơn hàng đã được đóng gói và chuẩn bị gửi đi.\nSố điện thoại: " +
    //             this.order.phone +
    //             ",\nĐịa chỉ nhận hàng: " +
    //             this.order.address +
    //             "\nTổng tiền cần thanh toán: " +
    //             this.order.paymentAmount +
    //             "đ\nNgày dự kiến nhận hàng:" +
    //             formattedDate +
    //             "(có thể bị delay do bên vận chuyển)\nHãy chú ý điện thoại của shipper giúp shop nhé\nCảm ơn quý khách rất nhiều, tặng ưu đãi freeship cho đơn hàng sau!",
    //         subject: "CHÚC MỪNG QUÝ KHÁCH ĐÃ ĐẶT HÀNG THÀNH CÔNG",
    //     };
    //     try {
    //         const responseSendEmail = await api.post("/mails/send", data);
    //         this.showSuccess(responseSendEmail.data);
    //     } catch (error) {
    //         this.showError(error);
    //     }
    // },
    handleSaveInfo(order, cart) {
        var infoCustomer = {
            name: order.name,
            phone: order.phone,
            address: order.address,
            email: order.email,
            height: order.height,
            weight: order.weight,
            province: order.province,
            district: order.district,
            ward: order.ward,
            productMoney: order.productMoney,
            shippingPrice: order.shippingPrice,
            totalMoney: order.totalMoney,
            orderDetail: cart,
            billCode: order.billCode,
            orderTime: order.orderTime,
            discount: order.discount,
        };
        localStorage.setItem("infoKoro", JSON.stringify(infoCustomer));
    },
    formatDate(inputString) {
        // Tạo đối tượng Date từ chuỗi đầu vào
        const date = new Date(inputString);

        // Lấy các thành phần giờ, phút, giây, ngày, tháng và năm
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const day = date.getDate();
        const month = date.getMonth() + 1; // Tháng trong JavaScript bắt đầu từ 0
        const year = date.getFullYear();

        // Tạo chuỗi định dạng "HH:mm:ss dd-MM-yyyy"
        const formattedDateTime = `${(hours < 10 ? "0" : "") + hours}:${(minutes < 10 ? "0" : "") + minutes}:${
            (seconds < 10 ? "0" : "") + seconds
        } ${day < 10 ? "0" : ""}${day}-${month < 10 ? "0" : ""}${month}-${year}`;

        return formattedDateTime;
    },
};
