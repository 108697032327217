import IMAGE from "../const/image";
export const ProductService = {
    getProductDetailMain() {
        return [
            IMAGE.productDetailMain1,
            IMAGE.productDetailMain2,
            IMAGE.productDetailMain3,
            IMAGE.productDetailMain4,
            IMAGE.productDetailMain5,
        ];
    },
    getProductDetailNav() {
        return [
            IMAGE.productDetailNav1,
            IMAGE.productDetailNav2,
            IMAGE.productDetailNav3,
            IMAGE.productDetailNav4,
            IMAGE.productDetailNav5,
        ];
    },
    getProductDetailSlider() {
        return [
            IMAGE.productDetailSlider1,
            IMAGE.productDetailSlider2,
            IMAGE.productDetailSlider3,
            IMAGE.productDetailSlider4,
            IMAGE.productDetailSlider5,
        ];
    },
    getProductDetailDesc() {
        return [IMAGE.productDetailsDesc1, IMAGE.productDetailsDesc2, IMAGE.productDetailsDesc3];
    },
    getUsers() {
        return [IMAGE.user2, IMAGE.user3];
    },
    getProductRelated() {
        return [IMAGE.productRelated1, IMAGE.productRelated2];
    },
    getListProduct() {
        return [
            {
                id: 1,
                name: "Whitetails Women's Open Sky",
                categoryProduct: [{ id: 3, name: "Clothes" }],
                price: 100,
                old_price: 120,
                image: IMAGE.productProdCut1,
            },
            {
                id: 2,
                name: "Simple Modern School Boys",
                categoryProduct: [{ id: 3, name: "Clothes" }],
                price: 120,
                old_price: 150,
                image: IMAGE.productProdCut2,
            },
            {
                id: 3,
                name: "Women's Essentials Convertible",
                categoryProduct: [{ id: 3, name: "Clothes" }],
                price: 150,
                old_price: 200,
                image: IMAGE.productProdCut3,
            },
            {
                id: 4,
                name: "Calvin Klein Gabrianna Novelty",
                categoryProduct: [
                    { id: 1, name: "Bags" },
                    { id: 2, name: "Shoes" },
                ],
                price: 80,
                old_price: 100,
                image: IMAGE.productProdCut4,
            },
            {
                id: 5,
                name: "Tommy Hilfiger Women’s Jaden",
                categoryProduct: [
                    { id: 1, name: "Bags" },
                    { id: 2, name: "Shoes" },
                ],
                price: 200,
                old_price: 300,
                image: IMAGE.productProdCut5,
            },
            {
                id: 6,
                name: "Tommy Hilfiger Women’s Jaden",
                categoryProduct: [
                    { id: 1, name: "Bags" },
                    { id: 2, name: "Shoes" },
                ],
                price: 200,
                old_price: 300,
                image: IMAGE.productProdCut5,
            },
            {
                id: 7,
                name: "Tommy Hilfiger Women’s Jaden",
                categoryProduct: [
                    { id: 1, name: "Bags" },
                    { id: 2, name: "Shoes" },
                ],
                price: 200,
                old_price: 300,
                image: IMAGE.productProdCut5,
            },
            {
                id: 8,
                name: "Tommy Hilfiger Women’s Jaden",
                categoryProduct: [
                    { id: 1, name: "Bags" },
                    { id: 2, name: "Shoes" },
                ],
                price: 200,
                old_price: 300,
                image: IMAGE.productProdCut5,
            },
        ];
    },
    getBanner() {
        return [
            {
                id: 1,
                title: "Whitetails Women's",
                description: "Whitetails",
                image: IMAGE.homeBannerSlider1,
            },
            {
                id: 2,
                title: "Open Sky",
                description: "Whitetails",
                image: IMAGE.homeBannerSlider2,
            },
            {
                id: 3,
                title: "Whitetails Sky",
                description: "Whitetails",
                image: IMAGE.homeBannerSlider3,
            },
        ];
    },
    getBannerSecond() {
        return [
            {
                id: 1,
                title: "Whitetails",
                image: IMAGE.homeBanner01,
            },
            {
                id: 2,
                title: "Women's Open Sky",
                image: IMAGE.homeBanner02,
            },
            {
                id: 3,
                title: "Open Sky",
                image: IMAGE.homeBanner03,
            },
        ];
    },
    getBannerThirty() {
        return [
            {
                id: 1,
                title: "Whitetails",
                price: "39.0$",
                image: IMAGE.homeBannerThirty01,
            },
            {
                id: 2,
                title: "Women's Open Sky",
                price: "30.0$",
                image: IMAGE.homeBannerThirty02,
            },
            {
                id: 3,
                title: "Open Sky",
                price: "59.0$",
                image: IMAGE.homeBannerThirty03,
            },
            {
                id: 4,
                title: "Boxx",
                price: "31.0$",
                image: IMAGE.homeBannerThirty04,
            },
            {
                id: 5,
                title: "Kem",
                price: "29.0$",
                image: IMAGE.homeBannerThirty05,
            },
            {
                id: 6,
                title: "Chuoi",
                price: "19.0$",
                image: IMAGE.homeBannerThirty06,
            },
        ];
    },
    getProducts() {
        return [
            {
                id: 1,
                name: "Whitetails",
                categoryProduct: [
                    { id: 1, name: "Bags" },
                    { id: 3, name: "Clothes" },
                ],
                price: 39,
                old_price: 40,
                image: IMAGE.homeProduct01,
            },
            {
                id: 2,
                name: "Whitetails",
                categoryProduct: [
                    { id: 1, name: "Bags" },
                    { id: 2, name: "Shoes" },
                ],
                price: 39,
                old_price: 40,
                image: IMAGE.homeProduct01,
            },
            {
                id: 3,
                name: "Whitetails",
                categoryProduct: [{ id: 2, name: "Shoes" }],
                price: 39,
                old_price: 40,
                image: IMAGE.homeProduct01,
            },
            {
                id: 4,
                name: "Whitetails",
                categoryProduct: [{ id: 2, name: "Shoes" }],
                price: 39,
                old_price: 40,
                image: IMAGE.homeProduct01,
            },
            {
                id: 5,
                name: "Whitetails",
                categoryProduct: [
                    { id: 1, name: "Bags" },
                    { id: 2, name: "Shoes" },
                ],
                price: 39,
                old_price: 40,
                image: IMAGE.homeProduct01,
            },
            {
                id: 6,
                name: "Whitetails",
                categoryProduct: [{ id: 3, name: "Clothes" }],
                price: 39,
                old_price: 40,
                image: IMAGE.homeProduct01,
            },
            {
                id: 7,
                name: "Whitetails",
                categoryProduct: [{ id: 3, name: "Clothes" }],
                price: 39,
                old_price: 40,
                image: IMAGE.homeProduct01,
            },
            {
                id: 8,
                name: "Whitetails",
                categoryProduct: [{ id: 3, name: "Clothes" }],
                price: 39,
                old_price: 40,
                image: IMAGE.homeProduct01,
            },
        ];
    },
    getCategories() {
        return [
            { id: 1, name: "Shoes" },
            { id: 2, name: "Clothes" },
            { id: 3, name: "Bags" },
        ];
    },
    getBannerTrending() {
        return [
            {
                id: 1,
                name: "Whitetails",
                price: "39.0$",
                image: IMAGE.homeProductSlider01,
            },
            {
                id: 2,
                name: "Women's Open Sky",
                price: "30.0$",
                image: IMAGE.homeProductSlider02,
            },
            {
                id: 3,
                name: "Open Sky",
                price: "59.0$",
                image: IMAGE.homeProductSlider03,
            },
            {
                id: 4,
                name: "Boxx",
                price: "31.0$",
                image: IMAGE.homeProductSlider04,
            },
        ];
    },
    getProductsBannerDouble() {
        return [
            {
                id: 1,
                name: "Whitetails",
                price: "39.0$",
                categoryProduct: [{ id: 3, name: "Clothes" }],
                image: IMAGE.homeProductTrending1,
            },
            {
                id: 2,
                name: "Women's Open Sky",
                price: "30.0$",
                categoryProduct: [
                    { id: 3, name: "Clothes" },
                    { id: 2, name: "Bags" },
                ],
                image: IMAGE.homeProductTrending2,
            },
            {
                id: 3,
                name: "Open Sky",
                price: "59.0$",
                categoryProduct: [{ id: 3, name: "Clothes" }],
                image: IMAGE.homeProductTrending3,
            },
            {
                id: 4,
                name: "Open Sky asdasd",
                price: "59.0$",
                categoryProduct: [{ id: 3, name: "Clothes" }],
                image: IMAGE.homeProductTrending3,
            },
        ];
    },
    getProductBestSellerWeek() {
        return [
            {
                id: 1,
                name: "Whitetails",
                price: "39.0$",
                categoryProduct: [
                    { id: 1, name: "Clothes" },
                    { id: 2, name: "Bags" },
                ],
                image: IMAGE.product09,
            },
            { id: 1, name: "Mouse Pans", price: "19.0$", categoryProduct: [{ id: 2, name: "Bags" }], image: IMAGE.product10 },
            { id: 1, name: "T-Shirt", price: "209.0$", categoryProduct: [{ id: 3, name: "Shoes" }], image: IMAGE.product11 },
            {
                id: 1,
                name: "Jean Jacket",
                price: "139.0$",
                categoryProduct: [{ id: 4, name: "Clothes" }],
                image: IMAGE.product12,
            },
            {
                id: 1,
                name: "Jean Pans",
                price: "109.0$",
                categoryProduct: [
                    { id: 1, name: "Clothes" },
                    { id: 2, name: "Bags" },
                ],
                image: IMAGE.product09,
            },
        ];
    },
    getBannerFeedback() {
        return [
            {
                id: 1,
                star: 5,
                content: "How you use the city or town name is up to you. All results may be freely used in any work",
                name: "John",
                position: "CEO",
                image: IMAGE.user2,
            },
            {
                id: 2,
                star: 5,
                content: "City or town name is up to you. All results may be freely used in any work",
                name: "SASA",
                position: "CHS",
                image: IMAGE.user2,
            },
            {
                id: 3,
                star: 5,
                content: "Town name is up to you. All results may be freely used in any work",
                name: "EQWE",
                position: "MKT",
                image: IMAGE.user2,
            },
            {
                id: 4,
                star: 4,
                content: "All results may be freely used in any work",
                name: "SADA",
                position: "SELLER",
                image: IMAGE.user2,
            },
        ];
    },
    getBlogs() {
        return [
            { id: 1, title: "The 'Boomerang' Employees Returning After Quitting", category: [{ id: 2, name: "Lift Style" }, { id: 3, name: "News" }], created_at: "14 July, 2023", image: IMAGE.blog01 },
            { id: 2, title: "Fast fashion: How clothes are linked to climate change", category: [{ id: 1, name: "Fashion" }, { id: 2, name: "Lift Style" }, { id: 3, name: "News" }], created_at: "28 May, 2023", image: IMAGE.blog02 },
            { id: 3, title: "The Sound Of Fashion: Malcolm McLaren Words", category: [{ id: 1, name: "Fashion" }, { id: 3, name: "News" }], created_at: "01 April, 2023", image: IMAGE.blog03 },
            
        ]
    },
    getProductInCart() {
        return [
            {id:1,nameProduct:"Legendary Whitetails Wmen's.",price:20,quantity:1,image:IMAGE.productShoppingCart01},
            {id:2,nameProduct:"Tommy Hilfiger Women’s Jaden",price:10,quantity:1,image:IMAGE.productShoppingCart02},
            {id:3,nameProduct:"Simple Modern School Boys",price:50,quantity:2,image:IMAGE.productShoppingCart03},
            {id:4,nameProduct:"Calvin Klein Gabrianna Novelty",price:90,quantity:1,image:IMAGE.productShoppingCart04},
        ]
    }
    
};
