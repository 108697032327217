import IMAGE from "../../../const/image";
import { useState } from "react";
import { Password } from "primereact/password";
import { InputText } from 'primereact/inputtext';
import { Checkbox } from "primereact/checkbox";
function Login() {
    const [checked, setChecked] = useState(false);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    return (
        <>
            <main>
                {/* breadcrumb area start */}
                <section className="breadcrumb__area include-bg text-center pt-95 pb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="breadcrumb__content p-relative z-index-1">
                                    <h3 className="breadcrumb__title">My account</h3>
                                    <div className="breadcrumb__list">
                                        <span>
                                            <a href="#">Home</a>
                                        </span>
                                        <span>My account</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* breadcrumb area end */}
                {/* login area start */}
                <section className="tp-login-area pb-140 p-relative z-index-1 fix">
                    <div className="tp-login-shape">
                        <img className="tp-login-shape-1" src={IMAGE.loginShape1} alt="" />
                        <img className="tp-login-shape-2" src={IMAGE.loginShape2} alt="" />
                        <img className="tp-login-shape-3" src={IMAGE.loginShape3} alt="" />
                        <img className="tp-login-shape-4" src={IMAGE.loginShape4} alt="" />
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-8">
                                <div className="tp-login-wrapper">
                                    <div className="tp-login-top text-center mb-30">
                                        <h3 className="tp-login-title">Login to Shofy.</h3>
                                        <p>
                                            Don’t have an account?{" "}
                                            <span>
                                                <a href="register.html">Create a free account</a>
                                            </span>
                                        </p>
                                    </div>
                                    <div className="tp-login-option">
                                        <div className="tp-login-social mb-10 d-flex flex-wrap align-items-center justify-content-center">
                                            <div className="tp-login-option-item has-google">
                                                <a href="#">
                                                    <img src={IMAGE.loginGoogle} alt="" />
                                                    Sign in with google
                                                </a>
                                            </div>
                                            <div className="tp-login-option-item">
                                                <a href="#">
                                                    <img src={IMAGE.loginFacebook} alt="" />
                                                </a>
                                            </div>
                                            <div className="tp-login-option-item">
                                                <a href="#">
                                                    <img className="apple" src={IMAGE.loginApple} alt="" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="tp-login-mail text-center mb-40">
                                            <p>
                                                or Sign in with <a href="#">Email</a>
                                            </p>
                                        </div>
                                        <div className="tp-login-input-wrapper">
                                            <div className="tp-login-input-box">
                                                <div className="tp-login-input">
                                                    <InputText id="email" placeholder="your_email@mail.com" value={email} onChange={(e) => setEmail(e.target.email)} className="w-full"/>
                                                </div>
                                                <div className="tp-login-input-title">
                                                    <label htmlFor="email">Your Email</label>
                                                </div>
                                            </div>
                                            <div className="tp-login-input-box">
                                                <div className="tp-login-input">
                                                    <Password id="password" placeholder="Min. 8 character" value={password} onChange={(e) => setPassword(e.target.password)} toggleMask className="w-full"/>
                                                </div>
                                                <div className="tp-login-input-title">
                                                    <label htmlFor="password">Password</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tp-login-suggetions d-sm-flex align-items-center justify-content-between mb-20">
                                            <div>
                                                <Checkbox
                                                    inputId="ingredient1"
                                                    name="pizza"
                                                    value="Remember me"
                                                    onChange={(e) => setChecked(e.checked)}
                                                    checked={checked}
                                                />
                                                <label htmlFor="ingredient1" className="ml-2">
                                                    Remember me
                                                </label>
                                            </div>
                                            <div className="tp-login-forgot">
                                                <a href="forgot.html">Forgot Password?</a>
                                            </div>
                                        </div>
                                        <div className="tp-login-bottom">
                                            <a href="profile.html" className="tp-login-btn w-100">
                                                Login
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* login area end */}
            </main>
        </>
    );
}
export default Login;
