import { createBrowserRouter } from "react-router-dom";
import Layout from "../container/layout";
import Home from "../container/pages/Home";
import About from "../container/pages/About";
import Product from "../container/pages/Product";
import Blog from "../container/pages/Blog";
import Profile from "../container/pages/Profile";
import Login from "../container/pages/Login";
import Shop from "../container/pages/Shop";
import BlogDetails from "../container/pages/BlogDetails";
import Register from "../container/pages/Register";
import Fogot from "../container/pages/Fogot";
import Contact from "../container/pages/Contact";
import ShoppingCart from "../container/pages/ShoppingCart";
import CheckOut from "../container/pages/Checkout";
import TrackingOrder from "../container/pages/TrackingOrder";
import TestUi from "../components/Test";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "",
                element: <Home />,
                loader: () => {
                    return "abc";
                },
            },
            {
                path: "about",
                element: <About />,
                loader: () => "abc",
            },
            {
                path: "product/:id/:slug",
                element: <Product />,
                loader: () => "product",
            },
            {
                path: "shop",
                element: <Shop />,
                loader: () => "shop",
            },
            {
                path: "login",
                element: <Login />,
                loader: () => "login",
            },
            {
                path: "blog",
                element: <Blog />,
                loader: () => "blog",
            },
            {
                path: "profile",
                element: <Profile />,
                loader: () => "profile",
            },
            {
                path: "blogDetails",
                element: <BlogDetails />,
                loader: () => "blogDetails",
            },
            {
                path: "register",
                element: <Register />,
                loader: () => "register",
            },
            {
                path: "fogot",
                element: <Fogot />,
                loader: () => "fogot",
            },
            {
                path: "contact",
                element: <Contact />,
                loader: () => "contact",
            },
            {
                path: "cart",
                element: <ShoppingCart />,
                loader: () => "cart",
            },
            {
                path: "checkout",
                element: <CheckOut />,
                loader: () => "checkout",
            },
            {
                path: "tracking-order",
                element: <TrackingOrder />,
                loader: () => "tracking-order",
            },
            {
                path: "test",
                element: <TestUi />,
                loader: () => "tracking-order",
            },
        ],
    },
]);

export default router;
